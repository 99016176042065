import { FC } from 'react';
import MobileToggle from '../utils/MobileToggle';
import './style.scss';

interface HeaderProps {
  onOpen: () => void;
}

const Header: FC<HeaderProps> = ({
  onOpen: onToggle
}) => {
  const onToggleSideNav = () => {
    onToggle();
  }

  return (
    <header className="headerMobile">
      <nav className="headerMenuNav" onClick={onToggleSideNav}/>
      <img src={require('../../../assets/mobile/header/header_logo@2x.webp')} alt="logo of the company" className='headerLogo' />
      <MobileToggle />
    </header>
  )
}

export default Header;