import { FC, TouchEvent } from "react";
import { MobileSideNavItems } from "apps/routes";
import { SideNavItem } from "apps/declarations";
import styles from './style.module.scss'

interface SideMenuProps {
  isVisible: boolean;
  onHide: () => void;
  onClick: (index: number) => void;
}

export const SideMenu: FC<SideMenuProps> = ({
  isVisible, onHide, onClick
}) => {
  const onClickOverlay = (e: TouchEvent<HTMLDivElement>) => {
    onHide()
  }

  const Item = (details: SideNavItem) => {
    return (
      <div className={styles.Item} onClick={() => onClick(details.index)}>
        <img src={details.image}/>
        <span>{details.title}</span>
      </div>
    )
  }

  return (
    <>
      { isVisible ? <div className={styles.Overlay} onTouchStart={onClickOverlay}/> : null }
      <div className={`${styles.SideMenu} ${isVisible ? styles.Visible : ''}`}>
        {
          isVisible ? MobileSideNavItems.map((item, index) => (
            <Item {...item} key={index}/>
          )) : null
        }
      </div>
    </>
  )
}

export default SideMenu;