import './style.scss';
import FaqTitleChinese from '../../../assets/mobile/faq/faqs_title_chinese.webp';
import FaqTitleEnglish from '../../../assets/mobile/faq/faqs_title_en.webp'
import FaqQuesIcon from '../../../assets/mobile/faq/faqs_icon.webp';
import { Translation } from 'react-i18next';
import i18next, { t } from 'i18next';
import { useState } from 'react';
import i18n from 'config/i18n';

function Faq() {

    const [changeLanguage, setChangelanguage] = useState(false);

    const [showQ1, setShowQ1] = useState(false);
    const [showQ2, setShowQ2] = useState(false);
    const [showQ3, setShowQ3] = useState(false);
    const [showQ4, setShowQ4] = useState(false);
    const [showQ5, setShowQ5] = useState(false);
    const [showQ6, setShowQ6] = useState(false);

    i18n.on('languageChanged', function(lng) {
        setChangelanguage(!changeLanguage);
    });

    const handleClick = (e: Number) => {

        setShowQ1(false);
        setShowQ2(false);
        setShowQ3(false);
        setShowQ4(false);
        setShowQ5(false);
        setShowQ6(false);

        switch (e) {
            case 1:
                showQ1 ? setShowQ1(false) : setShowQ1(true);
                break;
            case 2:
                showQ2 ? setShowQ2(false) : setShowQ2(true);
                break;
            case 3:
                showQ3 ? setShowQ3(false) : setShowQ3(true);
                break;
            case 4:
                showQ4 ? setShowQ4(false) : setShowQ4(true);
                break;
            case 5:
                showQ5 ? setShowQ5(false) : setShowQ5(true);
                break;
            case 6:
                showQ6 ? setShowQ6(false) : setShowQ6(true);
                break;
        }
    }

    return (
        <>
        <div className='faqsWrapper'>
            <div className='faqsTitleCont'>
                { changeLanguage ? 
                    <img src={FaqTitleEnglish} className='faqTitleEnglish' /> 
                    :
                    <>
                        <img src={FaqTitleChinese} className='faqTitleChinese' />
                        <div className='faqSubtitle'>
                            FAQs
                        </div>
                    </>
                }
            </div>
            <div className='faqsContent'>
                <div className="faqsContentContainer">
                    <div className='faqsBoxCont'>
                        <div className='faqsQuesCont' onClick={() => handleClick(1)}>
                            <img className='faqsIcon' src={FaqQuesIcon} />
                            <Translation>
                                    { t => <div className='faqsQuestion'> { t('QA_1') } </div> }
                            </Translation>                        
                        </div>
                        { showQ1 && 
                            <Translation>
                                { t => <div className='faqsAnswer'> { t('QA_11') } </div> }
                            </Translation>
                        }
                    </div>
                    <div className='faqsBoxCont'>
                        <div className='faqsQuesCont' onClick={() => handleClick(2)}>
                            <img className='faqsIcon' src={FaqQuesIcon} />
                            <Translation>
                                { t => <div className='faqsQuestion'> { t('QA_3') } </div> }
                            </Translation>                        
                        </div>
                        { showQ2 && 
                            <Translation>
                                { t => <div className='faqsAnswer'> { t('QA_31') } </div> }
                            </Translation>
                        }
                    </div>
                    <div className='faqsBoxCont'>
                        <div className='faqsQuesCont' onClick={() => handleClick(3)}>
                            <img className='faqsIcon' src={FaqQuesIcon} />
                            <Translation>
                                    { t => <div className='faqsQuestion'> { t('QA_4') } </div> }
                            </Translation>                        
                        </div>
                        { showQ3 &&
                        <Translation>
                            { t => <div className='faqsAnswer'> { t('QA_41') } </div> }
                        </Translation>
                        }
                    </div>
                    <div className='faqsBoxCont'>
                        <div className='faqsQuesCont' onClick={() => handleClick(4)}>
                            <img className='faqsIcon' src={FaqQuesIcon} />
                            <Translation>
                                { t => <div className='faqsQuestion'> { t('QA_5') } </div> }
                            </Translation>                        
                        </div>
                        { showQ4 &&
                            <Translation>
                                { t => <div className='faqsAnswer'> { t('QA_51') } </div> }
                            </Translation>
                        }
                    </div>
                    <div className='faqsBoxCont'>
                        <div className='faqsQuesCont' onClick={() => handleClick(5)}>
                            <img className='faqsIcon' src={FaqQuesIcon} />
                            <Translation>
                                { t => <div className='faqsQuestion'> { t('QA_6') } </div> }
                            </Translation>                        
                        </div>
                        { showQ5 &&
                            <Translation>
                                { t => <div className='faqsAnswer'> { t('QA_61') } </div> }
                            </Translation>
                        }
                    </div>
                    <div className='faqsBoxCont'>
                        <div className='faqsQuesCont' onClick={() => handleClick(6)}>
                            <img className='faqsIcon' src={FaqQuesIcon} />
                            <Translation>
                                { t => <div className='faqsQuestion'> { t('QA_7') } </div> }
                            </Translation>                        
                        </div>
                        { showQ6 &&
                            <Translation>
                                { t => <div className='faqsAnswer'> { t('QA_71') } </div> }
                            </Translation>
                        }
                    </div>
                </div>                
                <div className="footerCont">
                    <Translation>{t => <p className='footerContHr'>{t('ContactHr')}</p>}</Translation>
                    <Translation>{t => <p className='footerCompYear'>{t('companyYear')}</p>}</Translation>
                </div>                
            </div>
        </div>
        </>
    )
}

export default Faq;