/**
 *404页面
 */
import './style.scss';
import { Translation } from "react-i18next";
import errorImg from "../../../../assets/web/maintenance/404.png"
const NotFoundPage = () => {
  return (
      <div className="notFoundPageWeb">
    
          <img src={errorImg} className="errorImg"/>

          <div className='errorText'> <Translation>{(t) => <>{t("errorText")}</>}</Translation></div>
      </div>
  )
}

export default NotFoundPage;