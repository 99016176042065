import React, { useState } from 'react';
import './style.scss';
import i18n from 'config/i18n';

const MobileToggle = () => {

  const [ chineseTranslate, setChineseTranslate ] = useState(true);
  const [ englishTranslate, setEnglishTranslate ] = useState(false);

  const handleChangeLanguage = () => {
    if (englishTranslate) {
      setChineseTranslate(true)
      i18n.changeLanguage("zh");
      setEnglishTranslate(false);
    } else {
      setChineseTranslate(false)
      i18n.changeLanguage("en");
      setEnglishTranslate(true);
    }
  }

  return (
    <div className='headerToggle'>
      <p 
        className='mobileZh'
        onClick={handleChangeLanguage}
        style={{color: chineseTranslate ? "#13DDAD" : "#071325"}}
        >中</p>
      <span>/</span>
      <p 
        className='mobileEn'
        onClick={handleChangeLanguage}
        style={{color: englishTranslate ? "#13DDAD" : "#071325"}}
        >EN</p>
  </div>
  )
}

export default MobileToggle;
