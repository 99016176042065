import { Translation } from 'react-i18next';
import i18next from 'i18next';
import React, { FC, useState } from 'react';
import { Swiper, SwiperSlide} from "swiper/react";
import { PaginationOptions, Swiper as SwiperClass } from 'swiper/types';
import {Pagination} from "swiper";
import './style.scss';
import "swiper/css";
import "swiper/css/pagination";
import strategy_img_hover from  '@assets/web/About/strategy_img_hover@2x.webp'
import vision_img_hover from  '@assets/web/About/vision_img_hover@2x.webp'
import mission_img_hover from  '@assets/web/About/mission_img_hover@2x.webp'
const preImgList = [strategy_img_hover, vision_img_hover, mission_img_hover]
    interface BulletProps {
  index: number
}

interface AboutProps {
  toggleMainSwiper: (value: boolean) => void
}

const About: FC<AboutProps> = ({
  toggleMainSwiper
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<SwiperClass>();
	const [changeLanguage, setChangelanguage]=useState(false);
	const pagination: PaginationOptions = {
    type: 'bullets',
    clickable: true,
  };
		
	i18next.on('languageChanged', function(lng) {
		setChangelanguage(!changeLanguage);
	})

	const FirstSlide = () => {
    return (
      <div className="white-box-about">
        {/* <img className='label-bg' src={require('../../../assets/web/About/label_title_bg@2x.webp')} alt="label" /> */}
        { !changeLanguage ?
          <div className="label-bg-ZH">
            <p className='about-title-ZH'>
              <Translation>{t => <>{t('companyIntroduction')}</>}</Translation>
            </p>
          </div>
          :
          <div className="label-bg-EN">
            <p className='about-title-EN'>
              <Translation>{t => <>{t('companyIntroduction')}</>}</Translation>
            </p>
          </div>
        }
        <p className='about-desc'>
          <Translation>{t => <>{t('companyDetail')}</>}</Translation>
        </p>
      </div>
    );
  }

	const SecondSlide = () => {
    return(
      <div className="white-box-cards">
        <div className="cards">
          <div className='strategy-about'/>
          <div className="card-detail">
            <div className="card-detail-title">
              <Translation>{t => <>{t('Strategy')}</>}</Translation>
            </div>
            <div className="card-detail-desc">
              <Translation>{t => <>{t('StrategyDetail')}</>}</Translation>
            </div>
          </div>
        </div>

        <div className="cards">
          <div className='vision-about'/>
          <div className="card-detail">
            <div className="card-detail-title">
              <Translation>{t => <>{t('Vision')}</>}</Translation>
            </div>
            <div className="card-detail-desc">
              <Translation>{t => <>{t('VisionDetail')}</>}</Translation>
            </div>
          </div>
        </div>

        <div className="cards">
          <div className='mission-about'/>
          <div className="card-detail">
            <div className="card-detail-title">
              <Translation>{t => <>{t('Mission')}</>}</Translation>
            </div>
            <div className="card-detail-desc">
              <Translation>{t => <>{t('MissionDetail')}</>}</Translation>
            </div>
          </div>
        </div>
          {/*图片预加载*/}
          <div style={{display: 'none'}}>
              {preImgList.map((item:string) => {
                  return  <img src={item}/>
              })}
          </div>
      </div>
    );
  }

  const Bullet = (props: BulletProps) => {
    const onClick = () => {
      swiper?.slideTo(props.index)
      setActiveIndex(props.index);
    }
    return (
      <div
        className={`
          bullet
          swiper-pagination-bullet
          ${props.index === activeIndex ? 'swiper-pagination-bullet-active' : ''}
        `}
        onClick={onClick}
      />
    )
  }

	return (
			<div className="about">
				<div className="about-content">
					<div className='title-container'>
							{ changeLanguage 
								? 
								<img className='page-title-en' src={require('../../../assets/web/About/title_EN@2x.webp')} alt="english title" />
								: 
								<div className="ch-title-container">
									<img className='page-title-ch' src={require('../../../assets/web/About/title_ZH@2x.webp')} alt="chinese title" />
									<p>introduction</p>
								</div>
							}
					</div>
					<div className="row-container">
						<div className="left-content">
							<img className='logo-bg' src={require('../../../assets/web/About/common_bg_image@2x.webp')} alt="label" />
						</div>
						<div className="right-content">
							<div className="home-slider-container">
								<img className='robot' src={require('../../../assets/web/About/robot@2x.webp')} alt="robot" />
								<div className="slider-wrapper">

									{/* Slide one */}
									<Swiper
										pagination={pagination} 
                    modules={[Pagination]} 
                    onInit={(swiper: SwiperClass) => {
                      setSwiper(swiper)
                    }}
                    onPaginationUpdate={(swiper: SwiperClass) => {
                      setActiveIndex(swiper?.activeIndex)
                    }}
                  >
										<SwiperSlide> <FirstSlide/> </SwiperSlide>
										<SwiperSlide> <SecondSlide/> </SwiperSlide>
									</Swiper>

								</div>
								<div
                  className='swiper-pagination'
                  onMouseEnter={() => toggleMainSwiper(false)}
                  onMouseLeave={() => toggleMainSwiper(true)}
                >
                  <Bullet index={0} />
                  <Bullet index={1} />
                </div>
								<img className='globe-img' src={require('../../../assets/web/About/introduction_globe@2x.webp')} alt="robot" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
}

export default About;