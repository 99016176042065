import React from 'react';
import { Route, Routes} from 'react-router-dom';
import NotFoundPage from "./page/web/maintenance/404";
import './App.scss';

function App({}) {
    const isMobile = Boolean(Number(localStorage.getItem('isMobile')))
    const CurrentPages =  isMobile ? require('./routes/MobilePages').default : require('./routes/WebPages').default;

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<CurrentPages/>}>
        </Route>
        <Route path="*"  element={<NotFoundPage/>}>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
