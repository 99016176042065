import React, { FC, useMemo } from 'react';
import { diamond, longLine, longLinePrimary, shortLine } from 'apps/imgs';
import styles from './style.module.scss';

interface DividerDetails {
  count?: number | undefined,
  img?: string | undefined
}

const defaultDividerDetails: DividerDetails = {
  count: 3,
  img: shortLine
}

interface SideNavItemProps {
  title: JSX.Element | string,
  index: number
}

interface SideNavProps {
  titles: JSX.Element[] | string[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  divider?: DividerDetails | undefined;
}

const SideNav: FC<SideNavProps> = ({
  titles, activeIndex, setActiveIndex, divider
}) => {
  // Creates a vertically-aligned series of images (e.g. dashes)
  const Divider = () => useMemo(() => {
    return (
      <div className={styles.Divider}>
        {Array.from(Array(divider?.count || defaultDividerDetails.count).keys()).map((_, index: number) => (
          <img key={index} src={divider?.img || defaultDividerDetails.img}/>
        ))}
      </div>
    )
  }, [])

  const SideNavItem = (props: SideNavItemProps) => useMemo(() => {
    const isActive: boolean = props.index === activeIndex;
    // TODO: Replace the static images with svg
    return (
      <>
        <div className={`${styles.Item} ${isActive ? styles.Active : ''}`} onClick={() => setActiveIndex(props.index)}>
          {isActive ? <img className={styles.Selected} src={diamond}/> : null}
          <img className={styles.Line} src={isActive ? longLinePrimary : longLine}/>
          {props.title}
        </div>
        { props.index !== titles.length - 1 ? <Divider/> : null}
      </>
    )
  }, [])

  return (
    <div className={styles.SideNav}>
      {
        titles.map((section, index) => <SideNavItem key={index} title={section} index={index} />)
      }
    </div>
  );
}

export default SideNav;