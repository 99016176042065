import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import languageDetector  from 'i18next-browser-languagedetector';

import { resources } from './resouces';

const initConfig: any = {
  // lng: ['zh-CN', 'zh-TW'].includes(navigator.language) ? 'zh' : 'en',
  lng: process.env.HTTP_EVN_LANGUAGE === 'zh' ? 'zh': 'zh',
  resources,
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};

i18n
  //.use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(initConfig);

export default i18n;
