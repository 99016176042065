import React, { useEffect, useState } from "react";
import "./style.scss";
import { Translation } from "react-i18next";
const Modal = ({ modalIsOpen, handleModal, modalData }: any) => {
  const rendDetailRes = () => {
    const data = modalData.detail.responsibility;

    if (!data) return null;
    return (
      <>
        <div className="modalContentTitle">
          <Translation>{(t) => <>{t("Jobresponsibilities")}</>}</Translation>
        </div>
        <div>
          <ul className="modalList">
            {data.map((v: any, i: any) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  const rendDetailReq = () => {
    const data = modalData.detail.requirement;
    // console.log("dataaaaaaaa",data)

    if (!data) return null;
    return (
      <>
        <div className="modalContentTitle">
          <Translation>{(t) => <>{t("jobrequirements")}</>}</Translation>
        </div>

        <div>
          <ul className="modalList">
            {data.map((v: any, i: any) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      {modalIsOpen ? (
        <div className="modalContainer">
          <div className="modalWrapper">
            <div className="modalHead">
              <div className="modalHeadTitle">
            
                {modalData.name}
              </div>

              <div>
                <div onClick={handleModal} className="modalCloseBtn">
                <img className="modalCloseBtnImg" src={require(`../../../assets/web/job/closeBtnImg.webp`)} />
                </div>
              </div>

              <div className="modalTopContent">
                <div className="modalTopSubCont">
                  <div className="modalTopContentItem">
                    <span className="salaryTitle">
                      {modalData.detail.salary}
                    </span> 
                  </div>
                  <div className="modalTopContentItem">
                    {" "}
                    <Translation>
                      {(t) => <>{t("recruits")}</>}
                    </Translation> 
                    <span>
                    {"  "}   {" "}    {modalData.headCount}     
                    </span>
                    <Translation>
                      {(t) => <>{t("people")}</>}
                    </Translation> 
                  </div>
                </div>
                <div className="modalTopSubCont">
                  <div className="modalTopContentItem">
                    <span className="dateTitle">
                      {" "}
                      <Translation>
                        {(t) => <>{t("releasetime")}</>}
                      </Translation>: <span className="dateTitleTime">{modalData.time}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modalContent">
              <div className="modalRequirement">
              {rendDetailRes()}
                {rendDetailReq()}
                {/* <div className='modalContentTitle'>Job Requirements</div>
  <div>{modalData.detail.requirement}</div> */}
             

                {/* <div className="modalContentTitle">
                  {" "}
                  <Translation>
                    {(t) => <>{t("salaryrange")}</>}
                  </Translation>{" "}
                </div> */}
                {/* <ul className="modalList">
                  <li> {modalData.detail.salary}</li>
                </ul> */}
              </div>
            </div>



          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
