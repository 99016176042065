import { Translation } from 'react-i18next';
import i18next from 'i18next';
import './style.scss';
import { useState } from 'react';

const About = () => {
		const [changeLanguage, setChangelanguage] = useState(false);

		i18next.on('languageChanged', function(lng) {
		setChangelanguage(!changeLanguage);
	})

    return(
					<div className = 'about-container'>
						<div className="introduction-header">
							<div className='title-container'>
									{ changeLanguage 
										? 
											<img className='page-title-en' src={require('../../../assets/mobile/about/title_English@2x.webp')} alt="english title" />
										: 
										<div className="ch-title-container">
											<img className='page-title-ch' src={require('../../../assets/mobile/about/title_Chinese@2x.webp')} alt="chinese title" />
											<p>introduction</p>
										</div>
									}
							</div>
								<img className='robot' src={require('../../../assets/mobile/about/robot.webp')} alt="robot" />
						</div>
						<div className='about-desc-wrapper'>
							<div className="label-bg">
								<p className='about-title'>
									<Translation>{t => <>{t('companyIntroduction')}</>}</Translation>
								</p>
							</div>
								<p className='about-desc'>
									<Translation>{t => <>{t('companyDetail')}</>}</Translation>
								</p>
								<img className='globe' src={require('../../../assets/mobile/about/globe-bg.webp')} alt="globr" />
						</div>
					  
						<div className='cards-container'>
							{/* First Card */}
							<div className="card-item"> 
								<div className="card-col">
									<div className="card-left">
									<img className='strategy' src={require('../../../assets/mobile/about/strategy@2x.webp')} alt="strategy image" />
									</div>
									<div className="card-right">
										<div className="card-detail-wrapper">
											<div className="card-detail-title">
												<Translation>{t => <>{t('Strategy')}</>}</Translation>
											</div>
											<div className="card-detail-desc">
												<Translation>{t => <>{t('StrategyDetail')}</>}</Translation>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* Second Card*/}
							<div className="card-item">
								<div className="card-col">
									<div className="card-left">
									<img className='vision' src={require('../../../assets/mobile/about/vision@2x.webp')} alt="vision image" />
									</div>
									<div className="card-right">
										<div className="card-detail-wrapper">
											<div className="card-detail-title">
												<Translation>{t => <>{t('Vision')}</>}</Translation>
											</div>
											<div className="card-detail-desc">
												<Translation>{t => <>{t('VisionDetail')}</>}</Translation>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* Third Card */}
							<div className="card-item">
								<div className="card-col">
									<div className="card-left">
									<img className='mission' src={require('../../../assets/mobile/about/mission@2x.webp')} alt="mission image" />
										</div>
										<div className="card-right">
											<div className="card-detail-wrapper">
												<div className="card-detail-title">
													<Translation>{t => <>{t('Mission')}</>}</Translation>
												</div>
												<div className="card-detail-desc">
													<Translation>{t => <>{t('MissionDetail')}</>}</Translation>
												</div>
											</div>
										</div>
								</div>
							</div>
						</div>
					</div>
    )
}

export default About;