import './style.scss';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import { FC, useState } from 'react';

interface Props {}

const Bonus: FC<Props> = () => {
    const [changeLanguage,setChangelanguage]=useState(false);
    
    i18next.on('languageChanged', function(lng) {
        setChangelanguage(!changeLanguage);
    })

    return (
        <div className={'bonusWrapperMobile'} id='bonus'>
            <div className={'bonusCont'}>
                <div className={'bonusHeader'}>
                    <div className={'titleCont'}>
                        {changeLanguage ? 
                            (
                                <img className={'bonusTitleEN'} src={require('../../../assets/mobile/bonus/salary_title_EN@2x.webp')} />
                            ) : (
                            <>
                                <img className={'bonusTitle'} src={require('../../../assets/web/bonus/salary_title_Chinese@2x.webp')} />
                                <div className={'bonusSubtitle'}>
                                    salary / benefits
                                </div>
                            </>
                            )
                        }
                    </div>
                    <div className={'bonusDesc'}>
                        <Translation>{t => <>{t('salaryDetail')}</>}</Translation>
                    </div>
                </div>
                <div className={'itemCont'}>
                    {/* SALARY */}
                    <div className={'bonusItem'}>
                        <div className={'bonusIcon'}>
                        </div>
                        <div className={'bonusText'}>
                            <div className={'bonusType'}>
                                <Translation>{t => <>{t('Salary1')}</>}</Translation>
                            </div>
                            <ul>
                                <li>
                                    <Translation>{t => <>{t('Salary_1')}</>}</Translation>
                                </li>
                                <li>
                                    <Translation>{t => <>{t('Salary_2')}</>}</Translation>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    {/* LEAVES */}
                    <div className={'bonusItem'}>
                        <div className={'bonusIcon'}>
                        </div>
                        <div className={'bonusText'}>
                            <div className={'bonusType'}>
                                <Translation>{t => t('benefits')}</Translation>
                            </div>
                            <ul>
                                <li>
                                    <Translation>{t => t('benefits_1')}</Translation>
                                </li>
                                <li>
                                    <Translation>{t => t('benefits_2')}</Translation>
                                </li>
                                <li>
                                    <Translation>{t => t('benefits_3')}</Translation>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* MEALS */}
                    <div className={'bonusItem'}>
                        <div className={'bonusIcon'}>
                        </div>
                        <div className={'bonusText'}>
                            <div className={'bonusType'}>
                                <Translation>{t => <>{t('meals')}</>}</Translation>
                            </div>
                            <ul>
                                <li><Translation>{t => <>{t('meals_1')}</>}</Translation></li>
                            </ul>
                        </div>
                    </div>
                    {/* TRANSPORTATION */}
                    <div className={'bonusItem'}>
                        <div className={'bonusIcon'}>
                        </div>
                        <div className={'bonusText'}>
                            <div className={'bonusType'}>
                                <Translation>{t => <>{t('Transportation')}</>}</Translation>
                            </div>
                            <ul >
                                <li>
                                    <Translation>{t => <>{t('Transportation_1')}</>}</Translation>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* HOLIDAY */}
                    <div className={'bonusItem'}>
                        <div className={'bonusIcon'}>
                        </div>
                        <div className={'bonusText'}>
                            <div className={'bonusType'}>
                                <Translation>{t => <>{t('LeaveEntitlement')}</>}</Translation>
                            </div>
                            <ul>
                                <li>
                                    <Translation>
                                    {t => <>{t('LeaveEntitlement_1')}</>}
                                    </Translation>
                                </li>
                                <li>
                                    <Translation>
                                    {t => <>{t('LeaveEntitlement_2')}</>}
                                    </Translation>
                                </li>
                                <li>
                                    <Translation>
                                    {t => <>{t('LeaveEntitlement_3')}</>}
                                    </Translation>
                                </li>
                                <li>
                                    <Translation>
                                        {t => t('LeaveEntitlement_4')}
                                    </Translation>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* COMPANY ACTIVITIES */}
                    <div className={'bonusItem'}>
                        <div className={'bonusIcon'}>
                        </div>
                        <div className={'bonusText'}>
                            <div className={'bonusType'}>
                                <Translation>{t => t('TeamBuilding')}</Translation>
                            </div>
                            <ul>
                                <li>
                                    <Translation>{t => <>{t('TeamBuilding_1')}</>}</Translation>
                                </li>
                                <li>
                                <Translation>{t => <>{t('TeamBuilding_2')}</>}</Translation>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default Bonus;