import i18n from "config/i18n";
import { Translation } from "react-i18next";
const JobPostsArr=[
    
{
    id:1,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '15-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:2,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:3,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:4,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:5,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:6,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:7,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:8,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:9,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:10,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:11,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:12,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:13,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:14,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:15,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:16,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:17,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:18,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:19,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:20,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:21,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:22,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:23,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},
{
    id:24,
    name:    <Translation>{t => <>{t('UiDesign')}</>}</Translation>,
    salaryDetails: '18-30K',
    postTime:<Translation>{t => <>{t('AmonthAgo')}</>}</Translation>,
    recruitNumber:'19',

},




]

export const JOBLIST = [
  {
    id: 1,
    name: 'PHP开发工程师',
    headCount: 7,
    category:"develop",
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: [
        '本科以上学历，3年或以上PHP开发经验；',
        '熟悉LNMP环境的使用及搭建、调试、排错、优化、部署等；',
        '熟悉MySQL数据库开发及常用优化方法；',
        '熟悉Redis，等缓存技术；',
        '熟悉Laravel、Phalcon、ThinkPHP中至少一种框架；',
        '熟悉Linux常用命令； ',
        '掌握常见场景设计，如消息队列、缓存、高并发、高可用、高负载等（有千万级流量网站开发经验优先）；',
        '熟悉html/css/js及jQuery等框架者优先；',
        '熟悉Lua、GoLang、Python、Kafka等技术者优先。',
      ],
      salary: '25-40K',
    },
  },
  {
    id: 2,
    name: 'Golang',
    category:"develop",
    headCount: 15,
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: [
        '计算机或者数学相关专业，本科及以上学历',
        '基于微服务架构，负责管理后台,前端Api所有功能开发及维护，不断提升代码质量、可扩展性和可维护性；',
        '具有至少两年golang项目经验和三年以上',
        '其它语言（PHP/C++）的项目经验 精通 MySQL应用开发，熟悉数据库原理和常用性能优化技术，以及 NoSQL原理、使用场景以及限制,精通redis； 熟悉至少一种消息队列 Kafka/Rabbitmq/Redis等负责所编程序的自测试工作，并参加系统集成和联调；',
        '具有分布式系统设计和开发经验者优先。',
        '精通TCP/IP，HTTP协议，包括但不限于RPC、调用策略等；精通Protobuf/JSON序列化协议者优先',
      ],
      salary: '28-45K',
    },
  },
  {
    id: 3,
    name: '前端',
    headCount: 5,
    category:"develop",
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责公司项目的前端开发、维护、优化，完成核心代码的编写',
        '与产品、设计沟通，并能够针对不同业务需求给出前端技术解决方案并实现交互原型',
        '对前端相关项目进行性能优化，缩减加载、执行和渲染时间，提升用户体验',
        '跨部门协调沟通，并能够在项目中将前端工具、组件逐步沉淀'
    ],
      requirement: [
        '3年以上web/h5开发经验，有参与过复杂项目的开发。',
        '精通各种前端技术，包括HTML/CSS/JavaScript/Node.JS等，在Web（PC+Mobile）/Node.js/Native App三个方向上至少精通一个方向，具备多个的更佳',
        '对前端工程化与模块化开发有一定了解，熟练使用webpack等工具，有相关打包优化经验', 
        '熟练使用git进行项目代码管理以及版本控制，并遵循一定的流行规范', 
        '熟练使用或精通react、vue框架了解原理，并对源码有一定研究，能够基于框架编写高性能逻辑代码', 
        '具备良好的团队协作精神，能利用自身技术能力提升团队整体研发效率，提高团队影响力；对前端技术有持续的热情，个性乐观开朗，逻辑性强，善于合作',
        '加分项（满足其一即可）：Nodejs后端或js全栈产品开发经验；跨平台技术RN/Weex/Flutter；复杂企业后台应用开发经验；大规模/高负载场景下NodeJS应用开发、运维经验；熟练或精通关系型或非关系型数据库操作',
        '备注：要求有Whatsapp和Facebook项目经验的人员'
      ],   
         salary: '20-35K',
    },
  },
  {
    id: 4,
    name: '测试主管',
    category:"test",
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '建立和完善部门人员工作架构体系，组织和制定公司部门管理制度、方法和流程，监督、推动制度、方法、流程的执行落实',
        '规划项目测试策略，评审研发方案，参与版本需求的排期，并同步组长分配任务，若版本排期存在风险，及时沟通并解决', 
        '把控版本进度、风险和质量，跟踪了解版本上线情况和验证结果', 
        '协助组长对组内人员的培养和备份，包括业务知识、技能、管理等方面',
        '协调、调配、解决需求测试阶段某个小组人力不足的情况，实现小组之间人力灵活备份',
        '观察和识别人员动态风险，若存在风险，及时沟通解决或汇报领导',
        '参与评定绩效和其他奖励，同步组长，必要时介入组员沟通',
        '响应线上问题，并定期组织会议回顾及讨论如何避免再次出现',
        '挖掘人才，同时参与面试，为公司招聘优秀的人才',
        '传达公司制度和信息，监督员工执行',
        '组织所属部门娱乐、聚餐等文化建设工作，增强部门员工凝聚力',
        '管理公司部门的资源设备，负责采购、统计、日常使用监管'
      ],
      requirement: [
        '5年以上软件测试经验',
        '带领过8~20人的测试团队，独立承担小版本交付',
        '熟练掌握测试理论和概念，测试用例编写',
        '熟练掌握测试管理工具和缺陷管理工具',
        '熟练测试理论、流程、方法和常用测试工具',
        '熟悉Java/Shell/PHP/Python/Ruby等一种语言，熟悉Linux常用操作命令，熟悉Oracle/MySQL数据库基本操作',
        '性能测试、自动化测试、安全测试、接口测试、业务功能测试至少某一领域比较擅长，能力突出',
        '有良好的沟通表达能力、综合协调能力和理解能力'
      ],
      salary: '30-40K',
    },
  },
  {
    id: 5,
    name: '测试',
    headCount: 6,
    category:"test",
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: [
        '根据产品规格和测试需求，编写测试方案、测试用例，(APP 经验的优先',
        '熟悉主流测试工具，包括抓包工具、自动化测试工具, 性能测试，异常测试工具等，(Linux 环境下测试经验的优先',
        '熟悉主流的测试用例管理工具及缺陷管理工',
        '掌握良好的测试理念、测试方法，具有良好的业务理解能力、交流、沟通能力，能够清晰准确的描述BUG、跟踪BU',
        '熟悉TCP/IP协议、HTTP协',
        '有较好的协调能力，以及具有良好的团队意识和合作精',
        '熟悉Linux系统、MySQL数据库经验者优',
        '三年以上测试工作经验，知名 APP 公司工作经验优',
        '做事认真，有责任心，有工作热情，能吃苦耐劳',
      ],
      salary: '15-25K',
    },
  },
  {
    id: 6,
    category:"develop",
    name: '高级JAVA',
    headCount: 10,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '参与产品的架构设计讨论和技术调研',
        '搭建系统开发环境，完成系统具体的设计、编码及单元测试 ',
        '撰写系统详细设计文档',
      ],
      requirement: [
        '3年以上JAVA项目开发经验；有过大型项目经验者优先考虑；',
        '熟悉设计模式，具有面向对象的分析和设计的能力；具有丰富的J2EE架构设计经验',
        '熟悉Spring、Mybatis、Redis、MQ等常用框架',
        '熟悉Tomcat及Linux操作系统；',
        '熟练应用rabbitmq、redis、mongodb、dubbo、zookeeper、springboot中的一种或几种优先； ',
        '熟悉微服务架构及SpringCloud工具集的使用',
        '工作态度端正，积极性高；具有较强的沟通能力及团队合作精神；有抗压能力，能适应高强度的工作。',
      ],
      salary: '20-45K',
    },
  },
  {
    id: 7,
    category:"develop",
    name: 'COCOS-creator',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '根据需求使用COCOS CREATOR游戏引擎开发H5游戏;',
        '负责游戏客户端核心模块和业务逻辑的开发和维护工作',
      ],
      requirement: [
        '拥有2年以上棋牌类或捕鱼类等游戏开发经验;F至少熟练掌握C++/Lua/Javascript/Typescript其中一种语言，拥有良好的编码风格和习惯;',
        '熟练掌握游戏引擎（COCOS CREATOR）开发，拥有2款以上游戏开发经验;',
        '主管能动性强，热爱新技术;具备良好的团队合作意识及积极的心态',
      ],
      salary: '25-40k',
    },
  },
  {
    id: 8,
    category:"develop",
    name: 'C++',
    headCount: 10,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '基于微服务架构，负责web Api功能开发及维护;',
        '负责所编程序的自测试工作，并参加系统集成和联调',
      ],
      requirement: [
        '专科以上学历，计算机相关专业; ',
        '3年以上C++服务器开发经验; ',
        '熟悉redis应用场景以及限制 ;',
        '精通vs/gdb等开发工具进行开发，调试;',
        '精通多线程、socket、TCP/IP、HTTP协议; ',
        '熟悉数据结构，对算法有独特见解;',
        '至少熟悉一种以上php、lua开发语言;',
        '具备大型游戏服务器端程序开发经验者优先;',
        '熟悉MySQL应用开发，熟悉数据库原理和常用性能优化技术',
      ],
      salary: '25-50k',
    },
  },
  {
    id: 9,
    name: '渗透测试与攻防研究',
    headCount: 1,
    category:"test",
    time: '2023/3/1',
    detail: {
      responsibility: [
        '对业务服务器、网络设备、网站、APP应用的渗透测试、漏洞挖掘；',
        '提出漏洞修复方案并推动发现的漏洞生命周期闭环工作；',
        '编写安全测试方案、安全测试用例、安全测试报告；',
        '参与公司红/蓝军对抗，开展攻防演练，对公司的防御体系进行验证；',
        '研究和编写自动化安全测试、漏洞挖掘的方案，提升安全团队安全工作效率；',
        '研究各种最新安全攻防技术，跟踪国际国内安全社区的安全动态，不断提高公司整体安全水平；',
        '内网基础设施入侵检测思路的研究和实践，强化对最新威胁手段的防御和检测能力。',
      ],
      requirement: [
        '熟练掌握漏洞扫描、渗透测试常用工具； ',
        '了解SQL注入/XSS/CSRF/文件上传/文件包含/命令执行等漏洞原理；',
        '熟练掌握网络系统攻防，熟悉web、APP渗透测试及安全加固；',
        '会使用代码检测工具，原代码审核，懂得开发语言者优先；',
        '有漏洞提交经验或发表过安全相关文章者优先。',
      ],
      salary: '30-45K',
    },
  },
  {
    id: 10,
    name: 'PHP支付',
    category:"other",
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '充分理解任务和需求，按时按质完成系统开发，测试任务;',
        '支持运营运维部门，及时回复各类咨询;',
        '收集整理系统运行中遇到问题和用户反馈;',
        '保持技术和产品方面的学习，提高自身和系统竞争力；',
      ],
      requirement: [
        'MYSQL开发经验；',
        '熟悉至少一种常用PHP开源框架和技术（Thinkphp、Yii、Laravel、zend等）；',
        '熟悉Mysql数据库设计，脚本编写优化；',
        '熟悉MongoDB，Redis等KV数据库使用；',
        '有良好的编码习惯和撰写文档的习惯；',
        '善于主动发现问题，提出问题，解决问题',
        '具备强烈责任心、良好的团队精神； ',
        '具备良好的沟通能力和团队意识；',
        '具备较强的自学能力和意愿，开放，持续上进；',
        '有完整参与项目从立项到上线，运营，运维的经验加分',
        '有支付项目或对接第三方支付经验加分',
      ],
      salary: '25-35k',
    },
  },
  {
    id: 11,
    name: 'C#',
    category:"develop",
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责Net 桌面客户端研发;',
        'Xamarin 手机客户端研发;',
        '其他分派任务',
      ],
      requirement: [
        '熟练使用C#语言开发桌面客户端 ',
        '熟练使用Net多线程 ',
        '熟悉C#客户端和服务端通过Http，Https ,WebSocket通信 ',
        '有asp.net 或其他语言网页编程经验 ',
        '有爬虫或扒网页经验优先 ',
        '有Xamarin 或WPF编程经验优先;',
        '好学，有进取心责任感，胆大心细',
      ],
      salary: '25-50k',
    },
  },
  {
    id: 12,
    name: '运维开发',
    category:"develop",
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责开发并维护自建运维系统，熟练前后端开发 ',
        '维护自建系统，出现故障及时处理，保障系统稳定运行 ',
        '挖掘系统短板保障产品安全性 ',
        '负责公司内部自动化产品的开发，完善平台自动化的能力',
      ],
      requirement: [
        '计算机相关专业，两年以上系统维护/开发经验',
        '熟练运用js相关技术做过web前端，精通web通用框架原理',
        '熟练Django,Flask,tornado,sanic等任意2-3个Web同步/异步框架  ',
        '熟练nginx php jenkins git zabbix等服务，了解服务发现，服务注册原理',
        '熟悉基本运维工具栈，如：ansible salt zabbix ldap jenkins git ',
        '了解Docker等虚拟化技术，具备k8s并有实际使用和调整经验者加分 ',
        '了解爬虫，数据结构化相关类库加分  ',
        '具有良好的编码风格与编程习惯，有良好的技术文档读写能力和沟通能力，良好的团队合作精神',
        '拥有自我驱动的学习和工作习惯',
      ],
      salary: '25-40k',
    },
  },

  {
    id: 13,
    name: '高级运维工程师',
    category:"other",
    headCount: 10,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '参与并改进网站服务的整个生命周期，包括设计/架构/部署/运维以及持续优化；',
        '通过设计及监控可用性，延时等服务整体健康性指标，来运维网站服务；',
        '通过自动化/系统化的方式，以可持续维护的目标来扩容服务，并且主动提出和实施改进服务稳定性及运维灵活性的方案，提升整体网站的运维管理效率； ',
        '负责量化网站服务的服务质量，提升服务SLA标准',
        '负责基础运维工作、提高自动化运维水平、故障响应能力',
      ],
      requirement: [
        '专科及以上学历，3年以上大型系统运维管理工作经验；',
        '有运维管理500台以上服务器经验者优先；',
        '精通linux系统、Shell/Python编程语言、网络TCP/IP协议、数据库等，动手能力强；',
        '精通Nginx，包括不限于三方模块、配置调优、Lua集成及故障排除技巧；',
        '精通ELk，包括不限于日志采集、三方插件、监控、Dashboard等；',
        '熟悉Jenkins、Git、saltstack/ansible、tomcat、redis、mysql等常见开源组件；',
        '较强的分析和解决问题的能力，强烈责任感、 缜密的逻辑思维能力，并有很强的沟通以及主动推进问题直至解决的能力；',
        '有大型云计算或互联网公司从业经验，对于阿里云、AWS系统开发、测试、运维、应用工作经验者优先；',
        '具有容器(k8s+docker)相关的架构设计/实施/运维管理经验优先；',
        '能接受7*24小时突发事件的故障处理；',
      ],
      salary: '20-30k',
    },
  },

  {
    id: 14,
    name: '大数据运维工程师（高级）',
    category:"other",
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责公司大数据/Hadoop/hive/spark/hbase/Flume等运维管理工作； ',
        '负责内部大数据自动化运维以及数据化运营平台开发工作； ',
        '负责Hadoop/Hbase等系统的架构审核、业务监控、持续交付、应急响应、容量规划等；',
      ],
      requirement: [
        '计算机相关专业，三年以上大数据基础设施运维工作经验；',
        '掌握Python、Shell，了解java,scala开发语言尤佳，具备基本SQL编写能力；',
        '有自动化运维和监控平台经验者优先； ',
        '掌握Nginx / Apache / Tomcat 等WEB及应用服务技术的配置调优； 能够熟练配置服务器的负载均衡；',
        '熟悉Hadoop大数据生态圈，包括但不限于HDFS、YARN、Hive、HBase、Spark、kafka等； ',
        '掌握Zabbix/Cacti/Nagios等监控管理工具之一；',
      ],
      salary: '30-50K',
    },
  },
  {
    id: 15,
    name: '大数据技术专家', 
    headCount: 2,
    category:"other",
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: '',
      salary: '30-50K',
    },
  },
  {
    id: 16,
    name: '大数据测试工程师',
    category:"test",
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '理解业务需求，数据产品需求，参与需求讨论及产品设计；',
        '设计测试用例、测试工具，编写必要的测试脚本； ',
        '执行用例、报告并跟踪缺陷，推动测试中发现问题及时合理地解决； ',
        '从用户角度，在测试过程中，审查系统的友好程度及可改善点 ',
      ],
      requirement: [
        '具有3年及以上测试工作经验； ',
        '具有扎实的测试理论知识及数据库理论知识;',
        '熟练掌握一种及以上主流关系型数据库（SQL Server、Oracle、MySQL优先）;',
        '熟练使用Hive；5、熟悉Java，Python，Shell语言优先； ',
        '熟悉Hadoop、Spark、hbase、kafka等分布式开源项目；',
        '有大数据相关产品完整的项目测试经验，熟悉数据的抽取，转换以及加载过程(ETL)，对数据仓库有基础的了解；',
        '熟悉Linux操作系统及常用命令；',
      ],
      salary: '20~35K',
    },
  },
  {
    id: 17,
    name: '数据构架师',
    category:"develop",
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '根据业务分析需求，构建公司大数据平台的数据架构；',
        '使用合适的数据建模方法，对公司业务数据进行建模，以有效支撑业务分析需要； ',
        '通过与业务方的持续沟通，持续优化数据架构及数据模型',
      ],
      requirement: [
        '互联网行业数据架构及数据建模工作经验5年及以上；',
        '熟练掌握数据仓库架构设计，数据建模方法； ',
        '对数据质量治理有一定的经验或者自己的思考； ',
        '熟悉主流分布式存储及计算技术体系，如Hadoop，Spark；',
      ],
      salary: '50-80K',
    },
  },
  {
    id: 18,
    name: '数据开发工程师',
    category:"develop",
    headCount: 8,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '基于Hadoop，Spark生态技术构建大数据平台；',
        '基于Flume生态技术构建实时计算平台；',
        '基于分布式计算存储系统，进行数据仓库架构设计，数据模型设计，以及ETL开发；',
        '持续优化大数据平台，快速修复问题，快速响应业务的数据需求；',
      ],
      requirement: [
        '三年以上互联网行业大数据开发工作经验；',
        '熟练掌握Scala/Java/Python语言中的一种，熟悉Linux系统； ',
        '熟练掌握Hadoop、Spark、Flink、Hbase、Impala、Druid、Kylin技术栈中一种及以上； ',
        '有基于SparkStreaming，Flink进行实时计算开发经验优先；',
        '具有较好的数据建模经验优先；',
      ],
      salary: '30-50K',
    },
  },
  {
    id: 19,
    name: '数据产品经理',
    category:"product",
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: '',
      salary: '面议',
    },
  },
  {
    id: 20,
    name: '移动产品经理',
    category:"product",
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责 APP 相关产品规划及设计，完成用户需求调研分析、业务分析、用户行为分析，制定产品发展策略以及中长期的版本规划，制定版本迭代计划；',
        '与运营/客服/财务/等部门紧密合作，完成相应的产品需求拆解与响应，保证 APP 项目持续稳定的价值交付；  ',
        '与技术团队协作，推动产品需求落地并跟进项目研发全流程，验收产品，确保产品按时发布上线； ',
        '定期组织团队分析 APP 数据，以数据推动产品的功能迭代；',
        '跟进 APP 版本用户反馈，持续推动产品优化迭代。',
      ],
      requirement: [
        '本科及以上学历，专业不限，5年以上产品经理工作经验，两年以上团队管理经验；',
        '具备强烈的责任感与自驱力，沟通能力强，善于调动一切资源去完成目标，交付价值；',
        '思路清晰，善于思考和总结，能够快速提出多视角解决方案；',
        '熟练使用 Office 办公软件及 Axure、Visio、Xmind 等产品常用软件；了解产研协作工作流程，具备项目管理能力；',
        '落地执行力强，了解移动端产品的敏捷开发流程和产品逆向思维；',
        '至少独立负责过一条产品线，通过业务和产品的分析，制定产品的整体规划；',
        '有大型企业协作经验者优先，BAT 背景优先。',
      ],
      salary: '40~55K',
    },
  },
  {
    id: 21,
    name: '数据分析师',
    headCount: 4,
    category:"other",
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' 负责基础数据梳理，构建标签体系及分析框架负责日常分析需求响应，按时按量完成基础分析需求和报表设计开发，并能结合业务设计开发主题报表；',
        '配合平台产品或业务线对其用户数据进行深度分析与挖掘，提供数据支撑，并持续优化分析系统；能独立完成业务数据建模；并将结果转化为运营指标；',
        '完善数据报表体系，及时准确监控运营状况，并提供专业分析报告。关注产品线日常运营数据报表，从数据出发向业务部门提出相应的优化建议。',
      ],
      requirement: [
        '本科及以上学历，3 年以上互联网产品经理相关工作经验；',
        '熟悉并热衷于互联网产品，对业务有敏锐的观察力和数据洞见；',
        '了解常用的产品数据分析方法如漏斗分析、路径分析、热点分析及精准营销、交叉销售等业务导向分析方法；',
        '精通Excel、SQL、hadoop、Hive海量数据处理，有构建海量数据数据仓库经验优先；',
        '对数字有敏锐的观察力，喜欢和数字打交道，严谨细致；较强的分析能力，逻辑推理能力；',
        '较强的书面及口头表达能力； 具有较强的自主学习能力，乐于接受挑战；',
        '有责任心、具有团队合作精神，能承受一定的工作压力。 ',
      ],
      salary: '30~50K',
    },
  },
  {
    id: 22,
    name: '高级产品体验设计师',
    headCount: 2,
    time: '2023/3/1',
    category:"design",
    detail: {
      responsibility: [
        '负责客户端产品全链路体验设计, 包括完整项目从设计体验策略到UE/UI的关键设计方案的产出；',
        '负责整体产品的体验和视觉提升/迭代优化, 从用户出发提出解决方案和创新设计并落地；',
        '和产品负责人，产品经理一起构思创意，提供具备优质产品体验的整体解决方案；',
        '对产品数据的变化与用户满意度有极高的敏感度，能灵活度量产品的整体体验水平，提出改进建议；',
        '时刻关注并分析流行产品设计趋势，研究目标用户需求，体验诉求与审美倾向，并迅速优化现有产品；',
        '承担一定团队管理和培养工作, 注重方法和策略。',
      ],
      requirement: [
        '本科及以上学历，设计相关专业背景，8 年以上设计经验，有知名项目案例者优先；',
        '热爱设计，对移动互联网，有较深的理解，了解视觉设计的趋势，有全面的视觉设计理论知识；业务团队与技术团队的沟通桥梁，通过深入了解业务需求，形成 PRD 相关文档，协助技术开发满足数据要求；',
        '了解交互设计的理论知识，对于用户体验有较深的理解，能够从客观的角度进行分析和设计；',
        '对前沿科技，设计探索有浓厚兴趣，自我驱动力强，同时有驱动团队的经验；  ',
        '极度重视细节，追求像素级完美，具备人机交互设计与用户洞察能力；具备较成熟的产品思维和极强的用户同理心，能深度参与用户研究、交互设计、设计原型开发的工作； ',
        '能承担一定的工作压力，对所从事的业务有强烈的责任心； 7. BAT 背景优先考虑。 ',
      ],
      salary: '40~60K',
    },
  },
  {
    id: 23,
    name: '大数据产品经理',
    category:"product",
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '管理和分析业务方数据需求，规划数据平台整体建设目标，支持业务团队数据需求；',
        '主导数据产品的研发，与分析师、数据开发、业务方紧密合作，挖掘数据价值，形成数据产品；',
        '业务团队与技术团队的沟通桥梁，通过深入了解业务需求，形成 PRD 相关文档，协助技术开发满足数据要求；',
        '制定数据产品运营策略，协调部门及业务方持续改善产品功能和使用体验，提高决策效率； ',
        '组织跨团队协作，保证项目如期上线；',
        '通过 SQL 或机器学习模型分析，对数据进行价值追踪和质量验证。',
      ],
      requirement: [
        '本科及以上学历，5 年以上相关工作经验；',
        '有产品设计或分析经验，能够独产承担产品规划工作；',
        '具备良好的数据敏感度和业务视野，能够敏锐的捕获数据价值和产品机会；',
        '了解底层数据的架构技术以及应用层数据分析和算法的基本概念，能够运用数据作为产品解决业务方运营及决策问题；',
        '有较强的 SQL 能力，能够做基本开发和数据分析；6. 能够独立应用 Tableau 或类似分析工具进行数据分析；',
        '有参与过大型项目或系统建设的经验，有流量分析系统、管理决策系统、经营分析系统建设经验者优先；',
        '沟通能力强、有团队合作精神，精通项目管理优先； ',
        '具有银行数据经验者优先；',
        '有算法工程师或数据科学家从业经验者优先；',
        '有大数据底层开发经验者优先。 ',
      ],
      salary: '30~50K',
    },
  },
  {
    id: 24,
    name: '体育竞猜产品经理',
    category:"product",
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责体育产品线的需求调研、行业分析和竞品分析；',
        '负责产品蓝图的规划和对各个生命周期的把控；在版本落地中，协调部门内外的沟通协作，确保各部门对 PRD 的充分了解，并推进研发团队对产品规划的按期落地；',
        '监测体育线产品的业务指标数据，基于数据分析，做出相应的调优规划；',
        '收集用户反馈，解读用户诉求和用户行为，挖掘深度需求，并制定相应的应对规划。',
      ],
      requirement: [
        '5 年以上互联网行业经验，且有体育比分、资讯、体育 O2O 或 B 端竞猜产品经验者优先；',
        '热爱体育竞赛，熟悉竞彩代购业务，了解同类产品者优先； ',
        '关注 NBA、足球、网球、电竞等赛事动态，并相当熟悉相关赛事规则和动向；',
        '具备完整的产品生命周期管理经验，包括市场调研、行业分析、竞品分析、产品功能设计、迭代管理等； ',
        '具备较强的协调能力、自我驱动力，文档撰写能力，较强的逻辑思维能力，有责任心和上进心。 ',
      ],
      salary: '30~50K',
    },
  },
  {
    id: 25,
    category:"product",
    name: '移动端产品经理 (初中级)',
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责 APP 各个生命周期的需求管理；',
        '接收、整理和分析用户需求及问题反馈，完成产品的迭代规划与持续改进；',
        '将产品功能、机制、流程用恰当的工具设计为产品原型，编写产品PRD文档，以供设计、开发、测试人员明确产品需求；',
        '跟进设计及开发进度并保持高效沟通，确保产品功能特性和交互符合产品需求文档的要求； ',
        '编写文档，组织培训，帮助客服、运营、财务等关系部门理解和使用产品，并提供有效的支持；',
        '配合运营目标和方案，调整产品本身及支撑平台的功能逻辑，以实现运营目标最大化。',
      ],
      requirement: [
        '本科及以上学历，专业不限，理工科背景佳；',
        '2 年以上互联网/IT行业产品工作经验；',
        '责任心强，能及时跟进产品需求并及时处理线上问题；',
        '能够独立完成对产品的设计，撰写产品文档（包括原型、交互流程、逻辑撰写、说明文档等）。擅长交互设计者优先；',
        '了解产品开发的流程，懂得产品创新，创造竞争优势；',
        '思路清晰，善于沟通和协调资源，富有团队精神，不固执。 ',
      ],
      salary: '25~40K',
    },
  },
  {
    id: 26,
    name: '高级产品经理',
    category:"product",
    headCount: 4,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责产品线的用户需求调研、市场和竞品分析；',
        '挖掘业务部门的需求，在高效全面的分析后，提供最优的解决方案； ',
        '产出 Roadmap，落地产品设计和 PRD。并整合内外部资源，协调推进产品规划的实现；',
        '监测业务指标数据，基于数据分析，做出相应的调优规划；',
        '参与所在产品线的商业模型构建。',
      ],
      requirement: [
        '本科及以上学历，5 年以上互联网产品经验，有游戏、体育竞猜、支付相关经验者优先；',
        '具备互联网产品思维，并有优秀的产品设计能力、数据分析能力。对用户体验等细节极敏感，并能够针对性的提出产品优化方案；',
        '有较强的项目管理经验，具备优秀的系统思考能力、方案解决能力。有创新精神、较强的逻辑思维和决断力；',
        '具有较强的沟通协调能力、抗压能力和全局观；5. 协作能力突出，且擅用工具提升工作效率。',
      ],
      salary: '30~50K',
    },
  },
  {
    id: 27,
    name: '游戏数值策划(初中级)',
    headCount: 2,
    category:"other",
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责游戏内数值体系策划及实现相关工作；',
        '负责游戏策划案主体架构系统与细节设计；完成游戏数值设计、制作、跟进及测试验证；',
        '负责游戏上线效果跟进及调整总结优化，游戏核心和基础系统、功能、文案等持续调优；',
        '结合运营需求规划特色玩法和特殊活动，对数据结果负责。',
      ],
      requirement: [
        '本科及以上学历，理工科背景；',
        '1 至 3 年以上的游戏行业系统策划工作经验，游戏类型不限；',
        '能够独当一面负责游戏数值体系的方案设计和制作跟进；',
        '具备良好的，策划案能力，逻辑思维能力，分析能力；',
        '熟悉国内外体育彩票平台者优先。',
      ],
      salary: '25~40K',
    },
  },
  {
    id: 28,
    name: '游戏数值策划 (高级)',
    headCount: 1,
    category:"other",
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责公司体育平台游戏框架搭建与体系建设；',
        '编写游戏系统策划案，UI/UE 策划方案； ',
        '制定游戏数值框架，把握平台发展方向和游戏；',
        '管理游戏数值策划团队。',
      ],
      requirement: [
        '统招本科以上学历，理工科背景，5 年以上游戏数值策划经验，3 年以上游戏主策经验；',
        '熟悉手机游戏产品的开发流程和要点，对各类游戏有系统的认知，或对某类游戏有深入的了解； ',
        '善于分析各类游戏的平衡系统和经济系统设计，深刻理解玩家心态；',
        '有完整的设计理念，能根据项目整体思路和实际情况，系统化的思考和输完整的解决；',
        '精通游戏市场调研、项目开发与控制以及质量的把控；',
        '有较强的组织协调和沟通能力，具备优秀的团队协作精神与团队管理能力；',
        '有棋牌游戏、体育和彩票行业经验者优先。',
      ],
      salary: '40~55K',
    },
  },
  {
    id: 29,
    name: '前端',
    headCount: 9,
    category:"develop",
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责公司 Web 前端技术架构、技术选型、开发规范制定；公司自有产品的web端和手机前端页面开发； ',
        '负责公司前端项目框架及迭代优化工作；产品前端交互、体验、数据可视化等核心研发工作；',
        '推动 Web 前端规范化、工程化建设；带领团队探索技术改进，并解决复杂的技术问题；',
      ],
      requirement: [
        '不依赖脚手架搭建项目架构，比如vue-cli、create-react-app cli、angular-cli这些命令行自动生成的项目组织架构；',
        '对前端的数据与视图之间的关系有一定的理解，比如vue和vuex的关系，react和redux的关系，为什么要这么设计？  ',
        '对前端自动化构建流程有独立的思考能力；',
        '对ECMA Script或JavaScript语言机制有比较深的理解；',
        '必须掌握的技术栈：react、redux、typescript、rxjs、nest、es6/7、electron ',
        '对node.js服务端开发有一定的了解，比如react、vue的服务端渲染，中间件服务器（gateway）的开发；',
      ],
      salary: '30-60k',
    },
  },
  {
    id: 30,
    name: '安卓',
    category:"develop",
    headCount: 4,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '系统的功能开发迭代，涉及framework/kernel等层面的改进和用户体验提升，包括应用架构设计，性能分析和系统问题定位和解决，具备良好的创新意识；完成模块代码编写、单元测试、代码维护工作',
        '编写技术相关文档，熟练使用迭代开发方法进行系统开发；',
        '根据项目的需要快速学习并掌握相关新技术；',
        '参与项目重点、难点的技术攻坚',
      ],
      requirement: [
        '有组件化架构设计的经验 ',
        '理解至少6种设计模式,读过至少一个优秀的开源项目  ',
        '熟练开发自定义控件和动画 ',
        '具有安全，性能（渲染速度、内存优化、电量消耗等）方面的开发经验 ',
        '能定位卡顿和OOM问题  ',
        '熟悉NDK开发,并有NDK开发经验 ',
        '熟练使用单元测试对负责的模块进行质量保证；',
        '有良好的编码习惯、学习能力和英文文档阅读能力；',
        '熟悉okhttp,retrofit,还有rxjava的,熟悉自定义控件以及动画的优先',
      ],
      salary: '30-60k',
    },
  },
  {
    id: 31,
    name: '移动技术副总监',
    headCount: 1,
    category:"other",
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责客户端产品线(IOS/Android/和web前端)的技术研发管理工作； ',
        '负责指导成员进行移动平台的架构分析，持续进行架构升级，性能优化与技术演进; ',
        '负责招聘和培养开发工程师，及一线管理人员，构建技术梯队；',
        '负责优化推进项目流程，质量评估，人员绩效等工作;',
        '参与公司整体项目团队协调沟通与跨团队推进; ',
        '关注行业技术动态，保持团队技术持续更新',
      ],
      requirement: [
        '计算机相关专业，本科及以上学历，7年以上的互联网产品研发经验；',
        '至少精通一种移动平台(android/ios)，同时具有良好的技术广度及全局技术设计能力，有后端开发经验加分；',
        '熟悉主流移动端框架, 如组件化，Hybrid方案等，有实践经验及选型能力; ',
        '熟悉移动端性能，质量评估及优化方式，有实践经验;',
        '3年以上的移动端管理经验，熟悉开发流程以及相关的技术架构和最佳实践；30人以上2线团队管理经验加分；',
        '有良好的技术基础及技术认知能力，具备设计模式，网络，安全设计等基本知识；',
        '对产品体验敏感，能够快速了解业务需求，有技术驱动体验的优化意识; ',
        '有良好的学习认知思维，与沟通表达能力; ',
        '履历中有一二线互联网公司背景，参与过知名移动产品加分；',
      ],
      salary: '80-120k',
    },
  },
  {
    id: 32,
    name: '高级JAVA',
    category:"develop",
    headCount: 8,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '参与产品的架构设计讨论和技术调研; ',
        '搭建系统开发环境，完成系统具体的设计、编码及单元测试',
        '撰写系统详细设计文档',
      ],
      requirement: [
        '3年以上JAVA项目开发经验；有过大型项目经验者优先考虑；',
        '熟悉设计模式，具有面向对象的分析和设计的能力；具有丰富的J2EE架构设计经验 ',
        '熟悉Spring、Mybatis、Redis、MQ等常用框架 ',
        '熟悉Mysql等常用数据库开发及调优；',
        '熟悉Tomcat及Linux操作系统；',
        '熟练应用rabbitmq、redis、mongodb、dubbo、zookeeper、springboot中的一种或几种优先；',
        '熟悉微服务架构及SpringCloud工具集的使用 ',
        '工作态度端正，积极性高；具有较强的沟通能力及团队合作精神；有抗压能力，能适应高强度的工作。',
      ],
      salary: '30-60k',
    },
  },
  {
    id: 33,
    name: '高级运营设计',
    category:"operate",
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '独立完成产品的活动运营、品牌运营的创意及设计;',
        '根据公司产品方向，进行品牌定位、策划与设计，整体把控品牌调性;',
        '有成熟的工作经验，可独立完成设计工作及确保项目及时推进;',
        '能够快速理解用户和业务需求，对运营数据有了解并沉淀设计方法与经验;',
        '擅长把握各种设计风格，探索研究动态设计、视频剪辑、前端技术等，并结合应用到设计中',
      ],
      requirement: [
        '有3年以上运营视觉设计经验，有独立完成运营设计项目的能力和成功案例',
        '优秀的逻辑、沟通、创新能力，具备全面、系统化思维，抗压力强  ',
        '艺术类、数字媒体传达等相关专业背景，专业基本功扎实，审美水平优秀; ',
        '有优秀的手绘、动态设计能力者优先',
      ],
      salary: '15-30k',
    },
  },
  {
    id: 34,
    name: '高级UE设计师',
    headCount: 4,
    time: '2023/3/1',
    category:"design",
    detail: {
      responsibility: [
        '负责产品前后端交互设计工作；',
        '配合PM、UI以及RD快速高质量的输出交互文档；',
        '负责梳理定制产品的设计规范，提高产品的试用体验；',
        '指导新人。',
      ],
      requirement: [
        '热爱生活，对美好的事物有追求，',
        '2-5年交互工作经验，熟悉互联网、移动互联网产品开发流程；',
        '有较强的沟通能力，可以充分理解产品需求、使用场景；',
        '爱思考，有较强的逻辑思维、学习能力以及同理心；',
        '有自驱力、工作目标感和计划性；',
        '必须有完整的工作总结、不限于项目、方法论沉淀等；',
        '以上足够优秀可以放宽条件，有用研、广告领域、B端、工具类、PC平台工作经验加分。工业设计、人机交互、心理学、计算机等相关专业毕业优先，大公司背优先。',
      ],
      salary: '18-30k',
    },
  },
  {
    id: 35,
    name: '品牌平面设计师',
    headCount: 1,
    category:"design",
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责集团公司的品牌vi、品牌升级优化、市场活动物料设计；',
        '负责产品形象发展方向，梳理视觉表现落地',
      ],
      requirement: [
        '3-5年品牌相关设计经验，美术、设计或相关专业 ',
        '有口碑品牌设计经验者优先，美术、设计或相关专业 ',
        '擅长各种视觉风格表现，能够准确抓到品牌调性',
      ],
      salary: '15-25k',
    },
  },
  {
    id: 36,
    name: '高级UI',
    category:"design",
    headCount: 9,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责公司pc、app产品的前期视觉推导完成设计方案，制定设计规范，熟悉设计流程；',
        '参与前瞻性产品的创意设计和动态DEMO的实现。 ',
      ],
      requirement: [
        'UI设计5年以上工作经验；',
        '对企业vi系统设计有一定的经验；',
        '有在互联网企业经验者有优先：腾讯、百度、乐视、爱奇艺等 ',
        '精通多种设计软件；',
        '精通网页设计，对html+css有一定的了解，对于交互效果有着丰富的经验 ',
        '精通手机端设计（H5,APP），熟悉种各种机型的设计规范，熟练快速处理界面的切片和标注，并提供最优的适配方案；',
        '精通网页设计，对html+css有一定的了解，对于交互效果有着丰富的经验',
      ],
      salary: '18-30k',
    },
  },

  {
    id: 37,
    name: 'UI设计专家',
    category:"design",
    headCount: 5,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '进行高质量的业务/产品ui的视觉设计，主导视觉规范制定；',
        '站在用户角度挖掘需求，为产品功能和特性提供合理的系统化设计解决方案；',
        '独立承担项目，引领团队ui视觉设计思维意识的创新，实现产品视觉呈现在用户体验上和视觉上的突破；',
        '设计经验丰富，参与app、web、h5等界面设计，具备强悍的设计推动力。',
      ],
      requirement: [
        '科班出身，美术院校设计专业毕业，对设计有独特的理解和系统的学习（非美术院校毕业一律不予考虑）；',
        '5年及以上相关工作经验，主导过多套成型成熟的UI设计作品，有丰富的多平台产品视觉设计经验；',
        '对各种互联网产品趋势有敏锐的设计触觉，富有设计创造力和激情，有探索研究精神；',
        '强逻辑思维能力，掌握业务需求分析、产品需求分解的技巧；',
        '学习能力强，具有良好的沟通和设计表达能力，良好的团队协作态度，主动积极，踏实细心，协调能力强',
      ],
      salary: '50-80k',
    },
  },

  {
    id: 38,
    name: '设计副总监',
    category:"design",
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责把控产品的视觉设计方案；负责设计团队的日常管理、设计审核，协调项目相关的设计资源，把握进度，推动视觉及交互方案的落地；',
        '跟踪互联网行业设计趋势，研究行业、竞争对手等产品设计新概念，为产品长远规划决策提供信息支持；',
        '配合市场、运营及产品团队，提出视觉、创意及交互的意见与方案 ；',
        '参与新产品功能的构思，站在用户角度提出使用习惯的需求；',
        '梳理优化设计团队工作流程；具有一定的设计影响力，且有创新精神',
        '定义产品的设计规范，保证产品与服务体验一致性；',
        '指导从初级到高级的设计师，进行人员培养和成长管理,分享设计经验、推动提高团队的设计能力； ',
        '协助设计师与产品、运营、研发等部门间保持良好的沟通与协作',
      ],
      requirement: [
        '全日制设计专业本科及以上学历，设计相关专业，4年及以上的管理经验，且有成熟的线上产品设计经验，知名互联网任职经历优先； ',
        '具备深厚的设计理论和设计功底，在用户体验设计及相关领域有深入的理解与实践有优秀的艺术审美功底；',
        '了解国际化的设计风格，时刻关注前沿的产品设计趋势，能够独立带领团队完成从设计构思到设计表达的整个过程；',
        '能够对自身产品及行业、竞争对手等设计有较为深刻的研究和理解；',
        '沟通协作能力优秀，团队管理能力及推动执行能力突出。',
        '有良好的大局观，擅长拥抱变化；',
        '优秀的沟通及团队合作能力和推动力；',
        '保持好奇，乐于学习，充满激情，关注细节； ',
        '善于总结和设计经验，并乐于分享',
      ],
      salary: '40-60k',
    },
  },
  {
    id: 39,
    name: '游戏界面设计师',
    category:"design",
    headCount: 10,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '设定游戏界面的视觉创意和风格； ',
        '按照已定界面风格的原形标准，完成各级界面的延展性设计；',
        '完成界面相关标志、图标、图素的设计与绘制；',
        '跟进设计效果最终在产品的实现，保证设计品质与实现品质一致',
        '有效管理界面资源，对界面编辑器等工具、方法进行合理优化',
      ],
      requirement: [
        '具有扎实的美术功底，良好的色彩搭配感及设计造型的能力；',
        '有较强的手绘能力，能独立设计精绘界面中的图素与图标；',
        '熟练使用Photoshop，Illustrator等相关制图软件；',
        '工作态度积极，具有良好的工作协作能力、团队合作精神，善于沟通；',
        '熟悉cocs2d和unity3d引擎的设计还原相关原理；',
        '有棋牌游戏经验或者成功线上游戏作品优先。',
      ],
      salary: '18-30k',
    },
  },
  {
    id: 40,
    name: 'CEO助理',
    category:"other",
    headCount: 5,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '处理各类英文合同文件，与当地各政府机构对接； ',
        '跟进项目进程，并统计项目最新进度及时反映项目中存在的不足 ',
        '会议纪要以及其他领导交代的事宜',
      ],
      requirement: [
        '熟练听读写英语，熟练使用办公软件，能协助处理一些商务工作，项目跟进。',
        '有bc工作经验者排除。',
        '中国籍，背景干净，通过初步面试者，需要来菲进行当面面试。',
        '有出国工作经验或者国外留学经验',
      ],
      salary: '30-100k',
    },
  },
  {
    id: 41,
    name: '招聘副主管',
    category:"other",
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '根据公司战略，完成年度人力资源规划，掌握各部门招聘需求，控制人力成本的支出；',
        '协助部门负责人进行组织架构设计和岗位设置，协助梳理和完善公司的岗位说明书；',
        '创建公司各岗位胜任力模型、人才测评模型；通过人职匹配体系，为公司招聘、晋升提供科学的决策依据。',
        '指导督促各子公司拟定招聘方案以及各项具体活动的开展，保证招聘任务顺利完成；',
        '协助统筹招聘准备、实施（招募、甄选、录用）、评估、配置全过程。',
      ],
      requirement: [
        '大专以上学历，人力资源管理、工商管理等相关专业毕业；',
        '5年以上人力资源工作经验，3年以上招聘管理工作经验，有互联网、电商行业招聘管理经验优先；',
        '精通招聘模块，熟练应用各种招聘工具及面试方法；',
        '良好的沟通表达能力、亲和力、协调能力及洞察力；',
      ],
      salary: '20-50k',
    },
  },
  {
    id: 42,
    name: '测试',
    category:"test",
    headCount: 7,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '根据产品规格和测试需求，编写测试方案、测试用例，(APP 经验的优先)；',
        '熟悉主流测试工具，包括抓包工具、自动化测试工具, 性能测试，异常测试工具等，(Linux 环境下测试经验的优先)；',
        '熟悉主流的测试用例管理工具及缺陷管理工具；',
        '掌握良好的测试理念、测试方法，具有良好的业务理解能力、交流、沟通能力，能够清晰准确的描述BUG、跟踪BUG；',
        '熟悉TCP/IP协议、HTTP协议；',
        '有较好的协调能力，以及具有良好的团队意识和合作精神；',
        '熟悉Linux系统、MySQL数据库经验者优先； ',
        '三年以上测试工作经验，知名 APP 公司工作经验优先；9.做事认真，有责任心，有工作热情，能吃苦耐劳',
      ],
      requirement: [
        '根据产品规格和测试需求，编写测试方案、测试用例，(APP 经验的优先)；',
        '熟悉主流测试工具，包括抓包工具、自动化测试工具, 性能测试，异常测试工具等，(Linux 环境下测试经验的优先)；',
        '熟悉主流的测试用例管理工具及缺陷管理工具；',
        '掌握良好的测试理念、测试方法，具有良好的业务理解能力、交流、沟通能力，能够清晰准确的描述BUG、跟踪BUG；',
        '熟悉TCP/IP协议、HTTP协议；',
        '有较好的协调能力，以及具有良好的团队意识和合作精神；',
        '熟悉Linux系统、MySQL数据库经验者优先； ',
        '三年以上测试工作经验，知名 APP 公司工作经验优先； ',
        '做事认真，有责任心，有工作热情，能吃苦耐劳',
      ],
      salary: '20-35k',
    },
  },
  {
    id: 43,
    name: 'Android安卓开发',
    category:"test",
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '系统的功能开发迭代，涉及framework/kernel等层面的改进和用户体验提升，包括应用架构设计，性能分析和系统问题定位和解决，具备良好的创新意识；完成模块代码编写、单元测试、代码维护工作 ',
        '编写技术相关文档，熟练使用迭代开发方法进行系统开发；',
        '根据项目的需要快速学习并掌握相关新技术；',
        '参与项目重点、难点的技术攻坚',
      ],
      requirement: [
        '有组件化架构设计的经验 ',
        '理解至少6种设计模式,读过至少一个优秀的开源项目  ',
        '熟练开发自定义控件和动画 ',
        '具有安全，性能（渲染速度、内存优化、电量消耗等）方面的开发经验 ',
        '能定位卡顿和OOM问题  ',
        '熟悉NDK开发,并有NDK开发经验  ',
        '熟练使用单元测试对负责的模块进行质量保证； ',
        '有良好的编码习惯、学习能力和英文文档阅读能力；',
        '熟悉okhttp,retrofit,还有rxjava的, 然后熟悉自定义控件以及动画的优先',
      ],
      salary: '20-40K',
    },
  },

  {
    id: 44,
    name: 'DBA',
    category:"other",
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [],
      requirement: [
        '十年以上数据库架构、设计经验，有一定开发基础，有丰富的专业领域知识。',
        '精通关系数据库模型，并对非关系型数据库模型也有所了解。',
        '精通多种主流数据库如Oracle、Mysql、MongoDB等，并熟悉其他NoSQL及NewSQL数据库，如Redis、Cassandra、TIDB等。',
        '熟悉不同数据库、不同平台的优势、劣势，并能科学地作出决策。',
        '熟悉操作系统（Linux）、存储、网络技术，并对虚拟化、云等有所了解。',
        '熟悉主流软件架构，了解多种开发语言并至少掌握一门作为开发语言，有一定的开发能力',
        '具备敏锐的技术前瞻性。',
        '具有良好的沟通、协调能力，能承受较大的工作压力。',
        '对于复杂问题，具有很强的分析判断和解决能力。',
        '对于新技术具有很强的学习能力。',
        '具有完备的方案编写能力。',
      ],
      salary: '30-60K',
    },
  },
  {
    id: 45,
    name: 'IOS开发工程师',
    category:"develop",
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [],
      requirement: [
        '具备3年以上iOS开发经验；',
        '具有扎实的Objective-C语言基础，熟练掌握Swift；',
        '熟悉常见的数据结构以及设计模式；',
        '深入理解UIKit、Core Animation以及Core Graphics等系统框架；',
        '深入理解Objective-C Runtime运行机制和内存管理机制；',
        '有一定性能调优经验，熟练使用Instruments等性能调优工具；',
        '熟悉网络通信机制及常用数据传输协议，具有一定移动网络优化经验；',
        '具备良好的学习能力，对新技术有敏锐的嗅觉；',
        '具备独立分析并解决问题的能力，能独立承担工作任务',
      ],
      salary: '30-50K',
    },
  },

  {
    id: 46,
    name: '项目主管',
    headCount: 2,
    category:"other",
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责公司产品研发项目开发的组织与管理，包括项目启动、执行、监控、变更、验收等的组织与管理；',
        '沟通项目需求，从技术角度评估需求的合理性，评估项目开销（人力、周期、风险、预算等）及可行性；',
        '在系统开发阶段,引导高级开发输出概要设计和实现方案初稿，保证实现符合业务需求; ',
        '根据项目进度,参与相关文档的编写,如概要设计文档、需求变更文档、培训计划、上线发布计划等； ',
        '熟悉系统流程,参与组织测试用例,组织产品进行验收测试,并记录测试过程中发现的系统问题及提出的需求优化建议;',
        '解答业务系统操作问题,对系统问题、软件变更需求等内容进行及时跟踪与反馈； ',
        '负责承担产品研发项目的过程控制、成本控制、进度控制、质量控制等，确保按时、保质完成项目.',
        '负责相关团队（产品、开发、测试、运维等）的沟通配合与协调工作；',
        '参与公司软件技术框架的确立，控制团队代码质量。',
      ],
      requirement: [
        '统招全日制本科及以上学历，计算机相关专业，三年以上工作经验，具有团队管理经验或长期从事同一产品开发工作。 ',
        '5年以上软件项目实施经验,对信息化流程有较为深入的个人理解,能撰写解决方案,有项目管理者优先；  ',
        '具备良好的学习能力,思维敏捷,善于沟通,良好的书面与口头表达能力,组织协调能力较强; ',
        '熟悉JAVA、pathon等任意一门开发技术,熟悉 MYSQL、 Oracle数据库技术。要求有一门语言经验（测试也可以），年龄不能低于27，最好是男性  ',
        '有pmp证书优先',
      ],
      salary: '20-35k',
    },
  },
  {
    id: 47,
    name: '财务总监',
    category:"other",
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '负责制订年度财务规划，确保企业正常运营. ',
        '负责拟定年度、季度、月度财务预结算报表，并上报相关领导。 ',
        '负责建立健全财务管理制度，有效进行财务风险管控。',
        '负责资产统筹与管理，节省开支，控制成本。',
        '实时监控财务账目及流水，进行财务报表分析与建议。 ',
        '日常财务核算与清算工作。 ',
        '领导交待的其他工作。',
      ],
      requirement: [
        '211、985学校全日制本科以上学历；',
        '须具备注册会计师资格，同时具备其他执业资格（如ACCA、AICPA等）优先；',
        '十年以上企业财务管理工作经验，三年以上全面财务管理经验； ',
        '精通审计法律规范； ',
        '为人正直、处事灵活、责任心强、工作细致严谨，有良好的职业道德；  ',
        '有较强的人际沟通和协调能力，良好的纪律性 ',
      ],
      salary: '40-50K',
    },
  },
  {
    id: 49,
    name: '业务支付总监',
    category:"other",
    headCount: 5,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '根据集团的发展战略，制定公司中长期发展规划，并全面负责第三方支付的日常经营管理工作； ',
        '确立公司业务发展目标和计划并组织实施，实现公司业务和投资回报的快速增长； ',
        '健全和完善公司风险管理体系，推动公司风险管理水平的提升，加强对各项业务的动态监控，优化监控流程，确保公司业务在风险可控、可预测的情况下开展；',
        '负责第三方支付团队的管理，推进保障项目按时正常推进，不断提升安全系统和客户体验；',
      ],
      requirement: [
        '本科以上学历金融行业3年以上同等职务工作经验；',
        '具有第三方支付类行业营运经验；',
        '具备团队管理经验，熟悉团队激励体系的建设和实施；',
        '精通银行审计法律规范；',
        '为人正直、处事灵活、责任心强、工作细致严谨，有良好的职业道德； ',
        '有较强的人际沟通和协调能力，良好的纪律性',
      ],
      salary: '40-50K',
    },
  },
];

export const ENJOBLIST = [
  {
    id: 1,
    name: 'php',
    headCount: 7,
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: [
        'Bachelor degree or above, 3 years or more PHP development experience;',
        'Familiar with the use of LNMP environment and building, debugging, troubleshooting, optimization, deployment, etc.;',
        'Familiar with MySQL database development and common optimization methods;',
        'Familiar with Redis, and other caching technologies;',
        'Familiar with at least one of Laravel, Phalcon, ThinkPHP frameworks;',
        'Familiar with common Linux commands; ',
        'Knowledge of common scenario design, such as message queues, caching, high concurrency, high availability, high load, etc. (experience in developing websites with 10 million traffic is preferred);',
        'Familiarity with html/css/js and frameworks such as jQuery is preferred;',
        'Familiarity with Lua, GoLang, Python, Kafka and other technologies is preferred.' ,
      ],
      salary: '25-40K',
    },
  },
  {
    id: 2,
    name: 'Golang',
    headCount: 15,
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: [
        'Computer or mathematics related majors, bachelor degree or above',
        'Responsible for the development and maintenance of all functions of management backend and front-end Api based on microservices architecture, continuously improving code quality, scalability and maintainability;',
        'At least two years of golang project experience and more than three years',
        'Project experience in other languages (PHP/C++) Proficient in MySQL application development, familiar with database principles and common performance optimization techniques, as well as NoSQL principles, usage scenarios and limitations, proficient in redis; familiar with at least one kind of message queue Kafka/Rabbitmq/Redis, etc. Responsible for self-testing work of the program compiled, and participate in system integration and co-tuning.',
        'Experience in distributed system design and development is preferred.' ,
        'Proficient in TCP/IP, HTTP protocols, including but not limited to RPC, call strategies, etc.; proficiency in Protobuf/JSON serialization protocol is preferred',

      ],
      salary: '28-45K',
    },
  },
  {
    id: 3,
    name: 'Frontend',
    headCount: 5,
    time: '2023/3/1',
    detail: {
      responsibility: ['Responsible for the front-end development, maintenance and optimization of the company\'s projects, and complete the core code writing',
      'Communicate with product and design, and be able to give front-end technical solutions and implement interaction prototypes for different business requirements',
      'Optimize the performance of front-end related projects, reduce loading, execution and rendering time, and improve user experience',
      'Cross-departmental coordination and communication, and be able to gradually precipitate front-end tools and components in projects'],
      requirement:  [  '3+ years of web/h5 development experience, with involvement in complex projects.' ,
      'Proficient in various front-end technologies, including HTML/CSS/JavaScript/Node.JS, etc. At least one of the three directions of Web (PC+Mobile)/Node.js/Native App, with more than one is a plus',
      'Knowledge of front-end engineering and modular development, proficiency in using webpack and other tools, experience in packaging and optimization', 
      'Proficiency in using git for project code management and version control, and following certain popular specifications', 
      'skilled in using or proficient in react, vue framework to understand the principles and have some research on the source code, able to write high-performance logic code based on the framework', 
      'Good teamwork spirit, able to use their technical skills to improve the overall efficiency of the team\'s research and development, and improve the impact of the team; continuous enthusiasm for front-end technology, optimistic and cheerful personality, logical and good at cooperation',
      'Plus (meet one of them can): Nodejs back-end or js full-stack product development experience; cross-platform technology RN/Weex/Flutter; complex enterprise back-end application development experience; large-scale/high-load scenario NodeJS application development, operation and maintenance experience; skilled or proficient in relational or non-relational database operation',
      'Remarks: Whatsapp and Facebook project experience required'],
      salary: '20-35K',
    },
  },
  {
    id: 4,
    name: 'Test Manager',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [        'Establish and improve departmental staff work structure system, organize and develop company departmental management system, methods and processes, supervise and promote the implementation of the system, methods and processes',
      'Plan project testing strategy, review R&D solutions, participate in version requirement scheduling, and synchronize with team leader to assign tasks, if there is risk in version scheduling, communicate and solve in time', 
      'Control the version progress, risk and quality, track and understand the version on-line status and verification results', 
      'Assist the team leader to train and backup the team members, including business knowledge, skills, management, etc.',
      'Coordinate, deploy and solve the situation of insufficient manpower in a certain team during the requirements testing phase, and realize flexible backup of manpower between teams',
      'Observe and identify the risk of personnel dynamics, and if there is risk, communicate and solve it in time or report to the leadership',
      'Participate in evaluating performance and other rewards, synchronize with team leaders, and intervene in team member communication when necessary',
      'Respond to online issues and organize regular meetings to review and discuss how to avoid recurrence',
      'Explore talent, and also participate in interviews to recruit excellent talent for the company',
      'Communicate the company system and information, and supervise the staff to implement it',
      'Organize cultural construction work such as entertainment and dinners for the department to which you belong and enhance the cohesion of the department staff',
      'Manage the company\'s departmental resources and equipment, responsible for procurement, statistics, daily use supervision'],
      requirement: [   '5+ years of software testing experience',
      'Have led a testing team of 8~20 people and independently undertake small version delivery',
      'Proficiency in testing theory and concepts, test case writing',
      'Proficiency in test management tools and defect management tools',
      'Proficiency in testing theory, process, methodology and common testing tools',
      'Familiar with one language such as Java/Shell/PHP/Python/Ruby, familiar with Linux common operation commands, familiar with Oracle/MySQL database basic operation',
      'Good at performance testing, automation testing, security testing, interface testing, business functional testing in at least one area, with outstanding ability',
      'Good communication skills, comprehensive coordination and understanding'],
      salary: '30-40K',
    },
  },
  {
    id: 5,
    name: 'Test',
    headCount: 6,
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: [
        'Write test plans and test cases according to product specifications and testing requirements, (APP experience is preferred)',
        'Familiar with mainstream testing tools, including packet capture tools, automated testing tools, performance testing, exception testing tools, etc., (Linux environment testing experience is preferred)',
        'Familiar with mainstream test case management tools and defect management workers',
        'Good command of testing concepts, testing methods, good business understanding, communication skills, ability to describe bugs clearly and accurately, tracking BU',
        'Familiar with TCP/IP protocol, HTTP protocol',
        'Good coordination skills, as well as a good sense of teamwork and cooperation',
        'Familiarity with Linux system, MySQL database experience is an advantage',
        'Three years of experience in testing, preferably in a well-known APP company',
        'Conscientious, responsible, enthusiastic, hard-working',
      ],
      salary: '15-25K',
    },
  },
  {
    id: 6,
    name: 'Senior JAVA',
    headCount: 10,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Participate in product architecture design discussions and technical research',
        'Build system development environment, complete system specific design, coding and unit testing ',
        'Write detailed system design documents',
      ],
      requirement: [
        '3+ years of JAVA project development experience; experience with large projects is preferred;',
        'Familiar with design patterns, with object-oriented analysis and design skills; rich experience in J2EE architecture design',
        'Familiar with Spring, Mybatis, Redis, MQ and other common frameworks',
        'Familiar with Tomcat and Linux operating system;',
        'Proficiency in applying one or more of rabbitmq, redis, mongodb, dubbo, zookeeper, springboot preferred;',
        'Familiarity with microservices architecture and use of SpringCloud toolset',
        'Good work attitude and high motivation; strong communication skills and teamwork spirit; stress tolerance and ability to adapt to high intensity work.' ,
      ],
      salary: '20-45K',
    },
  },
  {
    id: 7,
    name: 'COCOS-creator',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Develop H5 games using COCOS CREATOR game engine according to demand;',
        'Responsible for the development and maintenance of the core modules and business logic of the game client',
      ],
      requirement: [
        'Have more than 2 years of experience in game development such as chess or fishing;F be proficient in at least one of the languages C++/Lua/Javascript/Typescript, with good coding style and habits;',
        'Proficiency in game engine (COCOS CREATOR) development, with more than 2 games development experience;',
        'Strong motivation and love for new technologies; good teamwork and positive mindset',
      ],
      salary: '25-40k',
    },
  },
  {
    id: 8,
    name: 'C++',
    headCount: 10,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for web Api function development and maintenance based on microservice architecture;',
        'Responsible for the self-testing of the compiled programs and participate in system integration and co-tuning',
      ],
      requirement: [
        'College degree or above, computer related majors; ',
        '3+ years of C++ server development experience; ',
        'Familiar with redis application scenarios as well as limitations ;',
        'Proficient in vs/gdb and other development tools for development, debugging;',
        'Proficient in multi-threading, sockets, TCP/IP, HTTP protocols; ',
        'Familiar with data structures and unique insights into algorithms;',
        'Familiar with at least one of the above php, lua development languages;',
        'Experience in large game server-side program development is preferred;',
        'Familiar with MySQL application development, familiar with database principles and common performance optimization techniques',
      ],
      salary: '25-50k',
    },
  },
  {
    id: 9,
    name: 'Defensive Research',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Penetration testing and vulnerability mining of business servers, network devices, websites, and APP applications;',
        'Propose vulnerability remediation solutions and promote the life cycle closure of discovered vulnerabilities;',
        'Write security test plans, security test cases, security test reports;',
        'Participate in company red/blue confrontation, carry out offensive and defensive exercises, and validate the companys defense system;',
        'Research and write solutions for automated security testing and vulnerability mining to improve the security efficiency of the security team',
        'Research various latest security attack and defense technologies, track the security dynamics of international and domestic security communities, and continuously improve the overall security level of the company;',
        'Research and practice of intranet infrastructure intrusion detection ideas to strengthen the defense and detection capabilities of the latest threat means.' ,

      ],
      requirement: [
        'Proficiency in vulnerability scanning, penetration testing common tools; ',
        'Understanding of SQL injection/XSS/CSRF/file upload/file containment/command execution and other vulnerability principles;',
        'Proficiency in network system attack and defense, familiar with web and APP penetration testing and security reinforcement;',
        'Will use code detection tools, original code review, know development language is preferred;',
        'Experience in vulnerability submission or published security-related articles is preferred.' ,
      ],
      salary: '30-45K',
    },
  },
  {
    id: 10,
    name: 'PHP to Paypal',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Fully understand the tasks and requirements, complete the system development and testing tasks on time and with quality;',
        'Support the operation and maintenance department and respond to all kinds of inquiries in a timely manner;',
        'Collect and organize problems and user feedback encountered in system operation;',
        'Maintain technical and product learning to improve their own and system competitiveness;',
      ],
      requirement: [
        'MYSQL development experience;',
        'Familiarity with at least one common PHP open source framework and technology (Thinkphp, Yii, Laravel, zend, etc.);',
        'Familiar with Mysql database design, scripting optimization;',
        'Familiar with MongoDB, Redis and other KV databases use;',
        'Good coding habits and documentation writing habits;',
        'Good at taking initiative to find problems, ask questions and solve problems',
        'Strong sense of responsibility and good team spirit; ',
        'Good communication skills and team spirit;',
        'Possess strong self-learning ability and willingness to be open and continuously motivated;',
         'Experience of complete participation in projects from inception to launch, operation, O&M is a plus',
         'Experience in payment projects or interfacing with third party payments is a plus',
      ],
      salary: '25-35k',
    },
  },
  {
    id: 11,
    name: 'C#',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for Net desktop client development;',
        'Xamarin mobile client development;',
        'Other assigned tasks',
      ],
      requirement: [
        'Proficiency in developing desktop clients using C# language ',
        'Proficiency in using Net multithreading ',
        'Familiar with C# client-side and server-side communication via Http, Https , WebSocket ',
         'Experience in web programming in asp.net or other languages ',
         'Experience in crawling or picking web pages is preferred ',
         'Experience in Xamarin or WPF programming is preferred;',
         'Good at learning, aggressive sense of responsibility, bold and careful',
      ],
      salary: '25-50k',
    },
  },
  {
    id: 12,
    name: 'Operations Development',
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' Responsible for developing and maintaining self-built operation and maintenance system, skilled in front and back-end development ',
        'Maintain the self-built system, deal with faults in a timely manner, and ensure the stable operation of the system ',
         'Digging system shortcomings to ensure product security ',
         'Responsible for the development of the company\'s internal automation products, improve the ability of platform automation ',
      ],
      requirement: [
        'Computer related major, more than two years of experience in system maintenance/development',
        'Proficiency in using js related technologies to do web front-end, proficient in web general framework principles',
        'Proficiency in Django, Flask, tornado, sanic and any 2-3 web synchronous/asynchronous frameworks',
        'Proficiency in nginx php jenkins git zabbix and other services, understanding of service discovery, service registration principles',
         'Familiar with basic Ops tool stack, such as: ansible salt zabbix ldap jenkins git ',
         'Understanding of virtualization technologies such as Docker, with k8s and practical use and tuning experience is a plus ',
         'Knowledge of crawlers, data structuring related libraries is a plus ',
        'Good coding style and programming habits, good ability to read and write technical documents and communication skills, good teamwork spirit',
         'Self-driven learning and working habits',
      ],
      salary: '25-40k',
    },
  },

  {
    id: 13,
    name: 'Senior Operations',
    headCount: 10,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Participate and improve the entire lifecycle of web services, including design/architecture/deployment/operations and continuous optimization;',
        'Operate and maintain web services by designing and monitoring overall service health metrics such as availability, latency, etc.;',
         'Scaling services in an automated/systematic manner with the goal of sustainable maintenance, and proactively proposing and implementing solutions to improve service stability and flexibility of operations and maintenance to enhance the overall website operations and management efficiency;',
        'Responsible for quantifying the service quality of website services and improving service SLA standards',
         'Responsible for basic operation and maintenance work, improve the level of automated operation and maintenance, fault response capability',
      ],
      requirement: [
        'Specialist or above, 3+ years of experience in large system operation and maintenance management;',
        'Experience in operation and maintenance management of more than 500 servers is preferred;',
        'Proficient in linux system, Shell/Python programming language, network TCP/IP protocol, database, etc., strong hands-on skills;',
        'Proficient in Nginx, including but not limited to three-way modules, configuration tuning, Lua integration and troubleshooting skills;',
        'Proficient in ELk, including but not limited to log collection, three-party plugins, monitoring, Dashboard, etc.;',
        'Familiar with common open source components such as Jenkins, Git, saltstack/ansible, tomcat, redis, mysql, etc.;',
        'Strong analytical and problem solving skills, strong sense of responsibility, meticulous logical thinking skills, and strong ability to communicate and take the initiative to drive issues to resolution;',
        'Experience in large cloud computing or Internet companies, for Ali cloud, AWS system development, testing, operations and maintenance, application work experience is preferred;',
        'Experience in container (k8s+docker) related architecture design/implementation/operations and maintenance management is preferred;',
         'Ability to accept 7*24 hours of unexpected events troubleshooting;',
      ],
      salary: '20-30k',
    },
  },

  {
    id: 14,
    name: 'bgSenior Operations',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' Responsible for the company\'s big data/Hadoop/hive/spark/hbase/Flume and other operation and maintenance management work;',
        ' responsible for internal big data automation operation and maintenance as well as data-based operation platform development work; ',
        'Responsible for architecture audit, business monitoring, continuous delivery, emergency response, capacity planning, etc. of Hadoop/Hbase and other systems;',

      ],
      requirement: [
        'Computer-related professional, more than three years of experience in big data infrastructure operation and maintenance;',
         'Mastering Python, Shell, java, scala development language is especially good, with basic SQL writing skills;',
         'Experience in automation and monitoring platform is preferred; ',
         'Master the configuration and tuning of WEB and application service technologies such as Nginx / Apache / Tomcat; be able to skillfully configure the load balancing of servers; ',
        'Familiarity with Hadoop big data ecosystem, including but not limited to HDFS, YARN, Hive, HBase, Spark, kafka, etc.; ',
        'Mastery of one of the monitoring management tools such as Zabbix/Cacti/Nagios;',


      ],
      salary: '30-50K',
    },
  },
  {
    id: 15,
    name: 'Technology Specialist',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: '',
      salary: '30-50K',
    },
  },
  {
    id: 16,
    name: 'Test Engineer',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Understand business requirements, data product requirements, participate in requirement discussions and product design;',
        'Design test cases, test tools, and write necessary test scripts; ',
        'Execute use cases, report and track defects, and promote timely and reasonable resolution of problems found in testing; ',
         'Review the user friendliness of the system and possible improvement points during the testing process from the user\'s perspective ',


      ],
      requirement: [
        'with 3 years or more experience in testing; ',
        'Solid knowledge of testing theory and database theory;',
        'Proficiency in one or more mainstream relational databases (SQL Server, Oracle, MySQL preferred);',
        'Proficiency in using Hive; 5. Familiarity with Java, Python, Shell language is preferred; ',
        'Familiar with Hadoop, Spark, hbase, kafka and other distributed open source projects;',
        'Complete project testing experience with big data related products, familiar with data extraction, conversion and loading process (ETL), basic understanding of data warehousing;',
        'Familiar with Linux operating system and common commands;',
      ],
      salary: '20~35K',
    },
  },
  {
    id: 17,
    name: 'Data Architect',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Build the data architecture of the company\'s big data platform based on business analytics needs; ',
        'Use appropriate data modeling methods to model the company\'s business data to effectively support business analysis needs; ',
        'Continuously optimize the data architecture and data model through continuous communication with business parties',
      ],
      requirement: [
        '5 years or more experience in data architecture and data modeling in internet industry;',
        'Proficiency in data warehouse architecture design, data modeling methods; ',
        'Experience or own thinking on data quality governance; ',
         'Familiar with mainstream distributed storage and computing technology systems, such as Hadoop, Spark; ',
      ],
      salary: '50-80K',
    },
  },
  {
    id: 18,
    name: 'Data Development Engineer',
    headCount: 8,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Building a big data platform based on Hadoop, Spark ecological technology;',
        'Building real-time computing platform based on Flume eco-technology;',
        'Data warehouse architecture design, data model design, and ETL development based on distributed computing storage system;',
        'Continuously optimize the big data platform to quickly fix problems and respond quickly to the data needs of the business;',

      ],
      requirement: [
        'More than three years of experience in big data development in the Internet industry;',
        'Proficiency in one of the Scala/Java/Python languages and familiarity with Linux systems; ',
        'Proficiency in one or more of Hadoop, Spark, Flink, Hbase, Impala, Druid, Kylin technology stack; ',
        'Experience in real-time computing development based on SparkStreaming, Flink is preferred; ',
        'Good experience in data modeling is preferred;',


      ],
      salary: '30-50K',
    },
  },
  {
    id: 19,
    name: 'Data Product Manager',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: '',
      requirement: '',
      salary: 'Negotiable',
    },
  },
  {
    id: 20,
    name: 'Mobile Product Manager ',
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for APP related product planning and design, complete user requirement research and analysis, business analysis, user behavior analysis, develop product development strategy as well as medium and long term version planning, develop version iteration plan; ',
        'Work closely with operations/customer service/finance/etc. departments to complete the corresponding product demand disassembly and response to ensure the continuous and stable value delivery of APP projects;',
         'Collaborate with technical team to drive the product requirements and follow up the whole process of project development, accept the products and ensure the products are released and launched on time; ',
         'Organize the team to analyze APP data regularly and use the data to drive the product feature iteration; ',
         'Follow up the APP version user feedback, continue to promote product optimization iteration.' ,

      ],
      requirement: [
        'Bachelor degree or above, major is not limited, 5+ years of experience as a product manager, 2+ years of experience in team management;',
        'Strong sense of responsibility and self-drive, strong communication skills, good at mobilizing all resources to accomplish goals and deliver value;',
        'Clear thinking, good at thinking and summarizing, able to quickly propose multi-perspective solutions;',
        'Proficient in using Office software and Axure, Visio, Xmind and other common product software; understand the collaborative workflow of industry and research, with project management skills;',
        'Strong execution on the ground, understanding the agile development process and product reverse thinking of mobile products;',
         'Independent responsible for at least one product line, through business and product analysis, to develop the overall planning of the product;',
         'Experience in large enterprise collaboration is preferred, BAT background is preferred.' ,

      ],
      salary: '40~55K',
    },
  },
  {
    id: 21,
    name: 'Data Analyst',
    headCount: 4,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' Responsible for basic data combing, building tagging system and analysis framework responsible for daily analysis demand response, completing basic analysis demand and report design and development on time and in quantity, and able to combine with business design and development theme reports;',
        'Cooperate with platform products or business lines to conduct deep analysis and mining of their user data, provide data support, and continuously optimize the analysis system; be able to independently complete business data modeling; and transform the results into operational indicators;',
        'Improve data reporting system, monitor operation status timely and accurately, and provide professional analysis reports. Pay attention to the daily operation data reports of the product line, and make corresponding optimization suggestions to the business department from the data.', 'Improve the data reporting system, monitor the operation status timely and accurately, and provide professional analysis reports.' ,

      ],
      requirement: [
        'Bachelor degree or above, 3+ years of relevant working experience as an Internet product manager;',
        'Familiar with and passionate about internet products, with keen observation and data insight on business;',
        'Understanding of common product data analysis methods such as funnel analysis, path analysis, hotspot analysis and business-oriented analysis methods such as precision marketing and cross-selling;',
        'Proficient in Excel, SQL, hadoop, Hive massive data processing, experience in building massive data data warehouse is preferred;',
        'Keen observation of numbers, like to work with numbers, rigorous and meticulous; strong analytical skills, logical reasoning ability;',
        'Strong written and verbal communication skills; Strong independent learning ability, willing to accept challenges;',
        'Responsible, team player and able to work under pressure. ',


      ],
      salary: '30~50K',
    },
  },
  {
    id: 22,
    name: 'Senior Product Experience Designer',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ', responsible for the whole chain of client product experience design, including the complete project from design experience strategy to UE/UI key design solutions output;',
        'Responsible for the overall product experience and visual enhancement / iterative optimization, propose solutions and innovative designs from the user and implement them;',
        'Work with product owners and product managers to conceptualize ideas and provide overall solutions with quality product experience;',
         'Highly sensitive to changes in product data and user satisfaction, able to flexibly measure the overall experience level of the product and make suggestions for improvement;',
        'Always pay attention to and analyze popular product design trends, study target users needs, experience demands and aesthetic tendencies, and quickly optimize existing products;',
         'Undertake certain team management and training work, focusing on methods and strategies.' ,


      ],
      requirement: [
        'Bachelor degree or above, design related professional background, 8+ years of design experience, with well-known project cases preferred;',
        'Love design, have a deep understanding of mobile internet, understand the trend of visual design, have comprehensive theoretical knowledge of visual design; bridge the communication between business team and technical team, assist technical development to meet data requirements through in-depth understanding of business requirements, form PRD related documents;',
        'Understand the theoretical knowledge of interaction design, have a deep understanding of user experience, and be able to analyze and design from an objective perspective;',
        'Strong interest in cutting-edge technology, design exploration, self-driven and also experience in driving teams; ',
        'Extreme attention to detail, the pursuit of pixel-level perfection, with human-computer interaction design and user insight; with a more mature product thinking and strong user empathy, can be deeply involved in user research, interaction design, design prototype development work; ',
         'Able to bear certain work pressure and have a strong sense of responsibility for the business you are engaged in; 7. BAT background is preferred. ',

      ],
      salary: '40~60K',
    },
  },
  {
    id: 23,
    name: 'Big Data Product Manager',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Manage and analyze business-side data requirements, plan overall data platform construction goals, and support business team data requirements;',
        'Lead the development of data products and work closely with analysts, data developers, and business parties to uncover data value and form data products;',
        'Bridge the communication between the business team and the technical team, form PRD related documents by deeply understanding the business requirements, and assist the technical development to meet the data requirements;',
        'Develop data product operation strategies, coordinate with departments and business parties to continuously improve product functionality and usage experience, and improve decision-making efficiency;',
        'Organize cross-team collaboration to ensure projects go live on time;',
        'Perform value tracking and quality verification of data through SQL or machine learning model analysis.' ,

      ],
      requirement: [
        'Bachelor degree or above, 5 years of relevant work experience;',
        'Experience in product design or analysis, able to undertake product planning work alone;',
        'Good data sensitivity and business vision, able to keenly capture data value and product opportunities;',
        'Understand the basic concepts of underlying data architecture technology and application layer data analysis and algorithms, and be able to use data as a product to solve business-side operational and decision-making problems;',
        'Strong SQL skills to do basic development and data analysis; 6. Ability to independently apply Tableau or similar analysis tools for data analysis;',
        'Experience in participating in large scale projects or system construction, experience in traffic analysis system, management decision system, business analysis system construction is preferred;',
         'Strong communication skills, teamwork spirit, proficient in project management is preferred;',
        'Experience in banking data is preferred;',
        'Experience as an algorithm engineer or data scientist is preferred;',
        'Experience in big data underlying development is preferred. ',

      ],
      salary: '30~50K',
    },
  },
  {
    id: 24,
    name: 'Sports Quiz Product Manager',
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' Responsible for the demand research, industry analysis and competitor analysis of sports product line;',
        'Responsible for the planning of the product blueprint and control of each life cycle; in the version implementation, coordinate the communication and collaboration within and outside the department to ensure that all departments have a full understanding of the PRD, and promote the R&D team to implement the product planning on schedule;',
        'Monitor the business index data of sports line products, and make corresponding tuning plans based on data analysis;',
        'Collect user feedback, interpret user demands and user behaviors, dig deeper needs, and make corresponding response planning.' ,


      ],
      requirement: [
        '5+ years of experience in internet industry, and experience in sports score, information, sports O2O or B-side betting products is preferred;',
        'Love sports competitions, familiar with lottery proxy business, and understand similar products is preferred;',
         'Concerned about NBA, soccer, tennis, e-sports and other events, and quite familiar with the rules and trends of related events;',
        'Complete experience in product lifecycle management, including market research, industry analysis, competitive product analysis, product feature design, iteration management, etc.; ',
        'Strong coordination skills, self-driven, document writing skills, strong logical thinking skills, responsibility and motivation. ',


      ],
      salary: '30~50K',
    },
  },
  {
    id: 25,
    name: 'Mobile Product Manager1',
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for the requirement management of each lifecycle of APP;',
        'Receive, organize and analyze user requirements and problem feedback, and complete product iteration planning and continuous improvement;',
        'Design product features, mechanisms, and processes into product prototypes with appropriate tools and write product PRD documents for design, development, and testing staff to clarify product requirements;',
        'Follow up the design and development progress and maintain efficient communication to ensure that the product functional features and interactions meet the requirements of the product requirements document;',
        'Write documentation and organize training to help customer service, operations, finance and other relationship departments to understand and use the product and provide effective support;',
         'Coordinate with operational objectives and programs to adjust the functional logic of the product itself and the supporting platform to maximize operational objectives.' ,


      ],
      requirement: [
        'Bachelor degree or above, major is not limited, science and technology background is good;',
        '2+ years of working experience in Internet/IT industry;',
        'Strong sense of responsibility, able to follow up product requirements and deal with online issues in a timely manner;',
         'Able to independently complete the design of the product, write product documentation (including prototypes, interaction flow, logic writing, description documents, etc.). Good at interaction design is preferred;',
        'Understand the process of product development, know how to innovate products and create competitive advantage;',
        'Clear thinking, good at communication and coordination of resources, team player and not stubborn. ',


      ],
      salary: '25~40K',
    },
  },
  {
    id: 26,
    name: 'Senior Product Manager',
    headCount: 4,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' Responsible for user requirements research, market and competitor analysis of product lines; ',
         'Digging into the needs of business departments and providing optimal solutions after efficient and comprehensive analysis; ',
        'Produce Roadmap, implement product design and PRD, and integrate internal and external resources to coordinate and promote the realization of product planning;',
         'Monitor business index data, and make corresponding tuning plan based on data analysis;',
         'Participate in the construction of business model of the product line.' ,


      ],
      requirement: [
        'Bachelor degree or above, 5 years of Internet product experience, with experience in games, sports betting, payment related experience is preferred;',
        'With Internet product thinking, and excellent product design and data analysis skills. Extremely sensitive to user experience and other details, and able to target product optimization solutions;',
        'Strong project management experience, with excellent system thinking skills, solution solving ability. Innovative spirit, strong logical thinking and decisiveness;',
        'Strong communication and coordination skills, stress tolerance and global perspective; 5. Outstanding collaboration skills and good at using tools to enhance work efficiency.' ,

      ],
      salary: '30~50K',
    },
  },
  {
    id: 27,
    name: 'Game Numerical Planning',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for the planning and implementation of the numerical system in the game;',
        'Responsible for the main architecture system and detailed design of the game planning case; complete the game numerical design, production, follow-up and test verification;',
         'Responsible for the game online effect follow-up and adjustment summary optimization, the game core and basic system, function, copywriting and other continuous tuning;',
         'Planning special gameplay and special activities with operation requirements, responsible for data results.' ,

      ],
      requirement: [
        'Bachelor degree or above, science and technology background;',
        '1 to 3 years of experience in system planning in game industry, game genre is not limited;',
         'Able to take charge of the game numerical system design and production follow-up;',
         'Have good, planning case ability, logical thinking ability, analysis ability;',
        'Familiar with domestic and international sports lottery platform is preferred.' ,


      ],
      salary: '25~40K',
    },
  },
  {
    id: 28,
    name: 'Game Numerical Plannings',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' responsible for the company\'s sports platform game framework building and system construction; ',
        'Write the game system planning case, UI/UE planning plan; ',
        'Develop the game numerical framework, grasp the platform development direction and game;',
        'Manage the game numerical planning team.' ,
      ],
      requirement: [
        'Bachelor degree or above, science and technology background, more than 5 years experience in game numerical planning, more than 3 years experience in game mastermind;',
        'Familiar with the development process and key points of mobile game products, with systematic knowledge of various games, or in-depth understanding of certain types of games; ',
        'Good at analyzing the balance system and economic system design of various games, and deeply understand the player mentality;',
        'Have a complete design concept, able to think systematically and lose complete solutions according to the overall project idea and actual situation;',
        'Proficient in game market research, project development and control as well as quality control;',
        'Strong organizational coordination and communication skills, with excellent teamwork spirit and team management skills;',
         'Experience in board games, sports and lottery industry is preferred.' ,

      ],
      salary: '40~55K',
    },
  },
  {
    id: 29,
    name: 'Frontend',
    headCount: 9,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' responsible for the company\'s Web front-end technology architecture, technology selection, development specifications; the company\'s own product web and mobile front-end page development; ',
        ' responsible for the company\'s front-end project framework and iterative optimization work; product front-end interaction, experience, data visualization and other core research and development work; ',
         'Promote Web front-end standardization and engineering; lead the team to explore technical improvements and solve complex technical problems; ',

      ],
      requirement: [
        'Build project architecture without relying on scaffolding, such as vue-cli, create-react-app cli, angular-cli, which are automatically generated from the command line;',
        'Have some understanding of the relationship between data and views in the front-end, such as the relationship between vue and vuex, react and redux, and why they are designed that way.  ',
        'Ability to think independently about the front-end automation build process;',
        'Have a relatively deep understanding of ECMA Script or JavaScript language mechanisms;',
        'Must master the technology stack: react, redux, typescript, rxjs, nest, es6/7, electron ',
        'Some understanding of node.js server-side development, such as server-side rendering for react, vue, middleware server (gateway) development;',
      ],
      salary: '30-60k',
    },
  },
  {
    id: 30,
    name: 'Android',
    headCount: 4,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Functional development iterations of the system, involving framework/kernel and other levels of improvement and user experience enhancement, including application architecture design, performance analysis and system problem location and resolution, with a good sense of innovation; complete module code writing, unit testing, code maintenance work',
        'Write technical related documentation and be proficient in system development using iterative development methods;',
        'Learn and master relevant new technologies quickly according to the needs of the project;',
         'Participate in the technical attack of the key and difficult points of the project',


      ],
      requirement: [
        'Experience in component-based architecture design ',
        'Understanding of at least 6 design patterns, having read at least one good open source project ',
        'Proficiency in developing custom controls and animations ',
         'Development experience in security, performance (rendering speed, memory optimization, power consumption, etc.) ',
         'Ability to locate lag and OOM issues ',
         'Familiar with NDK development, and have NDK development experience ',
         'Proficiency in using unit testing for quality assurance of responsible modules;',
        'Good coding habits, learning ability and ability to read English documentation;',
         'Familiar with okhttp, retrofit, and rxjava, familiar with custom controls and animation is preferred',

      ],
      salary: '30-60k',
    },
  },
  {
    id: 31,
    name: 'Mobile Technology Deputy Director',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
         'Responsible for the technical R&D management of client product line (IOS/Android/and web front-end); ',
        'Responsible for guiding members to conduct architecture analysis of mobile platform, continuous architecture upgrade, performance optimization and technical evolution; ',
         'Responsible for recruiting and training development engineers, and first-line managers to build technical echelon; ',
         'Responsible for optimizing and promoting the project process, quality assessment, personnel performance, etc.;',
        'Participate in overall project team coordination and communication and cross-team advancement of the company;',
        'Pay attention to industry technology dynamics and keep the team technology continuously updated',

      ],
      requirement: [
        'Computer related major, bachelor degree or above, 7+ years of internet product development experience;',
        'Proficient in at least one mobile platform (android/ios), with good technical breadth and global technical design skills, back-end development experience is a plus;',
        'Familiar with mainstream mobile frameworks, such as componentization, Hybrid solutions, etc., with practical experience and selection ability;',
         'Familiar with mobile performance, quality assessment and optimization methods, practical experience;',
        '3+ years of mobile management experience, familiar with the development process and related technical architecture and best practices; 30+ people 2 line team management experience is a plus;',
        'Good technical foundation and technical cognitive skills, with basic knowledge of design patterns, networking, security design, etc.;',
        'Sensitive to product experience, able to quickly understand business needs, with a sense of optimization of technology-driven experience;',
        'Good learning cognitive thinking, and communication skills;',
         'Background in first and second tier Internet companies, participation in well-known mobile products is a plus;',

      ],
      salary: '80-120k',
    },
  },
  {
    id: 32,
    name: 'Senior JAVA',
    headCount: 8,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Participate in product architecture design discussions and technical research;',
        'Build system development environment, complete system specific design, coding and unit testing',
         'Write detailed system design documents',
      ],
      requirement: [
        '3+ years of JAVA project development experience; experience with large projects is preferred;',
        'Familiar with design patterns, object-oriented analysis and design skills; rich experience in J2EE architecture design ',
        'Familiar with Spring, Mybatis, Redis, MQ and other common frameworks ',
         'Familiar with Mysql and other common database development and tuning; ',
         'Familiar with Tomcat and Linux operating system; ',
        'Proficiency in one or more of rabbitmq, redis, mongodb, dubbo, zookeeper, springboot is preferred;',
        'Familiarity with microservices architecture and use of SpringCloud toolset ',
        'Good work attitude and high motivation; strong communication skills and teamwork spirit; stress tolerance and ability to adapt to high intensity work.' ,

      ],
      salary: '30-60k',
    },
  },
  {
    id: 33,
    name: 'Senior Operations Designer',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Independently complete the creative and design of product activity operation and brand operation;',
        'Brand positioning, planning and design according to the company\'s product direction, overall control of brand tone;',
        'Proven work experience, can independently complete design work and ensure the timely advancement of the project;',
        'Able to quickly understand the user and business needs, have an understanding of operational data and precipitate design methods and experience;',
        'Good at grasping various design styles, exploring and researching dynamic design, video editing, front-end technology, etc., and combining them into design',

      ],
      requirement: [
        'More than 3 years of operational visual design experience, with the ability to independently complete operational design projects and successful cases',
        'Excellent logic, communication and innovation ability, with comprehensive and systematic thinking, strong anti-stress ',
         'Art, digital media communication and other related professional background, solid professional basic skills, excellent aesthetic level; ',
         'Excellent hand-drawing and dynamic design skills are preferred',

      ],
      salary: '15-30k',
    },
  },
  {
    id: 34,
    name: 'Senior UE Designer',
    headCount: 4,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for the front and back-end interaction design work of the product;',
        'Work with PM, UI and RD to output interaction documents quickly and with high quality;',
        'Responsible for sorting out the design specifications of custom products and improving the trial experience of products;',
        'Mentor newcomers.' ,
      ],
      requirement: [
        'Love life and have the pursuit of beautiful things,',
        '2-5 years of interaction work experience, familiar with the Internet, mobile Internet product development process;',
        'Strong communication skills, can fully understand product requirements, usage scenarios;',
        'Love to think, have strong logical thinking, learning ability and empathy;',
        'Self-driven, sense of work goals and planning;',
        'Must have a complete summary of work, not limited to projects, methodology precipitation, etc.;',
        'The above is good enough to relax the conditions, with experience in research, advertising field, B-end, tools, PC platform work a plus. Industrial design, human-computer interaction, psychology, computer and other related majors graduate preferred, large company back preferred.' ,

      ],
      salary: '18-30k',
    },
  },
  {
    id: 35,
    name: 'Brand Graphic Designer',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' Responsible for the brand vi, brand upgrade optimization, marketing activities material design of group companies;',
        'Responsible for product image development direction and sorting out visual performance landing',
      ],
      requirement: [
        ' 3-5 years of brand related design experience, majoring in art, design or related majors ',
        'Experience in word-of-mouth brand design is preferred, majoring in art, design or related majors ',
         'Good at various visual style performance, able to accurately grasp the brand tone',
      ],
      salary: '15-25k',
    },
  },
  {
    id: 36,
    name: 'Senior UI',
    headCount: 9,
    time: '2023/3/1',
    detail: {
      responsibility: [
        ' Responsible for the pre-visual derivation of the company\'s pc and app products to complete the design plan, develop design specifications and be familiar with the design process;',
        'Participate in the creative design of forward-looking products and the realization of dynamic DEMO. ',
      ],
      requirement: [
        '5+ years working experience in UI design;',
        'Experience in enterprise vi system design;',
        'Experience in internet companies is preferred: Tencent, Baidu, LeTV, Aiki, etc.',
         'Proficient in multiple design software;',
         'Proficient in web design, have certain understanding of html+css, have rich experience in interactive effects ',
         'Proficient in mobile design (H5, APP), familiar with the design specifications of various models, skilled in quickly handling interface slicing and labeling, and providing optimal adaptation solutions;',
        'Proficient in web design, have a certain understanding of html+css, and have rich experience in interaction effects',
      ],
      salary: '18-30k',
    },
  },

  {
    id: 37,
    name: 'UI Design Specialist',
    headCount: 5,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Conduct high quality visual design of business/product ui and lead visual specification development;',
        'Stand in the user\'s perspective to explore the needs and provide reasonable and systematic design solutions for product functions and features;',
        'Independently undertake projects, lead the team ui visual design thinking awareness innovation, realize the product visual presentation in the user experience and visual breakthrough;',
        'Experienced in design, participate in app, web, h5 and other interface design, with strong design driving force.' ,

      ],
      requirement: [
        'Graduated from an art school with a major in design, with a unique understanding of design and systematic learning (non-art school graduates will not be considered);',
        '5 years or more relevant work experience, leading multiple sets of mature UI design works, with rich experience in multi-platform product visual design;',
         'A keen sense of design for various Internet product trends, rich in design creativity and passion, with the spirit of exploration and research;',
        'Strong logical thinking skills, mastering the skills of business requirement analysis and product requirement decomposition;',
        'Strong learning ability, good communication and design expression ability, good teamwork attitude, proactive, down-to-earth and careful, strong coordination ability',

      ],
      salary: '50-80k',
    },
  },

  {
    id: 38,
    name: 'Deputy Creative Director',
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for controlling the visual design solution of the product; responsible for the daily management of the design team, design audit, coordinating the project related design resources, grasping the progress, and promoting the implementation of visual and interaction solutions;',
        'Track the design trends in the Internet industry, research new concepts of product design in the industry and competitors, and provide information support for long-term product planning decisions;',
         'Cooperate with marketing, operation and product teams to propose visual, creative and interactive ideas and solutions;',
         'Participate in the conception of new product features and put forward the requirements of usage habits from the user\'s perspective;',
        'Organize and optimize the workflow of design team; have certain design influence and innovative spirit',
        'Define the product design specification to ensure the consistency of product and service experience;',
        'Guide designers from junior to senior level, train and manage the growth of the team, share design experience, and promote the improvement of the team\'s design capability;',
         'Assist designers to maintain good communication and collaboration with product, operation, R&D and other departments',

      ],
      requirement: [
        'Full-time bachelor degree or above in design, design-related majors, 4 years or above management experience, and mature online product design experience, well-known Internet experience is preferred; ',
        'Have deep design theory and design skills, have in-depth understanding and practice in user experience design and related fields have excellent artistic and aesthetic skills;',
         'Understand international design style, always pay attention to the cutting-edge product design trends, able to independently lead the team to complete the whole process from design conception to design expression;',
         'Able to have a deep research and understanding of their own products and industry, competitors and other design;',
        'Excellent communication and collaboration skills, outstanding team management skills and ability to drive execution.' ,
        'Good big picture perspective and good at embracing change;',
        'Excellent communication and teamwork skills and driving force;' ,
        'Stay curious, willing to learn, passionate, and attentive to details; ',
        'Good at summarizing and designing experiences, and happy to share',
      ],
      salary: '40-60k',
    },
  },
  {
    id: 39,
    name: 'Game Interface Designer',
    headCount: 10,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Set the visual idea and style of the game interface; ',
        'Complete the extension design of the interface at all levels according to the original standard of the defined interface style;',
         'Complete the design and drawing of interface-related logos, icons and graphics;',
        'Follow up the final implementation of the design effect in the product to ensure that the design quality is consistent with the implementation quality',
        'Effectively manage interface resources and optimize interface editor and other tools and methods',


      ],
      requirement: [
        'Solid art skills, good sense of color matching and the ability to design shapes;',
        'Strong hand-drawing ability, able to independently design the pixel and icon in the fine drawing interface;',
         'Skilled in Photoshop, Illustrator and other related graphics software;',
        'Positive working attitude, good work collaboration, teamwork spirit, good communication;',
        'Familiar with the design of cocs2d and unity3d engine to restore the relevant principles;',
        'Experience in board games or successful online game works is preferred.' ,
      ],
      salary: '18-30k',
    },
  },
  {
    id: 40,
    name: 'CEO Assistant',
    headCount: 5,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '. deal with all kinds of English contract documents and interface with various local government agencies; ',
        'Follow up the project process and count the latest progress of the project to reflect the deficiencies in the project in time ',
         'Meeting minutes and other matters as explained by the leadership',
      ],
      requirement: [
        'Proficient in listening and reading English, proficient in using office software, able to assist with some business work, project follow-up.' ,
        'Experience in bc work is excluded.' ,
        'Chinese nationality, clean background, those who pass the preliminary interview, need to come to Philadelphia for in-person interview.' ,
        'Work experience abroad or study abroad experience' ,

      ],
      salary: '30-100k',
    },
  },
  {
    id: 41,
    name: 'Recruiting Deputy Directors',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Complete annual HR planning according to the company strategy, master the recruitment needs of each department and control the expenditure of manpower cost;',
        'Assist department heads in organizational structure design and position setting, assist in sorting out and improving the company\'s job descriptions;',
        'Create competency model and talent assessment model for each position in the company; provide scientific decision basis for recruitment and promotion of the company through person-job matching system.' ,
        'Guide and supervise the development of recruitment programs and specific activities of each subsidiary to ensure the successful completion of recruitment tasks;',
         'Assist in coordinating the whole process of recruitment preparation, implementation (recruitment, selection, hiring), evaluation and allocation.' ,


      ],
      requirement: [
        'College degree or above, graduated from human resource management, business administration and other related majors;',
        'More than 5 years of human resources work experience, more than 3 years of recruitment management experience, with experience in recruitment management in Internet and e-commerce industry is preferred;',
         'Proficient in recruitment module, skilled in applying various recruitment tools and interview methods;',
        'Good communication skills, affinity, coordination ability and insight;',


      ],
      salary: '20-50k',
    },
  },
  {
    id: 42,
    name: 'Test',
    headCount: 7,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Write test plans and test cases according to product specifications and testing requirements, (APP experience is preferred);',
        'Familiar with mainstream testing tools, including packet capture tools, automation testing tools, performance testing, exception testing tools, etc., (Linux environment testing experience is preferred);',
        'Familiar with mainstream test case management tools and defect management tools;',
        'Good command of testing concepts, testing methods, good business understanding, communication and communication skills, able to describe bugs and track bugs clearly and accurately;',
        'Familiar with TCP/IP protocol, HTTP protocol;',
        'Good coordination skills, as well as a good team spirit and cooperation;',
        'Familiar with Linux system, MySQL database experience is preferred;',
        'More than three years of testing experience, working experience in well-known APP companies is preferred; 9'
      ],
      requirement: [
        'Write test plans and test cases according to product specifications and testing requirements, (APP experience is preferred);',
        'Familiar with mainstream testing tools, including packet capture tools, automation testing tools, performance testing, exception testing tools, etc., (Linux environment testing experience is preferred);',
        'Familiar with mainstream test case management tools and defect management tools;',
        'Good command of testing concepts, testing methods, good business understanding, communication and communication skills, able to describe bugs and track bugs clearly and accurately;',
        'Familiar with TCP/IP protocol, HTTP protocol;',
        'Good coordination skills, as well as a good team spirit and cooperation;',
        'Familiar with Linux system, MySQL database experience is preferred;',
         'More than three years of experience in testing, experience in well-known APP companies is preferred; ',
         'Conscientious, responsible, enthusiastic and able to work hard',
      ],
      salary: '20-35k',
    },
  },
  {
    id: 43,
    name: 'Android Development',
    headCount: 3,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Functional development iterations of the system, involving framework/kernel and other levels of improvement and user experience enhancement, including application architecture design, performance analysis and system problem location and resolution, with a good sense of innovation; complete module code writing, unit testing, code maintenance work ',
         'Write technical related documentation and be skilled in using iterative development methods for system development;',
        'Learn and master relevant new technologies quickly according to the needs of the project;',
         'Participate in the technical attack of key and difficult points of the project',
      ],
      requirement: [
        'Experience in component-based architecture design ',
        'Understanding of at least 6 design patterns, having read at least one good open source project ',
         'Proficiency in developing custom controls and animations ',
        'Development experience in security, performance (rendering speed, memory optimization, power consumption, etc.) ',
         'Ability to locate lag and OOM issues ',
        'Familiar with NDK development, and have NDK development experience ',
         'Proficiency in using unit testing for quality assurance of responsible modules; ',
         'Good coding habits, learning ability and ability to read English documentation; ',
         'Familiar with okhttp, retrofit, and rxjava, and then familiar with custom controls and animation is preferred',
      ],
      salary: '20-40K',
    },
  },

  {
    id: 44,
    name: 'DBA',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [],
      requirement: [
        'More than ten years of experience in database architecture and design, with some development foundation and rich domain knowledge.' ,
        'Proficient in relational database model and also have knowledge of non-relational database model.' ,
        'Proficient in a variety of mainstream databases such as Oracle, Mysql, MongoDB, etc., and familiar with other NoSQL and NewSQL databases such as Redis, Cassandra, TIDB, etc.' ,
        'Familiar with the advantages and disadvantages of different databases and platforms, and able to make scientific decisions.' ,
        'Familiar with operating systems (Linux), storage, network technologies, and knowledge of virtualization, cloud, etc.' ,
        'Familiar with mainstream software architecture, understand multiple development languages and master at least one as a development language, with certain development ability' ,
        'Have a keen technical foresight.' ,
        'Good communication and coordination skills, able to work under pressure.' ,
        'Strong analytical judgment and solution ability for complex problems.' ,
        'Strong learning ability for new technologies.' ,
        'Complete program writing skills.' ,
      ],
      salary: '30-60K',
    },
  },
  {
    id: 45,
    name: 'IOS Development Engineer',
    headCount: 1,
    time: '2023/3/1',
    detail: {
      responsibility: [],
      requirement: [
        'Minimum 3 years of experience in iOS development;',
        'Solid foundation in Objective-C language and proficiency in Swift;',
        'Familiar with common data structures and design patterns;',
        'In-depth understanding of system frameworks such as UIKit, Core Animation and Core Graphics;',
        'In-depth understanding of Objective-C Runtime and memory management mechanisms;',
        'Some performance tuning experience, skilled in using performance tuning tools such as Instruments;',
        'Familiar with network communication mechanisms and common data transfer protocols, with some experience in mobile network optimization;',
        'Good learning ability and keen sense of new technologies;',
        'Ability to analyze and solve problems independently, and be able to undertake work tasks independently',


      ],
      salary: '30-50K',
    },
  },

  {
    id: 46,
    name: 'Project manager',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for the organization and management of the company\'s product R&D project development, including the organization and management of project initiation, execution, monitoring, change, acceptance, etc.;',
        'Communicate project requirements, evaluate the reasonableness of the requirements from a technical perspective, assess project overhead (manpower, cycle time, risk, budget, etc.) and feasibility;',
        'In the system development phase, guide the senior developer to output the outline design and the first draft of the implementation plan to ensure that the implementation meets the business requirements;',
         'According to the project schedule, participate in the preparation of relevant documents, such as outline design documents, requirements change documents, training plans, go-live release plans, etc.; ',
         'Familiar with the system process, participate in organizing test cases, organize product acceptance testing, and record system problems found during testing and suggestions for requirements optimization;',
        'Answer business system operation problems, and provide timely tracking and feedback on system issues, software change requirements, etc.; ',
        'Responsible for undertaking the process control, cost control, schedule control, quality control, etc. of product development projects to ensure the completion of projects on time and with quality.' ,
         'Responsible for the communication and coordination of related teams (product, development, testing, operation and maintenance, etc.); ',
        'Participate in the establishment of the company\'s software technology framework and control the quality of the team\'s code.' ,

      ],
      requirement: [
         'Full-time undergraduate degree or above, majoring in computer related field, more than three years working experience, with team management experience or long term working on the same product development. ',
        '5 years of experience in software project implementation, have more in-depth personal understanding of information technology processes, able to write solutions, project managers are preferred; ',
       'Good learning ability, quick thinking, good communication, good written and verbal skills, strong organizational and coordination skills; ',
         'Familiar with JAVA, pathon and any other development technology, familiar with MYSQL, Oracle database technology. Experience in one language is required (testing is also acceptable), age cannot be less than 27, preferably male ',
         'pmp certificate preferred',
      ],
      salary: '20-35k',
    },
  },
  {
    id: 47,
    name: 'Financial Director',
    headCount: 2,
    time: '2023/3/1',
    detail: {
      responsibility: [
        'Responsible for developing annual financial planning to ensure proper operation of the business. ',
         'Responsible for preparing annual, quarterly and monthly financial pre-settlement statements and submitting them to relevant leaders. ',
        'Responsible for establishing a sound financial management system and effective financial risk control.' ,
         'Responsible for asset coordination and management, saving expenses and controlling costs.' ,
        'Real-time monitoring of financial accounts and flow, financial statement analysis and recommendations. ',
        'Daily financial accounting and liquidation work. ',
        'Other work assigned by the leader.' ,
      ],
      requirement: [
        'Full-time undergraduate degree or above from 211 or 985 schools;',
        'Must have CPA qualification, and other practice qualification (such as ACCA, AICPA, etc.) is preferred;',
        'More than ten years of experience in corporate financial management and more than three years of experience in overall financial management; ',
         'Proficient in audit legal norms; ',
        'Integrity, flexibility, strong sense of responsibility, meticulous and rigorous work, and good professional ethics; ',
         'Strong interpersonal communication and coordination skills, good discipline ',
      ],
      salary: '40-50K',
    },
  },
  // {
  //   id: 48,
  //   name: 'Bank Director',
  //   headCount: 1,
  //   time: '2023/3/1',
  //   detail: {
  //     responsibility: '',
  //     requirement: '',
  //     salary: '40-50K',
  //   },
  // },
  {
    id: 49,
    name: 'BusinessPaymentDirector',
    headCount: 5,
    time: '2023/3/1',
    detail: {
      responsibility: [
        '. formulate medium and long-term development plans of the company according to the development strategy of the Group and be fully responsible for the daily operation and management of third party payment; ',
        '. establish the company\'s business development objectives and plans and organize their implementation to achieve rapid growth of the company\'s business and investment returns; ',
        'Improve and perfect the company\'s risk management system, promote the improvement of the company\'s risk management level, strengthen the dynamic monitoring of various businesses, optimize the monitoring process, and ensure that the company\'s business is conducted under controlled and predictable risks;',
         'Responsible for the management of the third party payment team, pushing forward to ensure that the project is progressing normally and on time, and continuously improving the security system and customer experience;',

      ],
      requirement: [
        'Bachelor degree or above in financial industry with more than 3 years of experience in the same position;',
        'Experience in operating in third party payment type industry;',
        'Experience in team management and familiar with the construction and implementation of team incentive system;',
        'Proficient in bank audit legal norms;',
         'Integrity, flexibility, strong sense of responsibility, meticulous and rigorous work, and good professional ethics;',
        'Strong interpersonal communication and coordination skills, good discipline',

      ],
      salary: '40-50K',
    },
  },
];

export default JobPostsArr;

