import './style.scss';

import i18n from 'config/i18n';
const changeLanguageHandler = () =>
{
   // console.log(i18n);

 if(i18n.language!=='zh'){

   i18n.changeLanguage("zh")
   // console.log("should be zh",i18n.language)
}
 else{
   // console.log("work in")
   i18n.changeLanguage("en")
   // console.log("should be en",i18n.language)
 }

}

const ToggleSwitch = () => {

   return (
      <div className="switch" >
         <input 
            id="language-toggle" 
            className="check-toggle check-toggle-round-flat" 
            type="checkbox"
            onClick={changeLanguageHandler}
         />
         <label htmlFor="language-toggle"></label>
         <span className="on">中</span>
         <span className="off">EN  </span>
      
      </div>
   )
}
export default ToggleSwitch;  