import Header from '../components/web/header/Header';
import Home from '../components/web/home';
import About from '../components/web/about';
import Bonus from '../components/web/bonus';
import Opportunities from '../components/web/opportunities';
import Contact from '../components/web/contact';
import Footer from '../components/web/footer/Footer';
import Faq from '../components//web/faq';
import { Translation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { useSideNavigation } from 'utils/hooks/useSideNavigation';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/pagination';
const sections: JSX.Element[] = [
  <Translation>{t => <>{t('longsagePage')}</>}</Translation>,
  <Translation>{t => <>{t('companyProfile')}</>}</Translation>,
  <Translation>{t => <>{t('benefitsButton')}</>}</Translation>,
  <Translation>{t => <>{t('opportunities')}</>}</Translation>,
  <Translation>{t => <>{t('contactUs')}</>}</Translation>,
  <Translation>{t => <>{t('faqs')}</>}</Translation>,
];

const WebPages = () => {
  const { toggle, SideNavigation, swiperProperties, slideTo } = useSideNavigation(sections)
   return (
    <>
      <Header />
            <Swiper {...swiperProperties} >
                <SwiperSlide> <Home slideTo={slideTo}/> </SwiperSlide>
                <SwiperSlide> <About toggleMainSwiper={toggle} /> </SwiperSlide>
                <SwiperSlide> <Bonus/> </SwiperSlide>
                <SwiperSlide> <Opportunities/> </SwiperSlide>
                <SwiperSlide> <Contact/> </SwiperSlide>
                <SwiperSlide> <Faq/> </SwiperSlide>
                <SideNavigation/>
            </Swiper>
      <Footer />
    </>
   )
}

export default WebPages;