import React, { FC, useState } from 'react';
import './style.scss';
import { WEBCONTACTINFO } from 'assets/web/config';
import i18next from 'i18next';
import ContactLeftImage from '@assets/web/contact/contact-common_bg_image.webp';

interface Props {}

const Contact: FC<Props> = () => {
    const [changeLanguage,setChangelanguage]=useState(false);
    
    i18next.on('languageChanged', function(lng) {
        setChangelanguage(!changeLanguage);
    })
    return (
        <div className={'contactWrapper'}>
            <div className={'contactCont'}>
                <div className={'contactLeft'}>
                    <div className={'titleCont'}>
                        {changeLanguage ? 
                            (
                                <img className={'contactTitleEN'} src={require('../../../assets/web/contact/contact_us_title_EN@2x.webp')} />
                            ) : (
                            <>
                                <img className={'contactTitle'} src={require('../../../assets/web/contact/contact_us_title_Chinese@2x.webp')} />
                                <div className={'contactSubtitle'}>
                                    contact us
                                </div>
                            </>
                            )
                        }
                    </div>
                    <img src={ContactLeftImage} className={'contactLeftImage'}></img>
                </div>
                <div className={'contactRight'}>
                <img className='contactBgItem1' src={require('@assets/web/contact/contact_us_img2@2x.webp')}/>
                    <div className={'contactSubCont'}>
                        
                    <img className='contactBgItem2' src={require('@assets/web/contact/contact_us_img@2x.webp')} />
    
                        <div className={'itemCont'}>
                            {WEBCONTACTINFO.map((item) => {
                                return  <div className={'contactItem'}>
                                    <img src={item.img.src} width={item.img.width} height={item.img.height}/>
                                    <p>{item.info}</p>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;