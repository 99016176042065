import React, { useEffect, ReactNode, useState } from "react";
import "./style.scss";
//import JobPostsArr from './data';
//import Modal from './Modal';
//import { JOBLIST,ENJOBLIST } from './data';
import { JOBLIST, ENJOBLIST } from "components/web/opportunities/data";
import i18n from "../../../config/i18n";
// import { Translation } from 'react-i18next';
import i18next from "i18next";
import { Translation } from "react-i18next";

import oppTitleChinese from "../../../assets/mobile/opportunities/title_Chinese.webp";
import oppTitleEn from "../../../assets/mobile/opportunities/opportunities_title_EN.webp";
import emptyImg from "../../../assets/mobile/opportunities/emptyoppImg.webp";
import MobileModal from "./MobileModal";
import { ReactComponent as ReactLogo } from "../../../assets/web/job/opportunities_search_ic.svg";
import { ReactComponent as SvgClose } from "../../../assets/mobile/opportunities/Vector.svg";
import { CategoryDetails } from "apps/declarations";
// import { Pagination } from "swiper";

const positionCategories: ReactNode[] = [
  <Translation>{(t) => <>{t("all")}</>}</Translation>,
  <Translation>{(t) => <>{t("design")}</>}</Translation>,
  <Translation>{(t) => <>{t("development")}</>}</Translation>,
  <Translation>{(t) => <>{t("operations")}</>}</Translation>,
  <Translation>{(t) => <>{t("testing")}</>}</Translation>,
  <Translation>{(t) => <>{t("product")}</>}</Translation>,
];

const Opportunities = () => {
  // const [searchInput, setSearchInput] = useState("");
  const [currentCategory, setCurrentCategory] = useState<number>(1);
  const [dataArr, setDataArr]: any[] = useState(ENJOBLIST);
  const [newDataArr, setNewDataArr]: any[] = useState(ENJOBLIST);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [isShowButton, setIsShowButton] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [changeLanguage, setChangelanguage] = useState(false);
  const [isCloseButton, setIsCloseButton] = useState(false);
  const [pagination, setPagination] = useState({
    totalPages: 0,
    page: 1,
  });
  const [showRecommended, setShowRecommended] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [visible, setVisible] = useState(4);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = newDataArr.slice(indexOfFirstPost, indexOfLastPost);

  // const paginate   = (pageNumber:any)  => setCurrentPage(pageNumber);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  i18next.on("languageChanged", function (lng) {
    setChangelanguage(!changeLanguage);
    // console.log(lng);
    if (lng === "zh") {
      setNewDataArr(JOBLIST);
      setDataArr(JOBLIST);
    }
    if (lng === "en") {
      setNewDataArr(ENJOBLIST);
      setDataArr(ENJOBLIST);
    }
  });

  const showMoreItems = () => {
    let temp = visible;

    setPagination((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));

    // console.log(temp);
    setVisible(temp + 4);
  };
  useEffect(() => {
    // console.log("aaaaa",i18n.language);
    // console.log("translation",t);

    if (i18n.language === "en") {
      setNewDataArr(ENJOBLIST);
      setDataArr(ENJOBLIST);
    }
    if (i18n.language === "zh") {
      setNewDataArr(JOBLIST);
      setDataArr(JOBLIST);
    }
  }, []);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      totalPages: Math.ceil(newDataArr.length / 4),
    }));

    // console.log("rustin", { pagination });
  }, [newDataArr]);

  i18next.on("languageChanged", function (lng) {
    setChangelanguage(!changeLanguage);
    // console.log(lng);
    if (lng === "zh") {
      setNewDataArr(JOBLIST);
      setDataArr(JOBLIST);
    }
    if (lng === "en") {
      setNewDataArr(ENJOBLIST);
      setDataArr(ENJOBLIST);
    }
  });

  const categories = [
    { id: 1, name: "全部", value: "all" },
    { id: 2, name: "设计", value: "design" },
    { id: 3, name: "开发", value: "develop" },
    { id: 4, name: "运营", value: "operate" },
    { id: 5, name: "测试", value: "test" },
    { id: 6, name: "产品", value: "product" },
    { id: 7, name: "其他", value: "other" },
  ];

  //   useEffect(() => {

  // if(searchInput === ""){
  //     setShowRecommended(false)
  // }
  //     }, [searchInput])

  const searchByCategory = (searchCategory: string) => {
    if (searchCategory === "all") {
      setNewDataArr(dataArr);

       setVisible(4);
       setPagination({
        page: 1,
        totalPages:0
      });
      } else {
      const filteredData = dataArr.filter((item: any) => {
        return item.category === searchCategory;
      });

      // console.log(filteredData)
      //            setDataArr(filteredData)
      setNewDataArr(filteredData);
    setVisible(4);
    
    setPagination({
      page: 1,
      totalPages:0
    });

    }
  };

  const searchItems = (searchValue: string) => {
    // console.log({searchValue})
    //   setSearchInput(searchValue)
    //   const filteredData =  dataArr.filter((item:any) => {
    //     return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
    // })

    // setDataArr(filteredData)
    setSearchInput(searchValue);
    if (searchValue !== "") {
      setIsCloseButton(true);
      const filteredData = dataArr.filter((item: any) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      // console.log(filteredData)
      //            setDataArr(filteredData)
      setNewDataArr(filteredData);
    } else {
      searchValue = "";
      setNewDataArr(dataArr);
    }
  };
  const resetItems = () => {
    // searchItems("")
    setSearchInput("");
    setIsCloseButton(false);
    searchItems("");
    setShowRecommended(false);
    setVisible(4);
    // setPagination({
    //   totalPages:0,
    //   page: 1
    // });
  };
  const Category = (details: any, index: number) => {
    // const{item,index}=deta;
    // console.log({details,index})
    return (
      <div
        className={
          currentCategory === details.details.id ? `categoryActive` : `category`
        }
        onClick={() => {
          //  resetItems();
          searchByCategory(details.details.value);
          setCurrentCategory(details.details.id);
        }}
      >
        {details.details.name}
      </div>
    );
  };
  //       }
  return (
    <div className="oppMbContainer">
      <div className="oppMbHeader">
        <div className="oppMbTitleText">
          {!changeLanguage ? (
            <img
              src={oppTitleChinese}
              alt="chinese title"
              className="oppMbTitle"
            />
          ) : (
            <img src={oppTitleEn} alt="en title" className="oppMbTitle" />
          )}
        </div>

        {!changeLanguage ? (
          <div className="titleMbExtra"> Opportunities</div>
        ) : null}
        <div className="titleMbText">
          <Translation>{(t) => <>{t("billion")}</>}</Translation>
        </div>
      </div>
      <div className="oppMbSearchContainer">
        <button type="submit" className="searchButtonMb">
          {/* <img className='oppMbSearchIcon' src={require('../../../assets/web/job/opportunities_search.webp')} /> */}
          <ReactLogo />
        </button>

        {/* <Translation>{t => }</Translation> */}

        <>
          {isCloseButton ? (
            <div className="oppMbcloseBtn" onClick={resetItems}>
              <SvgClose />
            </div>
          ) : null}
          {changeLanguage ? (
            <input
              onFocus={() => {
                setShowRecommended(true);
              }}
              // onBlur={()=>setShowRecommended(false)}
              type="text"
              value={searchInput}
              onChange={(e) => searchItems(e.target.value)}
              className="searchTermMb"
              placeholder="What are you looking for?"
            />
          ) : (
            <input
              onFocus={() => {
                setShowRecommended(true);
              }}
              // onBlur={()=>setShowRecommended(false)}
              value={searchInput}
              onChange={(e) => searchItems(e.target.value)}
              className="searchTermMb"
              placeholder="输入搜索职位"
            />
          )}
        </>
      </div>
      <div className={"categories"}>
        {!changeLanguage ? (
          <>
            {categories.map((item, index) => (
              <Category details={item} index={index} key={item.id} />
            ))}
          </>
        ) : null}
      </div>
      {showRecommended ? (
        <div className="recomSection">
          <div className="recomTitle">
            {" "}
            <Translation>
              {(t) => <>{t("recommendedSearch")}</>}
            </Translation>{" "}
          </div>
          <div className="recomItemContainer">
            <div
              className="recomItem"
              onClick={() => {
                !changeLanguage
                  ? searchItems("高级JAVA")
                  : searchItems("Senior JAVA");
              }}
            >
              <Translation>{(t) => <>{t("SeniorJAVA")}</>}</Translation>
            </div>

            <div
              className="recomItem"
              onClick={() => {
                !changeLanguage
                  ? searchItems("高级UI")
                  : searchItems("Senior UI");
              }}
            >
              <Translation>{(t) => <>{t("SeniorUI")}</>}</Translation>
            </div>
            <div
              className="recomItem"
              onClick={() => {
                !changeLanguage
                  ? searchItems("高级UE设计师")
                  : searchItems("Senior UE Designer");
              }}
            >
              <Translation>{(t) => <>{t("SeniorUEDesigner")}</>}</Translation>
            </div>
            <div
              className="recomItem"
              onClick={() => {
                !changeLanguage
                  ? searchItems("品牌平面设计师")
                  : searchItems("Brand Graphic Designer");
              }}
            >
              <Translation>
                {(t) => <>{t("BrandGraphicDesigner")}</>}
              </Translation>
            </div>
          </div>
        </div>
      ) : null}
      <div className="oppMbCardContainer">
        {newDataArr.length ? (
          newDataArr.slice(0, visible).map((post: any) => (
            <div
              className="oppMbCard"
              onClick={() => {
                setModalData(post);
                setModalIsOpenToTrue();
              }}
              key={post.id}
            >
              <div className="oppMbCardHeader">
                <div className="oppMbCardName">{post.name}</div>

                <div className="cardMbPostTime"> {post.time}</div>
              </div>

              <div className="oppMbCardFooter">
                <div className="oppMbCardSalary">{post.detail.salary}</div>
                <div className="oppMbCardHeadCount">
                  <Translation>{(t) => <>{t("recruitment")}</>}</Translation>{" "}
                  <span> {post.headCount}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="oppEmptyImgContainer">
            <img src={emptyImg} className="emptyImg" />

            <div className="emptyOppText">
              <Translation>{(t) => <>{t("emptyOppText")}</>}</Translation>{" "}
            </div>
          </div>
        )}
        <MobileModal
          modalIsOpen={modalIsOpen}
          handleModal={setModalIsOpenToFalse}
          modalData={modalData}
        />
        <div className="oppBtnContainer">
          {pagination.page < pagination.totalPages ? (
            <button onClick={showMoreItems} className="oppMoreBtn">
              <Translation>{(t) => <>{t("more")}</>}</Translation>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Opportunities;
