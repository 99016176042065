import React, { FC, useState } from 'react';
import './style.scss';
import { CONTACTINFO } from 'assets/web/config';
import i18next from 'i18next';

interface Props {}

const Contact: FC<Props> = () => {
    const [changeLanguage,setChangelanguage]=useState(false);
    
    i18next.on('languageChanged', function(lng) {
        setChangelanguage(!changeLanguage);
    })
    return (
        <div className={'contactWrapperMobile'}>
            <div className={'contactCont'}>
                <div className={'titleCont'}>
                    {changeLanguage ? 
                        (
                            <img className={'contactTitleEN'} src={require('../../../assets/mobile/contact/contact us_title_EN@2x.webp')} />
                        ) : (
                        <>
                            <img className={'contactTitle'} src={require('../../../assets/web/contact/contact_us_title_Chinese@2x.webp')} />
                            <div className={'contactSubtitle'}>
                                contact us
                            </div>
                        </>
                        )
                    }
                </div>
                <div className={'contactSubCont'}>
                {/* <img className='contactBgItem2' src={require('@assets/web/contact/contact_us_img@2x.webp')} /> */}
                    <div className={'itemCont'}>

                        {/* TELEGRAM */}
                        <div className={'contactItem'}>
                            <p>{CONTACTINFO.telegram}</p>
                        </div>
                        {/* MAIL */}
                        <div className={'contactItem'}>
                            <p>{CONTACTINFO.email}</p>
                        </div>
                        {/* COMPANY */}
                        <div className={'contactItem'}>
                            <p>{CONTACTINFO.linked}</p>
                        </div>
                        {/* SKYPE */}
                        <div className={'contactItem'}>
                            <p>{CONTACTINFO.skype}</p>
                        </div>
                            
                    </div>
                </div>
            </div>
            <img src={require('../../../assets/mobile/contact/contact us_bg_img@2x.webp')} alt="" className='contactBackgroundImage'/>
        </div>
    );
}

export default Contact;