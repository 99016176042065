import './style.scss';
import { Translation } from 'react-i18next';

const Footer = () => {
   return (
      <footer className="footer">
         <Translation>{t => <p>{t('companyYear')}</p>}</Translation>
         <Translation>{t => <p>{t('ContactHr')}</p>}</Translation>
      </footer>
   )
}

export default Footer;