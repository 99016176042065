import ToggleSwitch from '../utils/ToggleSwitch';
import {isSafariBrowser} from '../../../utils/helpers';
import './style.scss';

const Header = () => {

   return (
      <header className="header">
         <div className= {  isSafariBrowser ? 'headerContainerSafari' : 'headerContainer'}
         >
            <img src={require('../../../assets/web/header/logo@2x.webp')} alt="logo of the company" className='headerLogo' />
            <ToggleSwitch />
         </div>
      </header>
   )
}

export default Header;