import React, { useEffect, useState } from "react";
import "./style.scss";
import { Translation } from "react-i18next";
import { ReactComponent as SvgBack } from "../../../assets/mobile/opportunities/svgBackBtn.svg";
import { ReactComponent as SvgClock } from "../../../assets/mobile/opportunities/clock.svg";
const MobileModal = ({ modalIsOpen, handleModal, modalData }: any) => {
  const rendDetailRes = () => {
    const data = modalData.detail.responsibility;

    if (!data) return null;
    return (
      <>
        <div className="modalMbContentTitle">
          <Translation>{(t) => <>{t("Jobresponsibilities")}</>}</Translation>
        </div>
        <div className="modalMbListContainer">
          <ul className="modalMbList">
            {data.map((v: any, i: any) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  const rendDetailReq = () => {
    const data = modalData.detail.requirement;
    // console.log("dataaaaaaaa",data)

    if (!data) return null;
    return (
      <>
        <div className="modalMbContentTitle">
          <Translation>{(t) => <>{t("jobrequirements")}</>}</Translation>
        </div>

        <div>
          <ul className="modalMbList">
            {data.map((v: any, i: any) => {
              return <li key={i}>{v}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      {modalIsOpen ? (
        <div className="modalMbContainer">
          <div className="modalMbHead">
            <div className="modalMbHeadTitle">
              <div onClick={handleModal} className="modalMbCloseBtn">
                <SvgBack />
              </div>{" "}
              {/*<Translation>{(t) => <>{t("jobDetails")}</>}</Translation>*/}
            </div>
          </div>

          <div className="modalMbTopContent">
            <div className="modalMbTopContentItem1">
              <div className="modalMbTopContentName">{modalData.name}</div>

              <div className="modalMbTopContentCount">
                <Translation>{(t) => <> {t("recruitment")}</>}</Translation> {" "}
              <span>{modalData.headCount} </span>  {" "}
              </div>
            </div>
            <div className="modalMbTopContentItem2">
              <div className="modalMbTopContentSalary">
                {modalData.detail.salary}
              </div>
              <div className="modalMbTopContentTime">
              <SvgClock/> 
                <div className="modalMbTimeSec">
          {modalData.time}
                </div>
  
              </div>
            </div>
          </div>

          <div className="modalMbContent">
            <div className="modalMbResponse"></div>

            <div className="modalMbRequirement">
             
            {rendDetailRes()}
              {rendDetailReq()}
              {/* <div className='modalContentTitle'>Job Requirements</div>
  <div>{modalData.detail.requirement}</div> */}
           

            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MobileModal;
