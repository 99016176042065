export const resources = {
  en: {
    translation: {
      longsagePage: 'LONGSAGE PAGE',
      companyProfile: 'COMPANY PROFILE',
      benefitsButton: 'BENEFITS',
      opportunities: 'OPPORTUNITIES',
      contactUs: 'CONTACT US',
      faqs: 'FAQs',
      job: 'JOB OPPORTUNITIES',
      AboutUs: 'ABOUT US',
      ContactUs: 'CONTACT US',
      companyUppercase: 'COMPANY PROFILE',
      salaryUppercase: 'SALARY AND BENEFITS',
      ContactUsUppercase: 'CONTACT US',
      About: 'ABOUT INNOVATIONS',
      JoinUs: 'JOIN US',
      wonder: 'Innovations',
      companyIntroduction: 'Company Introduction',
      introduction: 'Company Introduction',
      salary: 'SALARY AND BENEFITS',
      jobUppercase: 'JOB OPPORTUNITIES',
      companyDetail: `Founded in 2016, Longsage Group currently employs over 2,000 people in Southeast Asia and some parts of the world. Our Company offers high-quality software development, data analysis, gaming, entertainment, and artificial intelligence technology services. Longsage Group is comprised of a large number of highly-skilled and technology advanced software developers. Our COMPANY VALUES adhere to INTEGRITY, INNOVATION, EFFICIENCY, and PEOPLE-ORIENTED. Our MISSION is to use advanced development tools and platforms that can develop high-quality software products to address our customer needs. Our COMMITMENT is to continuously invest in human resources to enrich technical knowledge, exceeds service level agreement, and achieve a winning situation for the Company, Employees and our Customers.`,
      Strategy: 'Strategy',
      StrategyDetail: `Based in the Asian market and opening up innovative development for international diversification; focusing on customers, creating high-quality products and services for them.`,
      Vision: 'Vision',
      VisionDetail: `Using the latest technology, we aim to spread entertainment everywhere`,
      Mission: 'Mission',
      MissionDetail:
          'Adapting to the needs of the times, continuously innovating, and striving to become the industry leader.',
      salaryTitle: 'SALARY, COMPENSATION AND BENEFITS IN INNOVATIONS GROUP',
      salaryDetail:
          'We offer competitive compensation and benefits package for all our employees, and provide regular work visa for our expat employees.',
      Salary1: 'SALARY',
      Salary_1: 'Guaranteed 13th month pay',
      Salary_2: 'Overtime pay',
      Salary_3: '',
      Salary_4: '',
      food: 'F&B',
      food_1: '',
      food_2: '',
      food_3: '',
      benefits: 'ACCOMMODATION',
      benefits_1: 'Accommodation / Accommodation allowance for Expat employees',
      benefits_2: '',
      benefits_3: '',
      meals: 'MEALS',
      meals_1: 'You could choose from Chinese, Filipino or Healthy meals',
      Transportation: 'TRANSPORTATION',
      Transportation_1: 'Office Shuttle Service',
      LeaveEntitlement: 'LEAVES',
      LeaveEntitlement_1: 'Annual Leave',
      LeaveEntitlement_2: 'Philippine Holidays for Local Employees',
      LeaveEntitlement_3: 'Chinese Holidays for Expat Employees',
      LeaveEntitlement_4: 'Government Mandated Leaves',
      LeaveEntitlement_5: 'Parental Leave for Solo Parents',
      LeaveEntitlement_6: 'Bereavement Leave',
      LeaveEntitlement_7: 'Special Leave for Women',
      LeaveEntitlement_8: 'Leave for Victims of Violence against Women and their Children',
      TeamBuilding: 'EMPLOYEE ENGAGEMENT ACTIVITIES',
      TeamBuilding_1: 'Lots of fun and teambuilding activities',
      TeamBuilding_2: 'Training and Development Programs',
      JobOpportunities: 'JOB OPPORTUNITIES',
      Opportunities:"Opportunities",
      JobSearch: 'Job Search',
      jobtitle: 'Kindly enter the job title',
      jobName: 'JOB TITLE',
      recruits: 'NUMBER OF RECRUITS',
      salaryrange: 'SALARY RANGE',
      releasetime: 'RELEASE TIME',
      operation: 'JOB DETAILS',
      Details: 'Job Details',
      Jobresponsibilities: 'Job responsibilities',
      jobrequirements: 'Job requirements',
      Remuneration: 'Remuneration',
      php: 'PHP Development Engineer',
      Frontend: 'Front-end',
      TestManager: 'Test Manager',
      Test: 'Test',
      SeniorJAVA: 'Senior JAVA',
      DefensiveResearch: 'Penetration Testing and Defensive Research',
      PHPtoPaypal: 'PHP to Paypal',
      OperationsDevelopment: 'Operations Development',
      SeniorOperations: 'Senior Operations and Maintenance Engineer',
      bgSeniorOperations: 'Big Data Senior Operations and Maintenance Engineer',
      TechnologySpecialist: 'Big Data Technology Specialist',
      TestEngineer: 'Big Data Test Engineer',
      DataArchitect: 'Data Architect',
      DataDevelopmentEngineer: 'Data Development Engineer',
      DataProductManager: 'Data Product Manager',
      MobileProductManager1: 'Mobile Product Manager (Intermediate level)',
      MobileProductManager: 'Mobile Product Manager (Senior)',
      DataAnalyst: 'Data Analyst',
      SeniorProductExperienceDesigner: 'Senior Product Experience Designer',
      BigDataProductManager: 'Big Data Product Manager',
      SportsQuizProducManager: 'Sports Quiz Product Manager',
      SeniorProductManager: 'Senior Product Manager',
      GameNumericalPlanning: 'Game Numerical Planning (Intermediate level)',
      GameNumericalPlannings: 'Game Numerical Planning (Senior)',
      Android: 'Android',
      MobileTechnologyDeputyDirector: 'Mobile Technology Deputy Director',
      SeniorOperationsDesigner: 'Senior Operations Designer',
      SeniorUEDesigner: 'Senior UE Designer',
      BrandGraphicDesigner: 'Brand Graphic Designer',
      SeniorUI: 'Senior UI',
      UIDesignSpecialist: 'UI Design Specialist',
      DeputyCreativeDirector: 'Deputy Creative Director',
      GameInterfaceDesigner: 'Game Interface Designer',
      CEOAssistant: 'CEO Assistant',
      RecruitingDeputyDirectors: 'Recruiting Deputy Directors',
      AndroidDevelopment: 'Android Development',
      IOSDevelopmentEngineer: 'IOS Development Engineer',
      Projectmanager: ' Project manager',
      FinancialDirector: 'Financial Director',
      BankDirector: 'Bank Director',
      BusinessPaymentDirector: 'Business Payment Director',
      QA: 'Q & A',
      QA_1: 'What are the positions available?',
      QA_11:
        'Senior operations and maintenance manager, senior front-end developers, security engineers, senior iOS developers, Tst developers, DBA developers, Android developers, PHP developers, UI/UE Designers, Technical Director.',
      QA_2: 'What benefits do the company provide?',
      QA_21:
        'Aside from the competitive salary, employees will receive welfare dividends after half a year. Employees are also entitled to 13th month salary. Employees can enjoy 15 days of vacation leave every 6 months, which totals to 14 days per year. Other leaves include paid sick leaves, wedding leaves, funeral leaves, maternity leaves, and other holiday leaves. The vacation tickets are reimbursable. Maternity leaves are entitled to a maternity allowance. The company celebrates employees’ birthdays by giving them birthday gifts and a birthday bonus. The company also organizes a monthly gathering and a monthly outing for its employees. Company dormitories have nice facilities such as a gym and a pool. The company also provides 3 meals a day with free flowing snacks and a daily serving of fruits to ensure the physical and mental health of employees.',
      QA_3: 'Will company provide working permit for foreign employees?',
      QA_31: `The company holds a legal license locally and is recognized by the local government. The company pays attention to legal compliance and ensures the safety and security of its employees. Working visas will be processed for employees.`,
      QA_4: 'Working hours',
      QA_41: '9 hours of work time. 1 rest day per week.',
      QA_5: 'Is the company location safe?',
      QA_51:
        'The company offices are located in the financial and commercial districts of Manila – Makati City and Pasay City. The law and order situation is very good. The company dormitories are located near the offices and there are company service cars that send employees to and from work every day.',
      QA_6: 'How am I going start to work in Manila?',
      QA_61:
        'The company will process working visas and air tickets for officially hired employees. After the employees arrive at the airport, they will be picked up at the airport and sent to the company dormitory.',
      QA_7: 'What preparations do I need to make?',
      QA_71:
        'The Philippines is a tropical country with only two seasons – rainy and sunny seasons. The normal temperature in the Philippines ranges from 27 °C -33 °C throughout the year. May-October is the rainy season in the Philippines. Employees need to bring a valid passport, visa and important personal items such as rain gear, sunscreen, and summer clothing. Upon arrival at the airport, the company will provide employees with a local sim card along with a starter kit that contains daily needs such as beddings, toiletries and other living necessities. The company ensures that employees can conveniently move in without having to worry about their living arrangements.',
      JobDetails: 'Job Details',
      detail:'Details',
      recruitment:"Recruitment",
  
      UiDesign:"UI Design",
      AmonthAgo:"a month ago",
      NextPage:"Next Page", 
      billion:"To be next billionaire",
      ContactHr: 'contact: hr@long-sage.com',
      companyYear: '©2016-2023 Longsage All Rights Reserved',
      jobDetails:'Job Details',
      recommendedSearch:'Recommended Search',
      emptyOppText:"No search results",
      more:"More",
      all: 'All',
      design: 'Design',
      development: 'Development',
      operations: 'Operations',
      testing: 'Testing',
      product: 'Product',
      people:'',
      emptySearchText:"No search results, try a different word",
      errorText:"Sorry, the page you visited does not exist~"
    },
  },
  zh: {
    translation: {
      longsagePage: '长圣首页',
      companyProfile: '公司概况',
      benefitsButton: '薪资福利',
      opportunities: '工作机会',
      contactUs: '联系我们',
      faqs: '常见问题',
      wonder: '创思集团',
      companyIntroduction: '公司简介',
      salary: '薪资福利',
      job: '工作机会',
      AboutUs: '关于长圣',
      ContactUs: '联系我们',
      companyUppercase: '公司概况',
      salaryUppercase: '薪资福利',
      jobUppercase: '工作机会',
      ContactUsUppercase: '联系我们',
      About: '关于创思',
      JoinUs: '加入我们',
      companyDetail:
          '长圣集团成立于2016年，目前集团规模已达2000多人，致力于在东南亚乃至全世界提供高品质的软件开发、数据分析、游戏娱乐和智能科技服务。长圣集团拥有多支经验丰富、技术先进、高素质的国际化技术团队。集团秉承“诚信、创新、高效、以人为本”的企业理念，采用先进的开发工具和平台，能够快速高效地开发出高质量的软件产品，始终将客户需求放在首位，为客户创造价值。集团未来将继续投入大量资金和人力资源，不断提高技术实力和服务水平，让企业、员工和客户实现共赢。',
      Strategy: '战略',
      StrategyDetail:
          '立足亚洲市场，为国际多元化发展开拓创新；以客户为中心，为客户精心打造优质产品及服务',
      Vision: '愿景',
      VisionDetail:
          '用最新的技术将娱乐传播到每一个角落',
      Mission: '使命',
      MissionDetail: '顺应时代的需求，不断创新，努力成为行业的领头羊',
      salaryTitle: '薪资福利',
      salaryDetail:
        '公司提供高于同行业的薪资水平，为所有员工办理正规工作签证，除此之外，还提供诸多福利。',
      Salary1: '薪酬',
      Salary_1: '年底13-16薪',
      Salary_2: '项目奖励',
      Salary_3: '员工分红',
      Salary_4: '生育津贴,过节费,忠诚奖,全勤奖',
      benefits: '住',
      benefits_1: '高级酒店公寓入住提供生活用品外住高额房补',
      benefits_2: '',
      benefits_3: '',
      meals: '食',
      meals_1: '三菜一汤的中餐服务',
      meals_2: '特色菲餐、营养餐',
      Accommodation: '住',
      Accommodation_1: '高级酒店、公寓',
      Accommodation_2: '宿舍保姆服务',
      Accommodation_3: '入住提供生活用品',
      Accommodation_4: '外住高额房补',
      Transportation: '行',
      Transportation_1: '上下班往返班车',
      LeaveEntitlement: '假期',
      LeaveEntitlement_1: '14天年假',
      LeaveEntitlement_2: '婚假、产假等',
      LeaveEntitlement_3: '',
      LeaveEntitlement_4: '',
      TeamBuilding: '发展机会',
      TeamBuilding_1: '丰富的团建旅行活动',
      TeamBuilding_2: '培训和发展机会',
      JobOpportunities: '工作机会',
      Opportunities:"工作机会",
      JobSearch: '职位搜索',
      jobtitle: '请输入职位名称',
      jobName: '职位名称',
      recruits: '招聘人数',
      salaryrange: '薪资范围',
      releasetime: '发布时间',
      operation: '操作',
      Details: '详情',
      Jobresponsibilities: '岗位职责',
      jobrequirements: '任职要求',
      Remuneration: '薪酬待遇',
      php: 'PHP开发工程师',
      Frontend: '前端',
      TestManager: '测试主管',
      Test: '测试',
      SeniorJAVA: '高级JAVA',
      DefensiveResearch: '渗透测试与攻防研究',
      PHPtoPaypal: 'PHP支付',
      OperationsDevelopment: '运维开发',
      SeniorOperations: '高级运维工程师',
      bgSeniorOperations: '大数据运维工程师（高级）',
      TechnologySpecialist: '大数据技术专家',
      TestEngineer: '大数据测试工程师',
      DataArchitect: '数据构架师',
      DataDevelopmentEngineer: '数据开发工程师',
      DataProductManager: '数据产品经理',
      MobileProductManager1: '移动端产品经理 (初中级)',
      MobileProductManager: '移动端产品经理（高级）',
      DataAnalyst: '数据分析师',
      SeniorProductExperienceDesigner: '高级产品体验设计师',
      BigDataProductManager: '大数据产品经理',
      SportsQuizProducManager: '体育竞猜产品经理',
      SeniorProductManager: '高级产品经理',
      GameNumericalPlanning: '游戏数值策划(初中级)',
      GameNumericalPlannings: '游戏数值策划 (高级)',
      Android: '安卓',
      MobileTechnologyDeputyDirector: '移动技术副总监',
      SeniorOperationsDesigner: '高级运营设计',
      SeniorUEDesigner: '高级UE设计师',
      BrandGraphicDesigner: '品牌平面设计师',
      SeniorUI: '高级UI',
      UIDesignSpecialist: 'UI设计专家',
      DeputyCreativeDirector: '设计副总监',
      GameInterfaceDesigner: '游戏界面设计师',
      CEOAssistant: 'CEO助理',
      RecruitingDeputyDirectors: '招聘副主管',
      AndroidDevelopment: 'Android安卓开发',
      IOSDevelopmentEngineer: 'IOS开发工程师',
      Projectmanager: '项目主管',
      FinancialDirector: '财务总监',
      BankDirector: '银行行长',
      BusinessPaymentDirector: '业务支付总监',
      QA: '常见问题',
      QA_1: '工作岗位，薪资待遇是什么？',
      QA_11:
        '有高级工程师，技术组长，主管，总监。面试通过依据能力可以向公司提出要求。组长，主管，总监依据能力大小可以向公司谈出自己的待遇和年薪。',
      QA_2: '请问公司会为每一位员工办理合法工作签证吗？',
      QA_21:
        '公司在当地持合法牌照，得到当地政府的认可。公司注重合法合规，确保员工的安全保障，会为员工办理工作签证。 ',
      QA_3: '公司福利如何？',
      QA_31: `员工除工资外，半年以后享受福利分红，年底双薪。14天年休假、婚假、丧假、产假、周休等相关假期，并享受回国休假机票签证往返报销，生育津贴。员工生日享有生日礼金，每个月发放团建津贴增强员工的凝聚力及归属感。高级酒店、公寓住宿（含有健身房游泳池）或高额住房补助金。公司还提供3菜一汤的中餐服务，每天水果零食供应不断，确保员工的身心健康。`,
      QA_4: '每天工作时间是多长？',
      QA_41: '工作时间为每天9小时 每周休息一天。',
      QA_5: '请问公司地点是否安全？',
      QA_51:
        '公司位于马尼拉，马卡提和帕塞的金融商业中心，治安情况非常好，公寓位于公司附近，上下班有公司车接送，非常方便。',
      QA_6: '请问我如何到达公司过去工作？',
      QA_61:
        '公司为正式员工承担签证及机票（HR安排）。员工到达机场后，行政准点到机场接机安全送到公司及宿舍。',
      QA_7: '我需要做好哪些准备?',
      QA_71:
        '菲律宾一年的气候温度均为27°-33°，每年的5月--10月份是菲律宾的雨季，您只需要带上有效护照、签证及个人重要物品，雨具、防晒霜、夏天的衣物即可。抵达机场后，公司行政人员将接送员工至住宿点和协助购买本地电话卡让员工报平安。公司后勤人员将提前为您准备好全新的床上用品及日用品，为员工提供轻便的入住方式，无需烦恼住行问题。',
      JobDetails: 'JobDetails',
      recruitment:"招聘人数 ",
      detail:'详情',
      UiDesign:"高级UI",
      AmonthAgo:"一个月前",
      NextPage:"下一页",
      billion:"百万年薪等你来拿",
      ContactHr: '联系方式: hr@long-sage.com',
      companyYear: '©2016-2023 Longsage All Rights Reserved',
      jobDetails:'职位详情',
      recommendedSearch:'推荐搜索',
      emptyOppText:"没有搜索结果，换个词试试吧～",
      more:"查看更多",
      all: '全部',
      design: '设计',
      development: '开发',
      operations: '运营',
      testing: '测试',
      product: '产品',
      people:'人',
      emptySearchText:"没有搜索结果，换个词试试吧～",
      errorText:"很抱歉，您访问的页面不存在～"
    },
  },
};
