import { useEffect, useState } from 'react';
import { ScrollItem } from 'apps/declarations';
import Header from '../components/mobile/header/Header';
import Home from 'components/mobile/home/Home';
import Bonus from 'components/mobile/bonus';
import About from '../components/mobile/about/About';
import Contact from 'components/mobile/contact';
import Faq from 'components/mobile/faq';
import Opportunities from 'components/mobile/opportunity/opportunity';
import SideMenu from 'components/mobile/sideMenu';

const MobilePages = () => {
  const [sections, setSections] = useState<ScrollItem[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onHideSideMenu = () => {
    setIsOpen(false);
  }

  const onSlideTo = (index: number) => {
    const scrollItem: ScrollItem = sections[index];
    const headerOffset = scrollItem.offset || 0;
    const elementPosition = scrollItem.element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    
    onHideSideMenu()
  }

  useEffect(() => {
    setSections([
      { element: document.getElementsByClassName('homeMobile')[0] },
      { element: document.getElementsByClassName('about-container')[0], offset: 80 },
      { element: document.getElementsByClassName('bonusWrapperMobile')[0], offset: 75 },
      { element: document.getElementsByClassName('oppMbContainer')[0], offset: 45 },
      { element: document.getElementsByClassName('contactWrapperMobile')[0] },
      { element: document.getElementsByClassName('faqsWrapper')[0] },
    ])
  }, [])

  return (
    <>
      <Header onOpen={() => setIsOpen(true)} />
      <SideMenu isVisible={isOpen} onHide={onHideSideMenu} onClick={onSlideTo}/>
      <Home/>
      <About/>
      <Bonus/>
      <Opportunities/>
      <Contact/>
      <Faq/>
    </>
  )
}

export default MobilePages;