import React, { useEffect, useState } from "react";
import "./style.scss";
import JobPostsArr from "./data";
import Modal from "./Modal";
import { JOBLIST, ENJOBLIST } from "./data";
import i18n from "../../../config/i18n";
import { Translation } from "react-i18next";
import i18next from "i18next";

import { ReactComponent as SvgSearch } from "../../../assets/web/job/ic_search.svg";
import emptyImg from "../../../assets/web/job/emptyImg.png";
const Opportunities = () => {
  const [searchInput, setSearchInput] = useState("");
  const [dataArr, setDataArr]: any[] = ([] = useState([]));
  const [newDataArr, setNewDataArr]: any[] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [activeCardId, setActiveCardId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [changeLanguage, setChangelanguage] = useState(false);
  const [modalData, setModalData] = useState({});
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = newDataArr.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(newDataArr.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    // console.log("aaaaa",i18n.language);
    // console.log("translation",t);

    if (i18n.language === "en") {
      setNewDataArr(ENJOBLIST);
      setDataArr(ENJOBLIST);
    }
    if (i18n.language === "zh") {
      setNewDataArr(JOBLIST);
      setDataArr(JOBLIST);
    }
  }, []);
  // useEffect(() => {

  //   // console.log("aaaaa",i18n.language);

  //     },[]);
  //   useEffect(() => {
  // console.log("okkkkk",i18n)

  //       },[i18n.on(languageChanged)]);

  i18next.on("languageChanged", function (lng) {
    setChangelanguage(!changeLanguage);
    // console.log(lng);
    if (lng === "zh") {
      setNewDataArr(JOBLIST);
      setDataArr(JOBLIST);
    }
    if (lng === "en") {
      setNewDataArr(ENJOBLIST);
      setDataArr(ENJOBLIST);
    }
  });

  const searchItems = (searchValue: string) => {
    // console.log({searchValue})
    //   setSearchInput(searchValue)
    //   const filteredData =  dataArr.filter((item:any) => {
    //     return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
    // })

    // setDataArr(filteredData)

    if (searchValue !== "") {
      const filteredData = dataArr.filter((item: any) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      // console.log(filteredData)
      //            setDataArr(filteredData)
      setNewDataArr(filteredData);
    } else {
      setNewDataArr(dataArr);
    }
  };
  return (
    <div>
      <div className="oppContainer">
        <div className="leftContainer">
          <div className="header-opp">
            <div className="title">
              {changeLanguage ? (
                <img
                  className="oppTitle"
                  src={require(`../../../assets/web/job/englishOpp.webp`)}
                />
              ) : (
                <img
                  className="oppTitle"
                  src={require("../../../assets/web/job/chineseOpp.webp")}
                />
              )}

              {/* <img className='oppTitle' src={require(`../../../assets/web/job/${<Translation>{t => <>{t('oppImg')}</>}</Translation>}.webp`)} /> */}
              {!changeLanguage ? (
                <div className="titleExtra"> Opportunities</div>
              ) : null}
              <div className="titleText">
                <Translation>{(t) => <>{t("billion")}</>}</Translation>
              </div>
            </div>

            <div className="imgContainer">
              <img
                className="oppLogoImg"
                src={require("../../../assets/web/job/Group2x.webp")}
              />
              <img
                className="oppImg"
                src={require("../../../assets/web/job/opportunities_img@2x.webp")}
              />
            </div>
          </div>
        </div>

        <div className="rightContainer">
          <div className="searchContainer">
            <div className="wrap">
              <div className="search">
                <button type="submit" className="searchButton">
                  <Translation>{(t) => <>{t("JobSearch")}</>}</Translation>:
                </button>

                <div className="searchTerm">
                  {changeLanguage ? (
                    <input
                      type="text"
                      onChange={(e) => searchItems(e.target.value)}
                      className="searchTermInput"
                      placeholder="Kindly enter the job title"
                    />
                  ) : (
                    <input
                      type="text"
                      onChange={(e) => searchItems(e.target.value)}
                      className="searchTermInput"
                      placeholder="输入搜索职位"
                    />
                  )}

                  {!changeLanguage ? (
                    <div className="searchIconSvg">
                      <SvgSearch />
                    </div>
                  ) : null}
                </div>
                {/* <Translation>{t => }</Translation> */}
              </div>
            </div>
          </div>
          <Modal
              modalIsOpen={modalIsOpen}
              handleModal={setModalIsOpenToFalse}
              modalData={modalData}
            />

          <div className="oppCardContainer">

            <>{currentPosts.length>0 ? 
        <>
        
        { currentPosts.map((post: any) => (
          <div
            key={post.id}
            className={
              activeCardId === post.id ? "oppCardActive" : "oppCard"
            }
            // onClick={() => {
            //   // console
            //   setActiveCardId(post.id);
            // }}
            onClick={() => {
              setModalData(post);
              setModalIsOpenToTrue();
            }}
          >
            <div className="oppCardHeader">
              <div className="cardPostTime"> {post.time}</div>
              <button
                className="cardButton"
                onClick={() => {
                  setModalData(post);
                  setModalIsOpenToTrue();
                }}
              >
                {" "}
                <Translation>{(t) => <>{t("detail")}</>}</Translation>
              </button>
            </div>

            <div className="oppCardDetails">
              <div className="oppCardName">{post.name}</div>
              <div className="oppCardSalary">{post.detail.salary}</div>
            </div>
            <div className="oppCardFooter">
              <Translation>{(t) => <>{t("recruitment")}</>}</Translation>{" "}
              <span> {post.headCount} </span>{" "}
              <Translation>{(t) => <>{t("people")}</>}</Translation>
            </div>
          </div>
        ))}

<div className="oppPagination">
        {pageNumbers.map((number) => (
          <div
            key={number}
            onClick={() => paginate(number)}
            className={
              number !== currentPage ? "oppPageItem" : "oppPageItemActive"
            }
          >
            <div className="page-link">{number}</div>
          </div>
        ))}

        <div
          className="oppPageItem"
          onClick={() => {
            if (currentPage < pageNumbers.length) {
              paginate(currentPage + 1);
            }
          }}
        >
          {" "}
          <Translation>{(t) => <>{t("NextPage")}</>}</Translation>
        </div>
      </div>
      </>
        :<div className="oppEmptyCardContainer">

<img src={emptyImg} className="emptySearchImg"/>
<div className="emptySearchText">   <Translation>{(t) => <>{t("emptySearchText")}</>}</Translation></div>
        </div>   }
            
            </>


  
          </div>


        </div>
      </div>
    </div>
  );
};

export default Opportunities;
