import contact_us_telegram_ic from '@assets/web/contact/web_contact_us_telegram_ic@2x.webp'
import contact_us_email_ic from '@assets/web/contact/web_contact_us_email_ic@2x.webp'
import contact_us_longsage_ic from '@assets/web/contact/web_contact_us_longsage_ic@2x.webp'
import contact_us_ic from '@assets/web/contact/web_contact_us_ic@2x.webp'
// 联系信息
export const CONTACTINFO = {
  telegram: '@HR_Recruit',
  linked: 'Longsage Group',
  email: 'Hr@Long-Sage.com',
  skype: '1563b6d012bff430',
};

// web页联系信息
export const WEBCONTACTINFO = [
  {
    img:{
      src: contact_us_telegram_ic,
      width: '32.16px',
      height: '30.59px'
    },
    info: CONTACTINFO.telegram
  },
  {
    img:{
      src: contact_us_email_ic,
      width: '34.5px',
      height: '24px'
    },
    info: CONTACTINFO.email
  },
  {
    img:{
      src: contact_us_longsage_ic,
      width: '31.91px',
      height: '36.66px'
    },
    info: CONTACTINFO.linked
  },
  {
    img:{
      src: contact_us_ic,
      width: '34px',
      height: '34px'
    },
    info: CONTACTINFO.skype
  },
];

export const SEOKey = [
  'Innovations Group,Innovations Group Phillipines,Innovations Group POGO'
];

export const JOBLIST = [
  {
    id: 1,
    name: 'php',
    headCount: 7,
    time: '2019/5/26',
    detail: {
      responsibility: '',
      requirement: [
        '本科以上学历，3年或以上PHP开发经验；',
        '熟悉LNMP环境的使用及搭建、调试、排错、优化、部署等；',
        '熟悉MySQL数据库开发及常用优化方法；',
        '熟悉Redis，等缓存技术；',
        '熟悉Laravel、Phalcon、ThinkPHP中至少一种框架；',
        '熟悉Linux常用命令； ',
        '掌握常见场景设计，如消息队列、缓存、高并发、高可用、高负载等（有千万级流量网站开发经验优先）；',
        '熟悉html/css/js及jQuery等框架者优先；',
        '熟悉Lua、GoLang、Python、Kafka等技术者优先。',
      ],
      salary: '25-40K',
    },
  },
  {
    id: 2,
    name: 'Golang',
    headCount: 15,
    time: '2019/5/26',
    detail: {
      responsibility: '',
      requirement: [
        '计算机或者数学相关专业，本科及以上学历',
        '基于微服务架构，负责管理后台,前端Api所有功能开发及维护，不断提升代码质量、可扩展性和可维护性；',
        '具有至少两年golang项目经验和三年以上',
        '其它语言（PHP/C++）的项目经验 精通 MySQL应用开发，熟悉数据库原理和常用性能优化技术，以及 NoSQL原理、使用场景以及限制,精通redis； 熟悉至少一种消息队列 Kafka/Rabbitmq/Redis等负责所编程序的自测试工作，并参加系统集成和联调；',
        '具有分布式系统设计和开发经验者优先。',
        '精通TCP/IP，HTTP协议，包括但不限于RPC、调用策略等；精通Protobuf/JSON序列化协议者优先',
      ],
      salary: '28-45K',
    },
  },
  {
    id: 3,
    name: 'Frontend',
    headCount: 5,
    time: '2019/5/26',
    detail: {
      responsibility: '',
      requirement: '',
      salary: '20-35K',
    },
  },
  {
    id: 4,
    name: 'TestManager',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: '',
      requirement: '',
      salary: '30-40K',
    },
  },
  {
    id: 5,
    name: 'Test',
    headCount: 6,
    time: '2019/5/26',
    detail: {
      responsibility: '',
      requirement: [
        '根据产品规格和测试需求，编写测试方案、测试用例，(APP 经验的优先',
        '熟悉主流测试工具，包括抓包工具、自动化测试工具, 性能测试，异常测试工具等，(Linux 环境下测试经验的优先',
        '熟悉主流的测试用例管理工具及缺陷管理工',
        '掌握良好的测试理念、测试方法，具有良好的业务理解能力、交流、沟通能力，能够清晰准确的描述BUG、跟踪BU',
        '熟悉TCP/IP协议、HTTP协',
        '有较好的协调能力，以及具有良好的团队意识和合作精',
        '熟悉Linux系统、MySQL数据库经验者优',
        '三年以上测试工作经验，知名 APP 公司工作经验优',
        '做事认真，有责任心，有工作热情，能吃苦耐劳',
      ],
      salary: '15-25K',
    },
  },
  {
    id: 6,
    name: 'SeniorJAVA',
    headCount: 10,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '参与产品的架构设计讨论和技术调研',
        '搭建系统开发环境，完成系统具体的设计、编码及单元测试 ',
        '撰写系统详细设计文档',
      ],
      requirement: [
        '3年以上JAVA项目开发经验；有过大型项目经验者优先考虑；',
        '熟悉设计模式，具有面向对象的分析和设计的能力；具有丰富的J2EE架构设计经验',
        '熟悉Spring、Mybatis、Redis、MQ等常用框架',
        '熟悉Tomcat及Linux操作系统；',
        '熟练应用rabbitmq、redis、mongodb、dubbo、zookeeper、springboot中的一种或几种优先； ',
        '熟悉微服务架构及SpringCloud工具集的使用',
        '工作态度端正，积极性高；具有较强的沟通能力及团队合作精神；有抗压能力，能适应高强度的工作。',
      ],
      salary: '20-45K',
    },
  },
  {
    id: 7,
    name: 'COCOS-creator',
    headCount: 1,
    time: '2019/5/30',
    detail: {
      responsibility: [
        '根据需求使用COCOS CREATOR游戏引擎开发H5游戏;',
        '负责游戏客户端核心模块和业务逻辑的开发和维护工作',
      ],
      requirement: [
        '拥有2年以上棋牌类或捕鱼类等游戏开发经验;F至少熟练掌握C++/Lua/Javascript/Typescript其中一种语言，拥有良好的编码风格和习惯;',
        '熟练掌握游戏引擎（COCOS CREATOR）开发，拥有2款以上游戏开发经验;',
        '主管能动性强，热爱新技术;具备良好的团队合作意识及积极的心态',
      ],
      salary: '25-40k',
    },
  },
  {
    id: 8,
    name: 'C++',
    headCount: 10,
    time: '2019/5/30',
    detail: {
      responsibility: [
        '基于微服务架构，负责web Api功能开发及维护;',
        '负责所编程序的自测试工作，并参加系统集成和联调',
      ],
      requirement: [
        '专科以上学历，计算机相关专业; ',
        '3年以上C++服务器开发经验; ',
        '熟悉redis应用场景以及限制 ;',
        '精通vs/gdb等开发工具进行开发，调试;',
        '精通多线程、socket、TCP/IP、HTTP协议; ',
        '熟悉数据结构，对算法有独特见解;',
        '至少熟悉一种以上php、lua开发语言;',
        '具备大型游戏服务器端程序开发经验者优先;',
        '熟悉MySQL应用开发，熟悉数据库原理和常用性能优化技术',
      ],
      salary: '25-50k',
    },
  },
  {
    id: 9,
    name: 'DefensiveResearch',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '对业务服务器、网络设备、网站、APP应用的渗透测试、漏洞挖掘；',
        '提出漏洞修复方案并推动发现的漏洞生命周期闭环工作；',
        '编写安全测试方案、安全测试用例、安全测试报告；',
        '参与公司红/蓝军对抗，开展攻防演练，对公司的防御体系进行验证；',
        '研究和编写自动化安全测试、漏洞挖掘的方案，提升安全团队安全工作效率；',
        '研究各种最新安全攻防技术，跟踪国际国内安全社区的安全动态，不断提高公司整体安全水平；',
        '内网基础设施入侵检测思路的研究和实践，强化对最新威胁手段的防御和检测能力。',
      ],
      requirement: [
        '熟练掌握漏洞扫描、渗透测试常用工具； ',
        '了解SQL注入/XSS/CSRF/文件上传/文件包含/命令执行等漏洞原理；',
        '熟练掌握网络系统攻防，熟悉web、APP渗透测试及安全加固；',
        '会使用代码检测工具，原代码审核，懂得开发语言者优先；',
        '有漏洞提交经验或发表过安全相关文章者优先。',
      ],
      salary: '30-45K',
    },
  },
  {
    id: 10,
    name: 'PHPtoPaypal',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '充分理解任务和需求，按时按质完成系统开发，测试任务;',
        '支持运营运维部门，及时回复各类咨询;',
        '收集整理系统运行中遇到问题和用户反馈;',
        '保持技术和产品方面的学习，提高自身和系统竞争力；',
      ],
      requirement: [
        'MYSQL开发经验；',
        '熟悉至少一种常用PHP开源框架和技术（Thinkphp、Yii、Laravel、zend等）；',
        '熟悉Mysql数据库设计，脚本编写优化；',
        '熟悉MongoDB，Redis等KV数据库使用；',
        '有良好的编码习惯和撰写文档的习惯；',
        '善于主动发现问题，提出问题，解决问题',
        '具备强烈责任心、良好的团队精神； ',
        '具备良好的沟通能力和团队意识；',
        '具备较强的自学能力和意愿，开放，持续上进；',
        '有完整参与项目从立项到上线，运营，运维的经验加分',
        '有支付项目或对接第三方支付经验加分',
      ],
      salary: '25-35k',
    },
  },
  {
    id: 11,
    name: 'C#',
    headCount: 1,
    time: '2019/6/3',
    detail: {
      responsibility: [
        '负责Net 桌面客户端研发;',
        'Xamarin 手机客户端研发;',
        '其他分派任务',
      ],
      requirement: [
        '熟练使用C#语言开发桌面客户端 ',
        '熟练使用Net多线程 ',
        '熟悉C#客户端和服务端通过Http，Https ,WebSocket通信 ',
        '有asp.net 或其他语言网页编程经验 ',
        '有爬虫或扒网页经验优先 ',
        '有Xamarin 或WPF编程经验优先;',
        '好学，有进取心责任感，胆大心细',
      ],
      salary: '25-50k',
    },
  },
  {
    id: 12,
    name: 'OperationsDevelopment',
    headCount: 3,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责开发并维护自建运维系统，熟练前后端开发 ',
        '维护自建系统，出现故障及时处理，保障系统稳定运行 ',
        '挖掘系统短板保障产品安全性 ',
        '负责公司内部自动化产品的开发，完善平台自动化的能力',
      ],
      requirement: [
        '计算机相关专业，两年以上系统维护/开发经验',
        '熟练运用js相关技术做过web前端，精通web通用框架原理',
        '熟练Django,Flask,tornado,sanic等任意2-3个Web同步/异步框架  ',
        '熟练nginx php jenkins git zabbix等服务，了解服务发现，服务注册原理',
        '熟悉基本运维工具栈，如：ansible salt zabbix ldap jenkins git ',
        '了解Docker等虚拟化技术，具备k8s并有实际使用和调整经验者加分 ',
        '了解爬虫，数据结构化相关类库加分  ',
        '具有良好的编码风格与编程习惯，有良好的技术文档读写能力和沟通能力，良好的团队合作精神',
        '拥有自我驱动的学习和工作习惯',
      ],
      salary: '25-40k',
    },
  },

  {
    id: 13,
    name: 'SeniorOperations',
    headCount: 10,
    time: '2019/6/13',
    detail: {
      responsibility: [
        '参与并改进网站服务的整个生命周期，包括设计/架构/部署/运维以及持续优化；',
        '通过设计及监控可用性，延时等服务整体健康性指标，来运维网站服务；',
        '通过自动化/系统化的方式，以可持续维护的目标来扩容服务，并且主动提出和实施改进服务稳定性及运维灵活性的方案，提升整体网站的运维管理效率； ',
        '负责量化网站服务的服务质量，提升服务SLA标准',
        '负责基础运维工作、提高自动化运维水平、故障响应能力',
      ],
      requirement: [
        '专科及以上学历，3年以上大型系统运维管理工作经验；',
        '有运维管理500台以上服务器经验者优先；',
        '精通linux系统、Shell/Python编程语言、网络TCP/IP协议、数据库等，动手能力强；',
        '精通Nginx，包括不限于三方模块、配置调优、Lua集成及故障排除技巧；',
        '精通ELk，包括不限于日志采集、三方插件、监控、Dashboard等；',
        '熟悉Jenkins、Git、saltstack/ansible、tomcat、redis、mysql等常见开源组件；',
        '较强的分析和解决问题的能力，强烈责任感、 缜密的逻辑思维能力，并有很强的沟通以及主动推进问题直至解决的能力；',
        '有大型云计算或互联网公司从业经验，对于阿里云、AWS系统开发、测试、运维、应用工作经验者优先；',
        '具有容器(k8s+docker)相关的架构设计/实施/运维管理经验优先；',
        '能接受7*24小时突发事件的故障处理；',
      ],
      salary: '20-30k',
    },
  },

  {
    id: 14,
    name: 'bgSeniorOperations',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责公司大数据/Hadoop/hive/spark/hbase/Flume等运维管理工作； ',
        '负责内部大数据自动化运维以及数据化运营平台开发工作； ',
        '负责Hadoop/Hbase等系统的架构审核、业务监控、持续交付、应急响应、容量规划等；',
      ],
      requirement: [
        '计算机相关专业，三年以上大数据基础设施运维工作经验；',
        '掌握Python、Shell，了解java,scala开发语言尤佳，具备基本SQL编写能力；',
        '有自动化运维和监控平台经验者优先； ',
        '掌握Nginx / Apache / Tomcat 等WEB及应用服务技术的配置调优； 能够熟练配置服务器的负载均衡；',
        '熟悉Hadoop大数据生态圈，包括但不限于HDFS、YARN、Hive、HBase、Spark、kafka等； ',
        '掌握Zabbix/Cacti/Nagios等监控管理工具之一；',
      ],
      salary: '30-50K',
    },
  },
  {
    id: 15,
    name: 'TechnologySpecialist',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: '',
      requirement: '',
      salary: '30-50K',
    },
  },
  {
    id: 16,
    name: 'TestEngineer',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '理解业务需求，数据产品需求，参与需求讨论及产品设计；',
        '设计测试用例、测试工具，编写必要的测试脚本； ',
        '执行用例、报告并跟踪缺陷，推动测试中发现问题及时合理地解决； ',
        '从用户角度，在测试过程中，审查系统的友好程度及可改善点 ',
      ],
      requirement: [
        '具有3年及以上测试工作经验； ',
        '具有扎实的测试理论知识及数据库理论知识;',
        '熟练掌握一种及以上主流关系型数据库（SQL Server、Oracle、MySQL优先）;',
        '熟练使用Hive；5、熟悉Java，Python，Shell语言优先； ',
        '熟悉Hadoop、Spark、hbase、kafka等分布式开源项目；',
        '有大数据相关产品完整的项目测试经验，熟悉数据的抽取，转换以及加载过程(ETL)，对数据仓库有基础的了解；',
        '熟悉Linux操作系统及常用命令；',
      ],
      salary: '20~35K',
    },
  },
  {
    id: 17,
    name: 'DataArchitect',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '根据业务分析需求，构建公司大数据平台的数据架构；',
        '使用合适的数据建模方法，对公司业务数据进行建模，以有效支撑业务分析需要； ',
        '通过与业务方的持续沟通，持续优化数据架构及数据模型',
      ],
      requirement: [
        '互联网行业数据架构及数据建模工作经验5年及以上；',
        '熟练掌握数据仓库架构设计，数据建模方法； ',
        '对数据质量治理有一定的经验或者自己的思考； ',
        '熟悉主流分布式存储及计算技术体系，如Hadoop，Spark；',
      ],
      salary: '50-80K',
    },
  },
  {
    id: 18,
    name: 'DataDevelopmentEngineer',
    headCount: 8,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '基于Hadoop，Spark生态技术构建大数据平台；',
        '基于Flume生态技术构建实时计算平台；',
        '基于分布式计算存储系统，进行数据仓库架构设计，数据模型设计，以及ETL开发；',
        '持续优化大数据平台，快速修复问题，快速响应业务的数据需求；',
      ],
      requirement: [
        '三年以上互联网行业大数据开发工作经验；',
        '熟练掌握Scala/Java/Python语言中的一种，熟悉Linux系统； ',
        '熟练掌握Hadoop、Spark、Flink、Hbase、Impala、Druid、Kylin技术栈中一种及以上； ',
        '有基于SparkStreaming，Flink进行实时计算开发经验优先；',
        '具有较好的数据建模经验优先；',
      ],
      salary: '30-50K',
    },
  },
  {
    id: 19,
    name: 'DataProductManager',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: '',
      requirement: '',
      salary: '面议',
    },
  },
  {
    id: 20,
    name: 'MobileProductManager ',
    headCount: 3,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责 APP 相关产品规划及设计，完成用户需求调研分析、业务分析、用户行为分析，制定产品发展策略以及中长期的版本规划，制定版本迭代计划；',
        '与运营/客服/财务/等部门紧密合作，完成相应的产品需求拆解与响应，保证 APP 项目持续稳定的价值交付；  ',
        '与技术团队协作，推动产品需求落地并跟进项目研发全流程，验收产品，确保产品按时发布上线； ',
        '定期组织团队分析 APP 数据，以数据推动产品的功能迭代；',
        '跟进 APP 版本用户反馈，持续推动产品优化迭代。',
      ],
      requirement: [
        '本科及以上学历，专业不限，5年以上产品经理工作经验，两年以上团队管理经验；',
        '具备强烈的责任感与自驱力，沟通能力强，善于调动一切资源去完成目标，交付价值；',
        '思路清晰，善于思考和总结，能够快速提出多视角解决方案；',
        '熟练使用 Office 办公软件及 Axure、Visio、Xmind 等产品常用软件；了解产研协作工作流程，具备项目管理能力；',
        '落地执行力强，了解移动端产品的敏捷开发流程和产品逆向思维；',
        '至少独立负责过一条产品线，通过业务和产品的分析，制定产品的整体规划；',
        '有大型企业协作经验者优先，BAT 背景优先。',
      ],
      salary: '40~55K',
    },
  },
  {
    id: 21,
    name: 'DataAnalyst',
    headCount: 4,
    time: '2019/5/26',
    detail: {
      responsibility: [
        ' 负责基础数据梳理，构建标签体系及分析框架负责日常分析需求响应，按时按量完成基础分析需求和报表设计开发，并能结合业务设计开发主题报表；',
        '配合平台产品或业务线对其用户数据进行深度分析与挖掘，提供数据支撑，并持续优化分析系统；能独立完成业务数据建模；并将结果转化为运营指标；',
        '完善数据报表体系，及时准确监控运营状况，并提供专业分析报告。关注产品线日常运营数据报表，从数据出发向业务部门提出相应的优化建议。',
      ],
      requirement: [
        '本科及以上学历，3 年以上互联网产品经理相关工作经验；',
        '熟悉并热衷于互联网产品，对业务有敏锐的观察力和数据洞见；',
        '了解常用的产品数据分析方法如漏斗分析、路径分析、热点分析及精准营销、交叉销售等业务导向分析方法；',
        '精通Excel、SQL、hadoop、Hive海量数据处理，有构建海量数据数据仓库经验优先；',
        '对数字有敏锐的观察力，喜欢和数字打交道，严谨细致；较强的分析能力，逻辑推理能力；',
        '较强的书面及口头表达能力； 具有较强的自主学习能力，乐于接受挑战；',
        '有责任心、具有团队合作精神，能承受一定的工作压力。 ',
      ],
      salary: '30~50K',
    },
  },
  {
    id: 22,
    name: 'SeniorProductExperienceDesigner',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责客户端产品全链路体验设计, 包括完整项目从设计体验策略到UE/UI的关键设计方案的产出；',
        '负责整体产品的体验和视觉提升/迭代优化, 从用户出发提出解决方案和创新设计并落地；',
        '和产品负责人，产品经理一起构思创意，提供具备优质产品体验的整体解决方案；',
        '对产品数据的变化与用户满意度有极高的敏感度，能灵活度量产品的整体体验水平，提出改进建议；',
        '时刻关注并分析流行产品设计趋势，研究目标用户需求，体验诉求与审美倾向，并迅速优化现有产品；',
        '承担一定团队管理和培养工作, 注重方法和策略。',
      ],
      requirement: [
        '本科及以上学历，设计相关专业背景，8 年以上设计经验，有知名项目案例者优先；',
        '热爱设计，对移动互联网，有较深的理解，了解视觉设计的趋势，有全面的视觉设计理论知识；业务团队与技术团队的沟通桥梁，通过深入了解业务需求，形成 PRD 相关文档，协助技术开发满足数据要求；',
        '了解交互设计的理论知识，对于用户体验有较深的理解，能够从客观的角度进行分析和设计；',
        '对前沿科技，设计探索有浓厚兴趣，自我驱动力强，同时有驱动团队的经验；  ',
        '极度重视细节，追求像素级完美，具备人机交互设计与用户洞察能力；具备较成熟的产品思维和极强的用户同理心，能深度参与用户研究、交互设计、设计原型开发的工作； ',
        '能承担一定的工作压力，对所从事的业务有强烈的责任心； 7. BAT 背景优先考虑。 ',
      ],
      salary: '40~60K',
    },
  },
  {
    id: 23,
    name: 'BigDataProductManager',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '管理和分析业务方数据需求，规划数据平台整体建设目标，支持业务团队数据需求；',
        '主导数据产品的研发，与分析师、数据开发、业务方紧密合作，挖掘数据价值，形成数据产品；',
        '业务团队与技术团队的沟通桥梁，通过深入了解业务需求，形成 PRD 相关文档，协助技术开发满足数据要求；',
        '制定数据产品运营策略，协调部门及业务方持续改善产品功能和使用体验，提高决策效率； ',
        '组织跨团队协作，保证项目如期上线；',
        '通过 SQL 或机器学习模型分析，对数据进行价值追踪和质量验证。',
      ],
      requirement: [
        '本科及以上学历，5 年以上相关工作经验；',
        '有产品设计或分析经验，能够独产承担产品规划工作；',
        '具备良好的数据敏感度和业务视野，能够敏锐的捕获数据价值和产品机会；',
        '了解底层数据的架构技术以及应用层数据分析和算法的基本概念，能够运用数据作为产品解决业务方运营及决策问题；',
        '有较强的 SQL 能力，能够做基本开发和数据分析；6. 能够独立应用 Tableau 或类似分析工具进行数据分析；',
        '有参与过大型项目或系统建设的经验，有流量分析系统、管理决策系统、经营分析系统建设经验者优先；',
        '沟通能力强、有团队合作精神，精通项目管理优先； ',
        '具有银行数据经验者优先；',
        '有算法工程师或数据科学家从业经验者优先；',
        '有大数据底层开发经验者优先。 ',
      ],
      salary: '30~50K',
    },
  },
  {
    id: 24,
    name: 'SportsQuizProducManager',
    headCount: 3,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责体育产品线的需求调研、行业分析和竞品分析；',
        '负责产品蓝图的规划和对各个生命周期的把控；在版本落地中，协调部门内外的沟通协作，确保各部门对 PRD 的充分了解，并推进研发团队对产品规划的按期落地；',
        '监测体育线产品的业务指标数据，基于数据分析，做出相应的调优规划；',
        '收集用户反馈，解读用户诉求和用户行为，挖掘深度需求，并制定相应的应对规划。',
      ],
      requirement: [
        '5 年以上互联网行业经验，且有体育比分、资讯、体育 O2O 或 B 端竞猜产品经验者优先；',
        '热爱体育竞赛，熟悉竞彩代购业务，了解同类产品者优先； ',
        '关注 NBA、足球、网球、电竞等赛事动态，并相当熟悉相关赛事规则和动向；',
        '具备完整的产品生命周期管理经验，包括市场调研、行业分析、竞品分析、产品功能设计、迭代管理等； ',
        '具备较强的协调能力、自我驱动力，文档撰写能力，较强的逻辑思维能力，有责任心和上进心。 ',
      ],
      salary: '30~50K',
    },
  },
  {
    id: 25,
    name: 'MobileProductManager1',
    headCount: 3,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责 APP 各个生命周期的需求管理；',
        '接收、整理和分析用户需求及问题反馈，完成产品的迭代规划与持续改进；',
        '将产品功能、机制、流程用恰当的工具设计为产品原型，编写产品PRD文档，以供设计、开发、测试人员明确产品需求；',
        '跟进设计及开发进度并保持高效沟通，确保产品功能特性和交互符合产品需求文档的要求； ',
        '编写文档，组织培训，帮助客服、运营、财务等关系部门理解和使用产品，并提供有效的支持；',
        '配合运营目标和方案，调整产品本身及支撑平台的功能逻辑，以实现运营目标最大化。',
      ],
      requirement: [
        '本科及以上学历，专业不限，理工科背景佳；',
        '2 年以上互联网/IT行业产品工作经验；',
        '责任心强，能及时跟进产品需求并及时处理线上问题；',
        '能够独立完成对产品的设计，撰写产品文档（包括原型、交互流程、逻辑撰写、说明文档等）。擅长交互设计者优先；',
        '了解产品开发的流程，懂得产品创新，创造竞争优势；',
        '思路清晰，善于沟通和协调资源，富有团队精神，不固执。 ',
      ],
      salary: '25~40K',
    },
  },
  {
    id: 26,
    name: 'SeniorProductManager',
    headCount: 4,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责产品线的用户需求调研、市场和竞品分析；',
        '挖掘业务部门的需求，在高效全面的分析后，提供最优的解决方案； ',
        '产出 Roadmap，落地产品设计和 PRD。并整合内外部资源，协调推进产品规划的实现；',
        '监测业务指标数据，基于数据分析，做出相应的调优规划；',
        '参与所在产品线的商业模型构建。',
      ],
      requirement: [
        '本科及以上学历，5 年以上互联网产品经验，有游戏、体育竞猜、支付相关经验者优先；',
        '具备互联网产品思维，并有优秀的产品设计能力、数据分析能力。对用户体验等细节极敏感，并能够针对性的提出产品优化方案；',
        '有较强的项目管理经验，具备优秀的系统思考能力、方案解决能力。有创新精神、较强的逻辑思维和决断力；',
        '具有较强的沟通协调能力、抗压能力和全局观；5. 协作能力突出，且擅用工具提升工作效率。',
      ],
      salary: '30~50K',
    },
  },
  {
    id: 27,
    name: 'GameNumericalPlanning',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责游戏内数值体系策划及实现相关工作；',
        '负责游戏策划案主体架构系统与细节设计；完成游戏数值设计、制作、跟进及测试验证；',
        '负责游戏上线效果跟进及调整总结优化，游戏核心和基础系统、功能、文案等持续调优；',
        '结合运营需求规划特色玩法和特殊活动，对数据结果负责。',
      ],
      requirement: [
        '本科及以上学历，理工科背景；',
        '1 至 3 年以上的游戏行业系统策划工作经验，游戏类型不限；',
        '能够独当一面负责游戏数值体系的方案设计和制作跟进；',
        '具备良好的，策划案能力，逻辑思维能力，分析能力；',
        '熟悉国内外体育彩票平台者优先。',
      ],
      salary: '25~40K',
    },
  },
  {
    id: 28,
    name: 'GameNumericalPlannings',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责公司体育平台游戏框架搭建与体系建设；',
        '编写游戏系统策划案，UI/UE 策划方案； ',
        '制定游戏数值框架，把握平台发展方向和游戏；',
        '管理游戏数值策划团队。',
      ],
      requirement: [
        '统招本科以上学历，理工科背景，5 年以上游戏数值策划经验，3 年以上游戏主策经验；',
        '熟悉手机游戏产品的开发流程和要点，对各类游戏有系统的认知，或对某类游戏有深入的了解； ',
        '善于分析各类游戏的平衡系统和经济系统设计，深刻理解玩家心态；',
        '有完整的设计理念，能根据项目整体思路和实际情况，系统化的思考和输完整的解决；',
        '精通游戏市场调研、项目开发与控制以及质量的把控；',
        '有较强的组织协调和沟通能力，具备优秀的团队协作精神与团队管理能力；',
        '有棋牌游戏、体育和彩票行业经验者优先。',
      ],
      salary: '40~55K',
    },
  },
  {
    id: 29,
    name: 'Frontend',
    headCount: 9,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责公司 Web 前端技术架构、技术选型、开发规范制定；公司自有产品的web端和手机前端页面开发； ',
        '负责公司前端项目框架及迭代优化工作；产品前端交互、体验、数据可视化等核心研发工作；',
        '推动 Web 前端规范化、工程化建设；带领团队探索技术改进，并解决复杂的技术问题；',
      ],
      requirement: [
        '不依赖脚手架搭建项目架构，比如vue-cli、create-react-app cli、angular-cli这些命令行自动生成的项目组织架构；',
        '对前端的数据与视图之间的关系有一定的理解，比如vue和vuex的关系，react和redux的关系，为什么要这么设计？  ',
        '对前端自动化构建流程有独立的思考能力；',
        '对ECMA Script或JavaScript语言机制有比较深的理解；',
        '必须掌握的技术栈：react、redux、typescript、rxjs、nest、es6/7、electron ',
        '对node.js服务端开发有一定的了解，比如react、vue的服务端渲染，中间件服务器（gateway）的开发；',
      ],
      salary: '30-60k',
    },
  },
  {
    id: 30,
    name: 'Android',
    headCount: 4,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '系统的功能开发迭代，涉及framework/kernel等层面的改进和用户体验提升，包括应用架构设计，性能分析和系统问题定位和解决，具备良好的创新意识；完成模块代码编写、单元测试、代码维护工作',
        '编写技术相关文档，熟练使用迭代开发方法进行系统开发；',
        '根据项目的需要快速学习并掌握相关新技术；',
        '参与项目重点、难点的技术攻坚',
      ],
      requirement: [
        '有组件化架构设计的经验 ',
        '理解至少6种设计模式,读过至少一个优秀的开源项目  ',
        '熟练开发自定义控件和动画 ',
        '具有安全，性能（渲染速度、内存优化、电量消耗等）方面的开发经验 ',
        '能定位卡顿和OOM问题  ',
        '熟悉NDK开发,并有NDK开发经验 ',
        '熟练使用单元测试对负责的模块进行质量保证；',
        '有良好的编码习惯、学习能力和英文文档阅读能力；',
        '熟悉okhttp,retrofit,还有rxjava的,熟悉自定义控件以及动画的优先',
      ],
      salary: '30-60k',
    },
  },
  {
    id: 31,
    name: 'MobileTechnologyDeputyDirector',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责客户端产品线(IOS/Android/和web前端)的技术研发管理工作； ',
        '负责指导成员进行移动平台的架构分析，持续进行架构升级，性能优化与技术演进; ',
        '负责招聘和培养开发工程师，及一线管理人员，构建技术梯队；',
        '负责优化推进项目流程，质量评估，人员绩效等工作;',
        '参与公司整体项目团队协调沟通与跨团队推进; ',
        '关注行业技术动态，保持团队技术持续更新',
      ],
      requirement: [
        '计算机相关专业，本科及以上学历，7年以上的互联网产品研发经验；',
        '至少精通一种移动平台(android/ios)，同时具有良好的技术广度及全局技术设计能力，有后端开发经验加分；',
        '熟悉主流移动端框架, 如组件化，Hybrid方案等，有实践经验及选型能力; ',
        '熟悉移动端性能，质量评估及优化方式，有实践经验;',
        '3年以上的移动端管理经验，熟悉开发流程以及相关的技术架构和最佳实践；30人以上2线团队管理经验加分；',
        '有良好的技术基础及技术认知能力，具备设计模式，网络，安全设计等基本知识；',
        '对产品体验敏感，能够快速了解业务需求，有技术驱动体验的优化意识; ',
        '有良好的学习认知思维，与沟通表达能力; ',
        '履历中有一二线互联网公司背景，参与过知名移动产品加分；',
      ],
      salary: '80-120k',
    },
  },
  {
    id: 32,
    name: 'SeniorJAVA',
    headCount: 8,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '参与产品的架构设计讨论和技术调研; ',
        '搭建系统开发环境，完成系统具体的设计、编码及单元测试',
        '撰写系统详细设计文档',
      ],
      requirement: [
        '3年以上JAVA项目开发经验；有过大型项目经验者优先考虑；',
        '熟悉设计模式，具有面向对象的分析和设计的能力；具有丰富的J2EE架构设计经验 ',
        '熟悉Spring、Mybatis、Redis、MQ等常用框架 ',
        '熟悉Mysql等常用数据库开发及调优；',
        '熟悉Tomcat及Linux操作系统；',
        '熟练应用rabbitmq、redis、mongodb、dubbo、zookeeper、springboot中的一种或几种优先；',
        '熟悉微服务架构及SpringCloud工具集的使用 ',
        '工作态度端正，积极性高；具有较强的沟通能力及团队合作精神；有抗压能力，能适应高强度的工作。',
      ],
      salary: '30-60k',
    },
  },
  {
    id: 33,
    name: 'SeniorOperationsDesigner',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '独立完成产品的活动运营、品牌运营的创意及设计;',
        '根据公司产品方向，进行品牌定位、策划与设计，整体把控品牌调性;',
        '有成熟的工作经验，可独立完成设计工作及确保项目及时推进;',
        '能够快速理解用户和业务需求，对运营数据有了解并沉淀设计方法与经验;',
        '擅长把握各种设计风格，探索研究动态设计、视频剪辑、前端技术等，并结合应用到设计中',
      ],
      requirement: [
        '有3年以上运营视觉设计经验，有独立完成运营设计项目的能力和成功案例',
        '优秀的逻辑、沟通、创新能力，具备全面、系统化思维，抗压力强  ',
        '艺术类、数字媒体传达等相关专业背景，专业基本功扎实，审美水平优秀; ',
        '有优秀的手绘、动态设计能力者优先',
      ],
      salary: '15-30k',
    },
  },
  {
    id: 34,
    name: 'SeniorUEDesigner',
    headCount: 4,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责产品前后端交互设计工作；',
        '配合PM、UI以及RD快速高质量的输出交互文档；',
        '负责梳理定制产品的设计规范，提高产品的试用体验；',
        '指导新人。',
      ],
      requirement: [
        '热爱生活，对美好的事物有追求，',
        '2-5年交互工作经验，熟悉互联网、移动互联网产品开发流程；',
        '有较强的沟通能力，可以充分理解产品需求、使用场景；',
        '爱思考，有较强的逻辑思维、学习能力以及同理心；',
        '有自驱力、工作目标感和计划性；',
        '必须有完整的工作总结、不限于项目、方法论沉淀等；',
        '以上足够优秀可以放宽条件，有用研、广告领域、B端、工具类、PC平台工作经验加分。工业设计、人机交互、心理学、计算机等相关专业毕业优先，大公司背优先。',
      ],
      salary: '18-30k',
    },
  },
  {
    id: 35,
    name: 'BrandGraphicDesigner',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责集团公司的品牌vi、品牌升级优化、市场活动物料设计；',
        '负责产品形象发展方向，梳理视觉表现落地',
      ],
      requirement: [
        '3-5年品牌相关设计经验，美术、设计或相关专业 ',
        '有口碑品牌设计经验者优先，美术、设计或相关专业 ',
        '擅长各种视觉风格表现，能够准确抓到品牌调性',
      ],
      salary: '15-25k',
    },
  },
  {
    id: 36,
    name: 'SeniorUI',
    headCount: 9,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责公司pc、app产品的前期视觉推导完成设计方案，制定设计规范，熟悉设计流程；',
        '参与前瞻性产品的创意设计和动态DEMO的实现。 ',
      ],
      requirement: [
        'UI设计5年以上工作经验；',
        '对企业vi系统设计有一定的经验；',
        '有在互联网企业经验者有优先：腾讯、百度、乐视、爱奇艺等 ',
        '精通多种设计软件；',
        '精通网页设计，对html+css有一定的了解，对于交互效果有着丰富的经验 ',
        '精通手机端设计（H5,APP），熟悉种各种机型的设计规范，熟练快速处理界面的切片和标注，并提供最优的适配方案；',
        '精通网页设计，对html+css有一定的了解，对于交互效果有着丰富的经验',
      ],
      salary: '18-30k',
    },
  },

  {
    id: 37,
    name: 'UIDesignSpecialist',
    headCount: 5,
    time: '2019/6/1',
    detail: {
      responsibility: [
        '进行高质量的业务/产品ui的视觉设计，主导视觉规范制定；',
        '站在用户角度挖掘需求，为产品功能和特性提供合理的系统化设计解决方案；',
        '独立承担项目，引领团队ui视觉设计思维意识的创新，实现产品视觉呈现在用户体验上和视觉上的突破；',
        '设计经验丰富，参与app、web、h5等界面设计，具备强悍的设计推动力。',
      ],
      requirement: [
        '科班出身，美术院校设计专业毕业，对设计有独特的理解和系统的学习（非美术院校毕业一律不予考虑）；',
        '5年及以上相关工作经验，主导过多套成型成熟的UI设计作品，有丰富的多平台产品视觉设计经验；',
        '对各种互联网产品趋势有敏锐的设计触觉，富有设计创造力和激情，有探索研究精神；',
        '强逻辑思维能力，掌握业务需求分析、产品需求分解的技巧；',
        '学习能力强，具有良好的沟通和设计表达能力，良好的团队协作态度，主动积极，踏实细心，协调能力强',
      ],
      salary: '50-80k',
    },
  },

  {
    id: 38,
    name: 'DeputyCreativeDirector',
    headCount: 3,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责把控产品的视觉设计方案；负责设计团队的日常管理、设计审核，协调项目相关的设计资源，把握进度，推动视觉及交互方案的落地；',
        '跟踪互联网行业设计趋势，研究行业、竞争对手等产品设计新概念，为产品长远规划决策提供信息支持；',
        '配合市场、运营及产品团队，提出视觉、创意及交互的意见与方案 ；',
        '参与新产品功能的构思，站在用户角度提出使用习惯的需求；',
        '梳理优化设计团队工作流程；具有一定的设计影响力，且有创新精神',
        '定义产品的设计规范，保证产品与服务体验一致性；',
        '指导从初级到高级的设计师，进行人员培养和成长管理,分享设计经验、推动提高团队的设计能力； ',
        '协助设计师与产品、运营、研发等部门间保持良好的沟通与协作',
      ],
      requirement: [
        '全日制设计专业本科及以上学历，设计相关专业，4年及以上的管理经验，且有成熟的线上产品设计经验，知名互联网任职经历优先； ',
        '具备深厚的设计理论和设计功底，在用户体验设计及相关领域有深入的理解与实践有优秀的艺术审美功底；',
        '了解国际化的设计风格，时刻关注前沿的产品设计趋势，能够独立带领团队完成从设计构思到设计表达的整个过程；',
        '能够对自身产品及行业、竞争对手等设计有较为深刻的研究和理解；',
        '沟通协作能力优秀，团队管理能力及推动执行能力突出。',
        '有良好的大局观，擅长拥抱变化；',
        '优秀的沟通及团队合作能力和推动力；',
        '保持好奇，乐于学习，充满激情，关注细节； ',
        '善于总结和设计经验，并乐于分享',
      ],
      salary: '40-60k',
    },
  },
  {
    id: 39,
    name: 'GameInterfaceDesigner',
    headCount: 10,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '设定游戏界面的视觉创意和风格； ',
        '按照已定界面风格的原形标准，完成各级界面的延展性设计；',
        '完成界面相关标志、图标、图素的设计与绘制；',
        '跟进设计效果最终在产品的实现，保证设计品质与实现品质一致',
        '有效管理界面资源，对界面编辑器等工具、方法进行合理优化',
      ],
      requirement: [
        '具有扎实的美术功底，良好的色彩搭配感及设计造型的能力；',
        '有较强的手绘能力，能独立设计精绘界面中的图素与图标；',
        '熟练使用Photoshop，Illustrator等相关制图软件；',
        '工作态度积极，具有良好的工作协作能力、团队合作精神，善于沟通；',
        '熟悉cocs2d和unity3d引擎的设计还原相关原理；',
        '有棋牌游戏经验或者成功线上游戏作品优先。',
      ],
      salary: '18-30k',
    },
  },
  {
    id: 40,
    name: 'CEOAssistant',
    headCount: 5,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '处理各类英文合同文件，与当地各政府机构对接； ',
        '跟进项目进程，并统计项目最新进度及时反映项目中存在的不足 ',
        '会议纪要以及其他领导交代的事宜',
      ],
      requirement: [
        '熟练听读写英语，熟练使用办公软件，能协助处理一些商务工作，项目跟进。',
        '有bc工作经验者排除。',
        '中国籍，背景干净，通过初步面试者，需要来菲进行当面面试。',
        '有出国工作经验或者国外留学经验',
      ],
      salary: '30-100k',
    },
  },
  {
    id: 41,
    name: 'RecruitingDeputyDirectors',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '根据公司战略，完成年度人力资源规划，掌握各部门招聘需求，控制人力成本的支出；',
        '协助部门负责人进行组织架构设计和岗位设置，协助梳理和完善公司的岗位说明书；',
        '创建公司各岗位胜任力模型、人才测评模型；通过人职匹配体系，为公司招聘、晋升提供科学的决策依据。',
        '指导督促各子公司拟定招聘方案以及各项具体活动的开展，保证招聘任务顺利完成；',
        '协助统筹招聘准备、实施（招募、甄选、录用）、评估、配置全过程。',
      ],
      requirement: [
        '大专以上学历，人力资源管理、工商管理等相关专业毕业；',
        '5年以上人力资源工作经验，3年以上招聘管理工作经验，有互联网、电商行业招聘管理经验优先；',
        '精通招聘模块，熟练应用各种招聘工具及面试方法；',
        '良好的沟通表达能力、亲和力、协调能力及洞察力；',
      ],
      salary: '20-50k',
    },
  },
  {
    id: 42,
    name: 'Test',
    headCount: 7,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '根据产品规格和测试需求，编写测试方案、测试用例，(APP 经验的优先)；',
        '熟悉主流测试工具，包括抓包工具、自动化测试工具, 性能测试，异常测试工具等，(Linux 环境下测试经验的优先)；',
        '熟悉主流的测试用例管理工具及缺陷管理工具；',
        '掌握良好的测试理念、测试方法，具有良好的业务理解能力、交流、沟通能力，能够清晰准确的描述BUG、跟踪BUG；',
        '熟悉TCP/IP协议、HTTP协议；',
        '有较好的协调能力，以及具有良好的团队意识和合作精神；',
        '熟悉Linux系统、MySQL数据库经验者优先； ',
        '三年以上测试工作经验，知名 APP 公司工作经验优先；9.做事认真，有责任心，有工作热情，能吃苦耐劳',
      ],
      requirement: [
        '根据产品规格和测试需求，编写测试方案、测试用例，(APP 经验的优先)；',
        '熟悉主流测试工具，包括抓包工具、自动化测试工具, 性能测试，异常测试工具等，(Linux 环境下测试经验的优先)；',
        '熟悉主流的测试用例管理工具及缺陷管理工具；',
        '掌握良好的测试理念、测试方法，具有良好的业务理解能力、交流、沟通能力，能够清晰准确的描述BUG、跟踪BUG；',
        '熟悉TCP/IP协议、HTTP协议；',
        '有较好的协调能力，以及具有良好的团队意识和合作精神；',
        '熟悉Linux系统、MySQL数据库经验者优先； ',
        '三年以上测试工作经验，知名 APP 公司工作经验优先； ',
        '做事认真，有责任心，有工作热情，能吃苦耐劳',
      ],
      salary: '20-35k',
    },
  },
  {
    id: 43,
    name: 'AndroidDevelopment',
    headCount: 3,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '系统的功能开发迭代，涉及framework/kernel等层面的改进和用户体验提升，包括应用架构设计，性能分析和系统问题定位和解决，具备良好的创新意识；完成模块代码编写、单元测试、代码维护工作 ',
        '编写技术相关文档，熟练使用迭代开发方法进行系统开发；',
        '根据项目的需要快速学习并掌握相关新技术；',
        '参与项目重点、难点的技术攻坚',
      ],
      requirement: [
        '有组件化架构设计的经验 ',
        '理解至少6种设计模式,读过至少一个优秀的开源项目  ',
        '熟练开发自定义控件和动画 ',
        '具有安全，性能（渲染速度、内存优化、电量消耗等）方面的开发经验 ',
        '能定位卡顿和OOM问题  ',
        '熟悉NDK开发,并有NDK开发经验  ',
        '熟练使用单元测试对负责的模块进行质量保证； ',
        '有良好的编码习惯、学习能力和英文文档阅读能力；',
        '熟悉okhttp,retrofit,还有rxjava的, 然后熟悉自定义控件以及动画的优先',
      ],
      salary: '20-40K',
    },
  },

  {
    id: 44,
    name: 'DBA',
    headCount: 1,
    time: '2019/6/11',
    detail: {
      responsibility: [],
      requirement: [
        '十年以上数据库架构、设计经验，有一定开发基础，有丰富的专业领域知识。',
        '精通关系数据库模型，并对非关系型数据库模型也有所了解。',
        '精通多种主流数据库如Oracle、Mysql、MongoDB等，并熟悉其他NoSQL及NewSQL数据库，如Redis、Cassandra、TIDB等。',
        '熟悉不同数据库、不同平台的优势、劣势，并能科学地作出决策。',
        '熟悉操作系统（Linux）、存储、网络技术，并对虚拟化、云等有所了解。',
        '熟悉主流软件架构，了解多种开发语言并至少掌握一门作为开发语言，有一定的开发能力',
        '具备敏锐的技术前瞻性。',
        '具有良好的沟通、协调能力，能承受较大的工作压力。',
        '对于复杂问题，具有很强的分析判断和解决能力。',
        '对于新技术具有很强的学习能力。',
        '具有完备的方案编写能力。',
      ],
      salary: '30-60K',
    },
  },
  {
    id: 45,
    name: 'IOSDevelopmentEngineer',
    headCount: 1,
    time: '2019/6/11',
    detail: {
      responsibility: [],
      requirement: [
        '具备3年以上iOS开发经验；',
        '具有扎实的Objective-C语言基础，熟练掌握Swift；',
        '熟悉常见的数据结构以及设计模式；',
        '深入理解UIKit、Core Animation以及Core Graphics等系统框架；',
        '深入理解Objective-C Runtime运行机制和内存管理机制；',
        '有一定性能调优经验，熟练使用Instruments等性能调优工具；',
        '熟悉网络通信机制及常用数据传输协议，具有一定移动网络优化经验；',
        '具备良好的学习能力，对新技术有敏锐的嗅觉；',
        '具备独立分析并解决问题的能力，能独立承担工作任务',
      ],
      salary: '30-50K',
    },
  },

  {
    id: 46,
    name: 'Projectmanager',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责公司产品研发项目开发的组织与管理，包括项目启动、执行、监控、变更、验收等的组织与管理；',
        '沟通项目需求，从技术角度评估需求的合理性，评估项目开销（人力、周期、风险、预算等）及可行性；',
        '在系统开发阶段,引导高级开发输出概要设计和实现方案初稿，保证实现符合业务需求; ',
        '根据项目进度,参与相关文档的编写,如概要设计文档、需求变更文档、培训计划、上线发布计划等； ',
        '熟悉系统流程,参与组织测试用例,组织产品进行验收测试,并记录测试过程中发现的系统问题及提出的需求优化建议;',
        '解答业务系统操作问题,对系统问题、软件变更需求等内容进行及时跟踪与反馈； ',
        '负责承担产品研发项目的过程控制、成本控制、进度控制、质量控制等，确保按时、保质完成项目.',
        '负责相关团队（产品、开发、测试、运维等）的沟通配合与协调工作；',
        '参与公司软件技术框架的确立，控制团队代码质量。',
      ],
      requirement: [
        '统招全日制本科及以上学历，计算机相关专业，三年以上工作经验，具有团队管理经验或长期从事同一产品开发工作。 ',
        '5年以上软件项目实施经验,对信息化流程有较为深入的个人理解,能撰写解决方案,有项目管理者优先；  ',
        '具备良好的学习能力,思维敏捷,善于沟通,良好的书面与口头表达能力,组织协调能力较强; ',
        '熟悉JAVA、pathon等任意一门开发技术,熟悉 MYSQL、 Oracle数据库技术。要求有一门语言经验（测试也可以），年龄不能低于27，最好是男性  ',
        '有pmp证书优先',
      ],
      salary: '20-35k',
    },
  },
  {
    id: 47,
    name: 'FinancialDirector',
    headCount: 2,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '负责制订年度财务规划，确保企业正常运营. ',
        '负责拟定年度、季度、月度财务预结算报表，并上报相关领导。 ',
        '负责建立健全财务管理制度，有效进行财务风险管控。',
        '负责资产统筹与管理，节省开支，控制成本。',
        '实时监控财务账目及流水，进行财务报表分析与建议。 ',
        '日常财务核算与清算工作。 ',
        '领导交待的其他工作。',
      ],
      requirement: [
        '211、985学校全日制本科以上学历；',
        '须具备注册会计师资格，同时具备其他执业资格（如ACCA、AICPA等）优先；',
        '十年以上企业财务管理工作经验，三年以上全面财务管理经验； ',
        '精通审计法律规范； ',
        '为人正直、处事灵活、责任心强、工作细致严谨，有良好的职业道德；  ',
        '有较强的人际沟通和协调能力，良好的纪律性 ',
      ],
      salary: '40-50K',
    },
  },
  {
    id: 48,
    name: 'BankDirector',
    headCount: 1,
    time: '2019/5/26',
    detail: {
      responsibility: '',
      requirement: '',
      salary: '40-50K',
    },
  },
  {
    id: 49,
    name: 'BusinessPaymentDirector',
    headCount: 5,
    time: '2019/5/26',
    detail: {
      responsibility: [
        '根据集团的发展战略，制定公司中长期发展规划，并全面负责第三方支付的日常经营管理工作； ',
        '确立公司业务发展目标和计划并组织实施，实现公司业务和投资回报的快速增长； ',
        '健全和完善公司风险管理体系，推动公司风险管理水平的提升，加强对各项业务的动态监控，优化监控流程，确保公司业务在风险可控、可预测的情况下开展；',
        '负责第三方支付团队的管理，推进保障项目按时正常推进，不断提升安全系统和客户体验；',
      ],
      requirement: [
        '本科以上学历金融行业3年以上同等职务工作经验；',
        '具有第三方支付类行业营运经验；',
        '具备团队管理经验，熟悉团队激励体系的建设和实施；',
        '精通银行审计法律规范；',
        '为人正直、处事灵活、责任心强、工作细致严谨，有良好的职业道德； ',
        '有较强的人际沟通和协调能力，良好的纪律性',
      ],
      salary: '40-50K',
    },
  },
];

export const ENJOBLIST = [
  {
    id: 1,
    name: 'VISUAL DESIGN SPECIALIST',
    detail: {
      responsibility: [  
        'Responsible for key vision, theme, mobile websites, App/website skin and motion effects of all company projects;',
        'Responsible for daily thematic design work of marketing and operation departments, including design, specification, execution and iterative upgrade;',
        'Responsible for daily thematic design work of marketing and operation departments, including web pages, banners and in-app/web banners, and etc.',
      ],
      requirement: [
        'With Bachelor’s degree in Fine Arts or Multi-Media Arts, or its equivalents;',
        'Minimum of 4 years work experience in designing brand identities, grid systems, editorial layouts and web applications in desktop and mobile;',
        'Strong hand-drawing ability, good sense of aesthetics and solid art skills;',
        'Skilled in using various design software;',
        'Could work with less supervision;',
        'Has the ability to refine other colleague’s ideas and come-up with new ones to create a highly designed visual experience;',
        'Good communication and teamwork skills;',
        'Preferably with working experience from 4A’s advertising agencies.',
      ]
    },
  },
  {
    id: 2,
    name: 'UI DESIGN SPECIALIST',
    detail: {
      responsibility:[
        'Responsible for the design of the company\'s internal platforms including desktop and mobile;',
        'Responsible for the style setting of the main product, leading the design and revision of the main product;',
        'Responsible for forward-looking design research to improve the design level of the entire team.',
      ],
      requirement: [
        'With Bachelor’s degree in Fine Arts or Multi-Media Arts, or its equivalents;',
        'Minimum of 3 years work experience related to background design;',
        'Possess solid basic design skills, especially a high control ability on typesetting and color;',
        'Able to use design related software: Sketch, Photoshop, Zeplin, and etc.;',
        'Possess a certain animation production ability, can use: AE, Flinto, ProtoPie and other related animation software;',
        'Possess skills such as product thinking and interactive thinking; ',
        'Able to independently output interactive wireframes.',
      ]
    },
  },
  {
    id: 3,
    name: '3D DESIGN SPECIALIST',
    detail: {
      responsibility:[
        'Responsible for the design of the company\'s internal platforms in 3D modeling, rendering, animation, and etc.;',
        'Responsible for 3D special effects and brand packaging video production.',
      ],
      requirement: [
        'With Bachelor’s degree in Fine Arts or Multi-Media Arts, or its equivalents;',
        'Minimum of 2 years work experience related to 3D Design;',
        'With good art foundation and a good sense of shape and color;',
        'Proficient in 3D modeling and lighting material rendering experience;',
        'Able to produce pictures with high quality quickly; ',
        'Have 3D animation production ability and able to incorporate it with related software to produce video files;',
        'Proficient in using design software: 3Dmax, C4D and other three-dimensional software, special effects and editing, AE and PR;',
        'Preferably with working experience from online gaming or chess industries.',
      ]
    },
  },
  {
    id: 4,
    name: 'MOTION DESIGN SPECIALIST',
    detail: {
      responsibility:[
        'Responsible for the design of the company’s internal platforms in animation effects;',
        'Independently complete the project animation production using output file methods such as JSON and APNG;',
        'Participate in the production of product interactive effects, including UI animation, and interactive animation design and production;',
        'Cooperate with UI Designers to realize the animation parts in the product scene.',
      ],
      requirement: [
        'With Bachelor’s degree in Fine Arts or Multi-Media Arts, or its equivalents;',
        'Minimum of 2 years work experience related to animation or motion design; ',
        'Able to complete motion effect projects independently;',
        'Proficient in 2D and 3D graphic design software, and animation production software such as: PS, AE, Sketch, Blender, C4D, Spine and etc.;',
        'Able to quickly output high-quality motion design solutions; ',
        'Expert in the application of AE particles and dynamic plug-ins, ',
        'With an in-depth understanding of the dynamic design of each platform, ',
        'Could pay attention to industry trends, and apply them to the project;',
        'Possess professional skills such as: collaboration skills, work conscientiously and responsible, fast-learner, passionate in designing, innovative spirit, and able to withstand work pressure.',        
      ]
    },
  },
  {
    id: 5,
    name: 'JAVA DEVELOPER',
    detail: {
      responsibility:[
        'Participate in the architecture design discussion and technical research of the payment system;',
        'Complete core functions and overcome difficult key technical business problems;',
        'Write detailed system design documents;',
        'Help to train and improve the team.',
      ],
      requirement: [
        'With Bachelor’s degree in Computer-related majors or its equivalents;',
        'Minimum of 3 years work experience in Java development from Internet Architecture industries;',
        'Proficient in multi-threading and other basic libraries;',
        'Familiar with distributed caching and other technologies;',
        'Familiar with micro service system technology; ',
        'Preferably with experience in K8s implementation;',
        'Preferably with K8s implementation experience;',
        'Familiar with Spring Boot, Spring Cloud, Mybatis, MQ development framework, and have actual experience in Spring Cloud micro services;',
        'Proficient in popular distributed systems and their architectures such as: RabbitMQ, Kafka, Zookeeper, ZeroMQ;',
        'Master distributed open source technologies, including distributed things, distributed logs, MQ;',
        'Proficient in caching and other mechanisms, familiar with the design, application, and tuning of high-concurrency, high-performance distributed systems;',
        'Proficient in database such as: MySQL, NoSQL storage, Hbase, Memcached, Redis, MongoDB, etc.;',
        'Familiar with Linux system, and be able to master various Linux commands and tools,',
        'Familiar with Shell.',
        'Preferably with relevant experience in medium and large mutual benefit network projects and high concurrency;',  
      ]
    },
  },
  {
    id: 6,
    name: 'ANDROID DEVELOPER',
    detail: {
      responsibility:[
        'System function development iteration, involving framework/kernel and other levels of improvement and user experience improvement;',
        'Application architecture design; ',
        'Performance analysis and system problem positioning and resolution; ',
        'Complete module code writing, unit testing, and code maintenance work;',
        'Compile technical related documents, and be proficient in using iterative development methods for system development;',
        'Quickly learn and master relevant new technologies according to the needs of the project;',
        'Participate in technical key points and difficulties of the project.',
      ],
      requirement: [
        'With Bachelor’s degree in Computer-related majors or its equivalents;',
        'Minimum of 3 years work experience in Android Development;',
        'With experience in componentized architecture design;',
        'Understand at least 6 design patterns and read at least one excellent open source project;',
        'Skilled in developing custom controls and animations;',
        'Have development experience in security and performance (rendering speed, memory optimization, power consumption, etc.);',
        'Able to locate stuck and OOM problems;',
        'Familiar with NDK development, and have NDK development experience;',
        'Proficiency in using unit tests to ensure the quality of responsible modules;',
        'Have good coding habits, learning ability and English document reading ability;',
        'Familiar with OKHttp, Retrofit and RxJava.',
      ]
    },
  },
  {
    id: 7,
    name: 'FRONT- END DEVELOPER',
    detail: {
      responsibility:[
        'Web front-end function development and implementation;',
        'Participate in project demand analysis, product usability improvement and Web interface technology optimization;',
        'Responsible for the development of front-end interface and front-end components/class libraries, ',
        'Research and explore innovative development ideas and cutting-edge front-end technologies, and apply them to teams and products.',
      ],
      requirement: [
        'With Bachelor’s degree in Computer-related majors or its equivalents;',
        'Minimum of 3 years work experience in Front-End Development;',
        'In-depth knowledge of Web standards, practical understanding and practical experience of related knowledge such as performance optimization and accessibility;',
        'Familiar with JS programming, and master Vue.js, Nuxt.js, ES6, Typescript, and other front-end related development technologies or frameworks;',
        'Must have actual project experience;',
        'Preferably with experience in React, Node.js and web mobile development;',
        'Preferably someone who can withstand work pressure.',
      ]
    },
  },
  {
    id: 8,
    name: 'OPERATIONS ENGINEER',
    detail: {
      responsibility:[
        'Identify operating system problems by observing and studying system functioning and performance results',
        'Investigate complaints and suggestions;',
        'Complete troubleshooting procedures;',
        'Scripting or developing (Shell / Python / Golang);',
        'Identify operational priorities by assessing operational objectives;',
        'Determine project objectives, such as: efficiency, cost savings, energy conservation, operator convenience, safety, environmental quality;',
        'Develop operational solutions by defining, studying, estimating, and screening alternative solutions, calculating economics, determining impact on total system;',
        'Anticipate operational problems by studying operating targets, modes of operation, unit limitations; monitoring unit performance;',
        'Improve operational quality results by studying, evaluating, and recommending process re-design; ',
        'Implementing changes such as: contributing information and opinion to unit design and modification teams;',
        'Maintain safe and healthy work environment by following and enforcing standards and procedures; complying with legal regulations;',
        'Provide operational management information by collecting, analyzing, and summarizing operating and engineering data and trends;',
        'Update job knowledge by participating in educational opportunities; reading professional publications; maintaining personal networks; participating in professional organizations;',
        'Accomplish engineering and organization mission by completing related results as needed.'
      ],
      requirement: [
        'With Bachelor’s degree in Computer-related majors or its equivalents;',
        'Minimum of 3 years work experience in Software Development;',
        'Proficient in Linux operating system such as: Centos or Ubuntu; ',
        'Proficient in writing scripts such as: Shell / Lua / Python / Golang; ',
        'Familiar with Cloud Services such as: AWS / GCP / Azure / Alibaba Cloud / Huawei Cloud; ',
        'Familiar with automated OPS tools such as: Ansible / Git / Jenkins / Nginx /  Grafana & Prometheus; ',
        'With skills in Operations Research, Safety Management, Reporting Skills, Analyzing Information, and Technical Understanding; ',
        'Knowledgeable in Manufacturing Control Planning, Methods and Procedures, and Quality; ',
        'Preferably someone proficient in open source system such as: Elastic Stack and Kubernetes.',
      ]
    },
  },
  {
    id: 9,
    name: 'TEST SPECIALIST',
    detail: {
      responsibility:[
        'Provide demand decomposition and analysis;',
        'Write test points and test cases;',
        'Provide iterative functional testing;',
        'Determine initial problem location;',
        'Responsible of module maintenance;',
        'Accumulation of technical documents;',
      ],
      requirement: [
        'With Bachelor’s degree in Computer-related majors or its equivalents;',
        'Minimum of 3 years work experience in manual Software testing;',
        'Familiar with test theory;',
        'Proficient in using Xmind tools to write test points and test cases;',
        'Familiar with basic database operations;',
        'Familiar with using Fiddler and other packet capture tools;',
        'Good communication skills;',
        'Excellent team awareness, obedience to management, and ability to withstand work pressure.',
      ]
    },
  },
  {
    id: 10,
    name: 'NETWORK ENGINEER',
    detail: {
      responsibility:[
        'Ensure stable and reliable network services (e.g. access products, service networks and core network);',
        'Management as well as maintenance of the network systems and executing proper control over their operations;',
        'Day-to-day network performance monitoring;',
        'Practical application abilities; analytical as well as logical reasoning skills',
        'Identification of errors and faults in the network and devising methods to overcome them and solve all network related problems.',
        'Install, troubleshoot and repair of network Hardware / Software;',
        'Resolve network related issues and aspects such as: routing, switching, security, wireless, and handling of network tools, and etc.;',
        'Properly execute installations and upgrading of the network;',
        'Deployment and maintenance of network monitoring, analysis and reporting tools;',
        'Provisioning (IP assignment, core network configuration, monitoring and graphing, leased lines);',
        'Support with network equipment build and installation;',
        'Support facilities provisioning (installing racks, setting up power, installing ethernet, fibre and console cabling, ups and dell server);',
        'Communicate with clients, whenever required, to explain them the technicalities related to network and their efficient operation;',
        'Maintenance of technical documentation of processes and procedures used.',
      ],
      requirement: [
        'With Bachelor’s degree in Computer-related majors or its equivalents;',
        'Minimum of 3 years work experience in Network Development;',
        'Strong troubleshooting methodology and experience in an operational environment;',
        'Experience with networking knowledge such as: VLAN, MSTP, HSRP, VPC, VDC, OSPF, EIGRP, BGP, IPSec, DMVPN;',
        'Experience with Cisco products like: Cisco Router (ISR or ASR), Switch (IOS or Nexus), WLC, and ASA;',
        'Experience in Firewall (Cisco ASA, Palo Alto) and Load Balancer (F5) is an advantage',
        'Experience in Zabbix, H3C, SangFor, MPLS VPN, SD-WAN is an advantage',
        'Preferably with CCNA / CCNP / CCIE certification.',
      ]
    },
  },{
    id: 11,
    name: 'IT SUPPORT SPECIALIST',
    detail: {
      responsibility:[
        'Troubleshoot and repair of workstations, printers, and other client hardware and software: Microsoft Windows Operating System and MAC OS;',
        'Answer user’s inquiries regarding computer software and hardware operation to resolve issues;',
        'Document issues and resolution thoroughly;',
        'Set up and remove hardware / software equipment for employee use; ',
        'Perform and ensure proper installation of cable, operating systems and appropriate software;',
        'Refer major hardware / software problems or defective products to vendors or technicians for service;',
        'Install and perform minor repairs to hardware, software, and peripheral equipment, following design or installation specifications.',
      ],
      requirement: [
        'With Bachelor’s degree in Computer-related majors or its equivalents;',
        'Minimum of 1 year work experience in IT Helpdesk/IT Support management;',
        'With In-depth knowledge and experience with troubleshooting and repair of workstations, printers, and other client hardware; Microsoft Windows Operating Systems (Win10) and MAC OS X;',
        'With Entry-level certifications from Cisco and Microsoft certifications is an advantage;',
        'Willing and capable to learn new things;',
        'Positive work attitude, self-motivated and good team work skills.',
      ]
    },
  },{
    id: 12,
    name: 'IT SPECIALIST / TECHNICAL SUPPORT REPRESENTATIVE',
    detail: {
      responsibility:[
        'Undertake daily work orders for internal employees and transfer to internal network engineers for processing;',
        'Conduct return visits and evaluations on the time and service attitude of work order processing;',
        'Use the work order system software and organize data regularly.',
      ],
      requirement: [
        'With Junior High School diploma;',
        'Preferably with customer service work experience;',
        'Proficient in computer operations, office software and basic network knowledge base;',
        'Positive work attitude, customer service oriented, and able to withstand work pressure;',
        'Willing to work overtime. ',
      ]
    },
  },
  {
    id: 13,
    name: 'RISK CONTROL SPECIALIST',
    detail: {
      responsibility:[
        'Apply withdrawal check based on the rules & regulations set by the company;',
        'Screen member\'s betting pattern; ',
        'Detect if member is involved in any abnormal betting behavior;',
        'Screen member\'s performance and analyze if the member has potential risk/fraud behavior;',
        'Escalate member to relevant team for further checking.',
      ],
      requirement: [
        'With Junior High School diploma;',
        'Experience with Risk/Fraud management in online gaming industry is an advantage;',
        'Willing to work on shifting schedule;',
        'Good communication skills.',
      ]
    },
  },
  {
    id: 14,
    name: 'BONUS SPECIALIST',
    detail: {
      responsibility:[
        'Responsible for member’s deposit and withdrawal transactions;'
      ],
      requirement: [
        'With Junior High School diploma;',
        'Experience with Payment management in online gaming industry is an advantage;',
        'Willing to work on shifting schedule;',
        'Good communication skills.',
      ]
    },
  },
  {
    id: 15,
    name: 'DATA SPECIALIST',
    detail: {
      responsibility:[
        'Responsible for the data analysis and data analysis report collation;',
        'Provide daily, weekly, monthly, and annual data analysis reports, charts, reports, etc., and regularly produce and send to relevant departments;',
        'Complete relevant business analysis, provide data support for operational decision-making, event planning, bonus distribution, etc.; ',
        'Provide data support to relevant headquarters and sites (clients).',
        'Responsible for the collection, transmission (mainly reporting) and management of the data;',
        'Timely supervision of customer demand for data, daily, weekly and monthly operations will be performed separately once data is reported;',
        'Responsible for sorting, summarizing, and analyzing and processing all kinds of data, reporting information and data to leaders and relevant departments in a timely manner, and making relevant records;',
        'Conduct various data analysis and comprehensive data processing, and process it into useful information to provide management team for decision making;',
        'Ensure safe management of data handled, and timely backup of various data and reports.',
      ],
      requirement: [
        'With Bachelor’s degree in Statistics, Mathematics, Information Technology or its equivalents;',
        'Minimum of 1 years work experience in Data management;',
        'Ability to manage big data;',
        'Preferably with experience in SQL and other related data extraction tools;',
        'Good data sensitivity and able to extract core results from massive data;',
        'With experience in data analysis, mining, filtering and modeling;',
        'Proficiency in writing data analysis reports independently, and timely discovering and analyzing hidden changes and problems;',
        'Able to perform higher-level data statistics, calculations and analysis;',
        'Proficient in Microsoft Excel;',
        'Knowledgeable with data privacy and confidentiality;',
        'Must be keen to details, logical, fast-learner, attentive, and patient;',
        'Positive work attitude, able to withstand work pressure, and able to collaborate on cross-departmental projects.',
      ]
    },
  },
  {
    id: 16,
    name: 'BILINGUAL SPECIALIST',
    detail: {
      responsibility:[
        'Provide support to NON-IT Departments that require Mandarin proficiency skills.'
      ],
      requirement: [
        'With Bachelor’s degree;',
        'Working experience is not required; open to fresh graduates;',
        'Must be proficient in Mandarin language both written and oral;',
        'Open to all nationalities;',
        'Must be based in Philippines.',
      ]
    },
  },
  {
    id: 17,
    name: 'CEO ASSISTANT',
    detail: {
      responsibility:[
        'Manage the CEO\'s calendar;',
        'Schedule meetings;',
        'Arrange travel plans;',
        'Answer phone calls and emails;',
        'Organize and filing of documents;',
        'Communicate with other staff members on behalf of the executives;'
      ],
      requirement: [
        'With Bachelor’s degree;',
        'Must be based in Philippines;',
        'Must be proficient in Mandarin and English language both written and oral;',
        'Open to Filipino, Malaysian or Vietnamese; ',
        'Preferably with work experience as Executive Assistant or Flight Steward.',
      ]
    },
  },
  {
    id: 18,
    name: 'DEPARTMENT ASSISTANT',
    detail: {
      responsibility:[
        'Organize departmental meetings;',
        'Conduct onboarding process of new employees;',
        'Data entry and update information of the employees;',
        'Perform general office clerical duties and errands.',
      ],
      requirement: [
        'With Bachelor’s degree;',
        'Minimum of 1 year work experience as Executive Assistant or other relevant work;',
        'Superb English communication skills;',
        'Proficient in Microsoft Excel and other Microsoft Applications;',
        'Charismatic and can overcome Cultural Diversity;',
        'Time management skills;',
        'An individual who has established connections with different universities in the Philippines.',
      ]
    },
  },
  {
    id: 19,
    name: 'TEAM ASSISTANT',
    detail: {
      responsibility:[
        'Screen and direct phone calls and distribute correspondence',
        'Handle requests and queries appropriately',
        'Manage diary and schedule meetings and appointments',
        'Make travel arrangements and bookings',
        'Take dictation and minutes',
        'Produce reports, presentations and briefs',
        'Devise and maintain office filling system',
      ],
      requirement: [
        'With Bachelor’s degree;',
        'Working experience is not required; open to fresh graduates;',
        'Good English communication skills;',
        'Proficient using Microsoft Office applications;',
        'Outstanding organizational and time management skills;',
        'Ability to multitask and prioritize daily workload;',
        'With utmost discretion and confidentiality;',
        'Amenable for regional travel.',
      ]
    },
  }
];
