import React, { useState } from 'react';
import './style.scss'
import i18next from 'i18next';

const Home = () => {

  const [changeLanguage,setChangelanguage] = useState(true);
    
  i18next.on('languageChanged', function(lng) {
      setChangelanguage(!changeLanguage);
  })

  return (
    <main className='homeMobile'>

      <div className="homeTop">
        {changeLanguage ? 
          (
          <>
            <img src={require('../../../assets/mobile/home/home_title@2x.webp')} alt="" className='homeTitle'/>
            <p>YOUR SUCCESS，OUR DREAM</p>
            <img src={require('../../../assets/mobile/home/home_subtitle@2x.webp')} alt="" className='homeSubtitle'/>
          </>
          ) :
          (
            <>
              <img src={require('../../../assets/mobile/home/home_titleEn@2x.webp')} alt="" className='homeTitleEn'/>
              <img src={require('../../../assets/mobile/home/home_subtitleEn@2x.webp')} alt="" className='homeSubtitleEn'/>
            </>
          )
        }
      </div>

        {changeLanguage ?
          (
            <>
              <img src={require('../../../assets/mobile/home/common_bg_image@2x.webp')} alt="" className='commonImage'/>

              <div className='homeBottom'>
                <img src={require('../../../assets/mobile/home/home_img@2x.webp')} alt="" className='homeImage' />
              </div>
            </>
          ) : 
          (
            <>
              <img src={require('../../../assets/mobile/home/common_bg_image@2x.webp')} alt="" className='commonImageEn'/>
              
              <div className='homeBottomEn'>
                <img src={require('../../../assets/mobile/home/home_img@2x.webp')} alt="" className='homeImage' />
              </div>
            </>
          )
        }
    </main>
  )
}

export default Home;