import React from 'react';
import { useState } from 'react';
import './style.scss';
import FaqEnglish from '../../../assets/web/faq/faqs_title_en.webp';
import FaqChinese from '../../../assets/web/faq/faqs_title_chinese.webp'
import Rocket from '../../../assets/web/faq/faqs_img.webp';
import Bullet from '../../../assets/web/faq/bullet-icon.webp';
import BulletActive from '../../../assets/web/faq/bullet-icon-active.webp';
import QuesBackground from '../../../assets/web/faq/faqs_text_card_bg.webp';
import SideBackground from '../../../assets/web/faq/faq-common_bg_image.webp'
import i18n from 'config/i18n';
import { Translation } from 'react-i18next';

function FAQ() {

    const [showQ1, setShowQ1] = useState(false);
    const [showQ2, setShowQ2] = useState(false);
    const [showQ3, setShowQ3] = useState(false);
    const [showQ4, setShowQ4] = useState(false);
    const [showQ5, setShowQ5] = useState(false);
    const [showQ6, setShowQ6] = useState(false);

    const [changeLanguage, setChangelanguage] = useState(false);

    i18n.on('languageChanged', function(lng) {
        setChangelanguage(!changeLanguage);
    })

    const handleClick = (index: Number) => {
        setShowQ1(false);
        setShowQ2(false);
        setShowQ3(false);
        setShowQ4(false);
        setShowQ5(false);
        setShowQ6(false);

        switch (index) {
            case 1:
                showQ1 ? setShowQ1(false) : setShowQ1(true);
                break;
            case 2:
                showQ2 ? setShowQ2(false) : setShowQ2(true);
                break;
            case 3:
                showQ3 ? setShowQ3(false) :setShowQ3(true);
                break;
            case 4:
                showQ4? setShowQ4(false) :setShowQ4(true);
                break;
            case 5:
                showQ5? setShowQ5(false) :setShowQ5(true);
                break;
            case 6:
                showQ6? setShowQ6(false) :setShowQ6(true);
                break;
        }
    };

    const setTextStyle = {
        color: 'rgba(7, 19, 37, 1)'
    }
    
    const setTextActiveStyle = {
        color: '#01CA69',
        boxShadow: 'none'
    }

    return (
        <>
        <div className='faqWrapper'> 
            <div className='faqs-container'>
                <div className='faqs-title'>
                    { changeLanguage ? 
                    <img src={FaqEnglish} className='faqs-title-en' /> 
                    :   <>
                            <img src={FaqChinese} className='faqs-title-chinese' />
                            <div className='faqs-title-sub'>
                                FAQs
                            </div>
                        </>
                    }
                    <img src={QuesBackground} className='faqs-ques-bg' />
                    <img src={SideBackground} className='faqs-side-bg' />              
                </div>
                <div className='faqs-content'>
                    <div className='faqs-question-container'>
                        <div className='faqs-qa' style={showQ1 ? (setTextActiveStyle) : (setTextStyle)}>
                            <div className='faqs-ques' onClick={ () => handleClick(1) }>
                                { showQ1 ? (
                                    <img src={BulletActive} className='bullet-icon' />
                                ) : (
                                    <img src={Bullet} className='bullet-icon' />
                                ) }
                                <Translation>
                                    { t => <span style={showQ1 ? (setTextActiveStyle) : (setTextStyle)}> { t('QA_1')} </span> }
                                </Translation>
                            </div>
                            { showQ1 && 
                                <div className='faqs-ans'>
                                    <Translation>
                                        { t => <span> { t('QA_11') } </span> }
                                    </Translation>
                                </div>
                            } 
                        </div>
                        <div className='faqs-qa' style={showQ2 ? (setTextActiveStyle) : (setTextStyle)}>
                            <div className='faqs-ques' onClick={ () => handleClick(2) }>
                                { showQ2 ? (
                                    <img src={BulletActive} className='bullet-icon' />
                                ) : (
                                    <img src={Bullet} className='bullet-icon' />
                                )}
                                <Translation>
                                    { t => <span style={showQ2 ? (setTextActiveStyle) : (setTextStyle)}> { t('QA_3') } </span> }
                                </Translation>
                            </div>
                            { showQ2 && 
                                <div className='faqs-ans'>
                                    <Translation>
                                        { t => <span> { t('QA_31') } </span> }
                                    </Translation>                                    
                                </div>
                            } 
                        </div>
                        <div className='faqs-qa' style={showQ3 ? (setTextActiveStyle) : (setTextStyle)}>
                            <div className='faqs-ques' onClick={ () => handleClick(3) }>
                                { showQ3 ? (
                                    <img src={BulletActive} className='bullet-icon' />
                                ) : (
                                    <img src={Bullet} className='bullet-icon' />
                                )}
                                <Translation>
                                    { t => <span style={showQ3 ? (setTextActiveStyle) : (setTextStyle)}> { t('QA_4') } </span> }
                                </Translation>
                            </div>
                            { showQ3 && 
                                <div className='faqs-ans'>
                                    <Translation>
                                        { t => <span> { t('QA_41') } </span> }
                                    </Translation>
                                </div>
                            } 
                        </div>
                        <div className='faqs-qa' style={showQ4 ? (setTextActiveStyle) : (setTextStyle)}>
                            <div className='faqs-ques' onClick={ () => handleClick(4) }>
                                { showQ4 ? (
                                    <img src={BulletActive} className='bullet-icon' />
                                ) : (
                                    <img src={Bullet} className='bullet-icon' />
                                )}
                                <Translation>
                                    { t => <span style={showQ4 ? (setTextActiveStyle) : (setTextStyle)}> { t('QA_5') } </span> }
                                </Translation>
                            </div>
                            { showQ4 && 
                                <div className='faqs-ans'>
                                    <Translation>
                                        { t => <span> { t('QA_51') } </span> }
                                    </Translation>
                                </div>
                            } 
                        </div>
                        <div className='faqs-qa' style={showQ5 ? (setTextActiveStyle) : (setTextStyle)}>
                            <div className='faqs-ques' onClick={ () => handleClick(5) }>
                                { showQ5 ? (
                                    <img src={BulletActive} className='bullet-icon' />
                                ) : (
                                    <img src={Bullet} className='bullet-icon' />
                                ) }
                                <Translation>
                                    { t => <span style={showQ5 ? (setTextActiveStyle) : (setTextStyle)}> { t('QA_6') } </span> }
                                </Translation>
                            </div>
                            { showQ5 && 
                                <div className='faqs-ans'>
                                    <Translation>
                                        { t => <span> { t('QA_61') } </span> }
                                    </Translation>
                                </div>
                            } 
                        </div>
                        <div className='faqs-qa' style={showQ6 ? (setTextActiveStyle) : (setTextStyle)}>
                            <div className='faqs-ques' onClick={ () => handleClick(6) }>
                                { showQ6 ? (
                                    <img src={BulletActive} className='bullet-icon' />
                                ) : (
                                    <img src={Bullet} className='bullet-icon' />
                                )}
                                <Translation>
                                    { t => <span style={showQ6 ? (setTextActiveStyle) : (setTextStyle)}> { t('QA_7') } </span> }
                                </Translation>
                            </div>
                            { showQ6 && 
                                <div className='faqs-ans'>
                                    <Translation>
                                        { t => <span> { t('QA_71') } </span> }
                                    </Translation>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='faqs-img-cont'>
                        <img src={Rocket} className='faqs-img' />
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default FAQ;