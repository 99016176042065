import './style.scss';
import { Translation } from 'react-i18next';
import i18n from '../../../config/i18n';
import i18next from 'i18next';
import React, { FC, useState } from 'react';
import BonusLeftImage from '@assets/web/bonus/bonus-common_bg_image.webp';

import ic_salary_selected from '@assets/web/bonus/ic_salary_selected@2x.webp'
import ic_leaves_selected from  '@assets/web/bonus/ic_leaves_selected@2x.webp'
import dic_meals_selecte from  '@assets/web/bonus/ic_meals_selected@2x.webp'
import ic_transportation_selected from  '@assets/web/bonus/ic_transportation_selected@2x.webp'
import ic_holiday_selected from  '@assets/web/bonus/ic_holiday_selected@2x.webp'
import ic_teambuilding_selected from  '@assets/web/bonus/ic_teambuilding_selected@2x.webp'

const preImgList = [ic_salary_selected, ic_leaves_selected, dic_meals_selecte, ic_transportation_selected, ic_holiday_selected, ic_teambuilding_selected]

interface Props {}

const Bonus: FC<Props> = () => {
    const [changeLanguage,setChangelanguage]=useState(false);
    const [activeItem, setActiveItem] = useState('')

    i18next.on('languageChanged', function(lng) {
        setChangelanguage(!changeLanguage);
    })
    return (
        <div className={'bonusWrapper'} id='bonus'>
            <div className={'bonusCont'}>
                <div className={'bonusLeft'}>
                    <div className={'titleCont'}>
                        {changeLanguage ? 
                            (
                                <img className={'bonusTitleEN'} src={require('../../../assets/web/bonus/salary_title_EN@2x.webp')} />
                            ) : (
                            <>
                                <img className={'bonusTitle'} src={require('../../../assets/web/bonus/salary_title_Chinese@2x.webp')} />
                                <div className={'bonusSubtitle'}>
                                    salary / benefits
                                </div>
                            </>
                            )
                        }
                    </div>
                    <img src={BonusLeftImage} className='bonusLeftImage'></img>
                    <div className={'bonusDesc'}>
                        <Translation>{t => <>{t('salaryDetail')}</>}</Translation>
                    </div>
                </div>
                <div className={'bonusRight'}>
                    {/* TO DO: Refactor and map */}
                    <div className={'topRow'}>
                        {/* SALARY */}
                        <div className={`bonusItem ${activeItem==='item1'? 'active' : ''}`} onClick={()=>{
                            setActiveItem('item1')
                        }}>
                            <div className={'bonusIcon'}>
                            </div>
                            <div className={'bonusText'}>
                                <div className={'bonusType'}>
                                    <Translation>{t => <>{t('Salary1')}</>}</Translation>
                                </div>
                                <ul>
                                    <li>
                                        <Translation>{t => <>{t('Salary_1')}</>}</Translation>
                                    </li>
                                    <li>
                                        <Translation>{t => <>{t('Salary_2')}</>}</Translation>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        {/* LEAVES */}
                        <div className={`bonusItem ${activeItem==='item2'? 'active' : ''}`} onClick={()=>{
                            setActiveItem('item2')
                        }}>
                            <div className={'bonusIcon'}>
                            </div>
                            <div className={'bonusText'}>
                                <div className={'bonusType'}>
                                    <Translation>{t => t('benefits')}</Translation>
                                </div>
                                <ul>
                                    <li>
                                        <Translation>{t => t('benefits_1')}</Translation>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* MEALS */}
                        <div className={`bonusItem ${activeItem==='item3'? 'active' : ''}`} onClick={()=>{
                            setActiveItem('item3')
                        }}>
                            {/*图片预加载*/}
                            <div style={{display: 'none'}}>
                                {preImgList.map((item:string) => {
                                    return  <img src={item}/>
                                })}
                            </div>
                            <div className={'bonusIcon'}>
                            </div>
                            <div className={'bonusText'}>
                                <div className={'bonusType'}>
                                    <Translation>{t => <>{t('meals')}</>}</Translation>
                                </div>
                                <ul>
                                    <li><Translation>{t => <>{t('meals_1')}</>}</Translation></li>
                                    {i18n.language === "zh" && <li><Translation>{t => <>{t('meals_2')}</>}</Translation></li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={'bottomRow'}>
                        {/* TRANSPORTATION */}
                        <div className={`bonusItem ${activeItem==='item4'? 'active' : ''}`} onClick={()=>{
                            setActiveItem('item4')
                        }}>
                            <div className={'bonusIcon'}>
                            </div>
                            <div className={'bonusText'}>
                                <div className={'bonusType'}>
                                    <Translation>{t => <>{t('Transportation')}</>}</Translation>
                                </div>
                                <ul >
                                    <li>
                                        <Translation>{t => <>{t('Transportation_1')}</>}</Translation>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* HOLIDAY */}
                        <div className={`bonusItem ${activeItem==='item5'? 'active' : ''}`} onClick={()=>{
                            setActiveItem('item5')
                        }}>
                            <div className={'bonusIcon'}>
                            </div>
                            <div className={'bonusText'}>
                                <div className={'bonusType'}>
                                    <Translation>{t => <>{t('LeaveEntitlement')}</>}</Translation>
                                </div>
                                <ul>
                                    <li>
                                        <Translation>
                                        {t => <>{t('LeaveEntitlement_1')}</>}
                                        </Translation>
                                    </li>
                                    <li>
                                        <Translation>
                                        {t => <>{t('LeaveEntitlement_2')}</>}
                                        </Translation>
                                    </li>
                                    <li>
                                        <Translation>
                                        {t => <>{t('LeaveEntitlement_3')}</>}
                                        </Translation>
                                    </li>
                                    <li>
                                        <Translation>
                                            {t => t('LeaveEntitlement_4')}
                                        </Translation>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* COMPANY ACTIVITIES */}
                        <div className={`bonusItem ${activeItem==='item6'? 'active' : ''}`} onClick={()=>{
                            setActiveItem('item6')
                        }}>
                            <div className={'bonusIcon'}>
                            </div>
                            <div className={'bonusText'}>
                                <div className={'bonusType'}>
                                    <Translation>{t => t('TeamBuilding')}</Translation>
                                </div>
                                <ul>
                                    <li>
                                        <Translation>{t => <>{t('TeamBuilding_1')}</>}</Translation>
                                    </li>
                                    <li>
                                        <Translation>{t => <>{t('TeamBuilding_2')}</>}</Translation>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default Bonus;