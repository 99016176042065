import { FC, useState } from 'react';
import i18n from '../../../config/i18n';
import i18next from 'i18next';
import './style.scss';

interface Props {
   slideTo: (index: number) => void
}

const Home: FC<Props> = ({
   slideTo
}) => {
   const [changeLanguage,setChangelanguage]=useState(false);

   i18next.on('languageChanged', function(lng) {
      setChangelanguage(!changeLanguage);
   })

   const onClickAbout = () => {
      slideTo(1);
   }

   return (
      <main className="home">
         <div className='homeContainer'>
            <div className='contentContainer'>
               <div className='contentContainer_left'>
                  {changeLanguage? (
                     <div className='homeTitleCont'>
                        <img className='homeTitleEN' src={require('../../../assets/web/home/home_titile_EN@2x.webp')} />
                     </div>
                  ) : (
                     <>
                     <div className='homeTitleCont'>
                        <img className='homeTitle' src={require('../../../assets/web/home/home_titile_Chinese@2x.webp')} />
                        <p className='homeSubtitle'>YOUR SUCCESS, OUR DREAM</p>
                     </div>
                     </>
                  )}
                  <button className='contentContainer_left__button' onClick={onClickAbout}>{i18n.t('AboutUs')}</button>
               </div>

               <div className='contentContainer_right'>
                  <img src={require('../../../assets/web/home/home_img@2x.webp')} alt="home image" className='contentContainer_right__image' />
               </div>
            </div>
         </div>
      </main>
   )
}

export default Home;